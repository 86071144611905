export interface IWidgets {
  is_active: boolean;
  created_by: null;
  updated_by: null;
  id: number;
  updated_datetime: null;
  widget_tags: string;
  widget_description: string;
  created_datetime: null;
  widget_name: string;
  widget_format: string;
  selected?: boolean | undefined | null;
}

export interface IDashboardDetails {
  dashboard: Dashboard;
  widgets: IWidgetInfo[];
}
export interface IDashboardList {
  label: string;
  value: number
}

export interface Dashboard {
  created_by: null;
  is_active: boolean;
  created_datetime: Date;
  account_id: number;
  dashboard_name: string;
  is_public: boolean;
  updated_by: null;
  id: number;
  updated_datetime: null;
  user_id: number;
  is_default: boolean;
}

export interface IWidgetInfo {
  is_active: boolean;
  created_by: null;
  updated_by: null;
  id: number;
  updated_datetime: null;
  widget_tags: string;
  widget_description: string;
  created_datetime: null;
  widget_name: string;
  widget_format: string;
  widget_endpoint: string;
  widget_title: string;
  user_widget_association_id: number;
  date_range?: number | string;
  foreground_color?: string | null;
  background_color?: string | null;
  others_preference?: any
}

export interface ILayoutsPNew {
  h: number;
  i: string;
  minH: number;
  minW: number;
  moved: boolean;
  static: boolean
  w: number;
  x: number
  y: number
}
export const analyticsEndpoints = {
  contact_vs_recent: "/contact/contact-source/{account_id}",
  total_contact_created_vs_last_week: "/contact/contact-creation-stats/{account_id}",
  total_task_created_vs_last_week: "/tasks/source/{account_id}",
  contact_vs_time: "/contact/contact-creation-by-day/{account_id}",
  contact_creation_total_by_source: "/contact/total-by-source/{account_id}",
  contact_creation_total_by_person: "/contact/total-by-person/{account_id}",
  company_vs_recent: "/company/source/{account_id}",
  total_company_created_vs_last_week: "/company/creation/{account_id}",
  company_vs_time: "/company/creation-by-day/{account_id}",
  company_creation_total_by_person: "/company/total-by-person/{account_id}",
  TOTAL_TICKET_CREATED_VS_LAST_WEEK: "/ticket/source/{account_id}",
  TICKET_VS_MONTH: "/ticket/per-month-create/{account_id}",
  TASK_VS_MONTH: "/tasks/per-month-create/{account_id}",
  TICKET_STATUS: "/ticket/status/{account_id}",
  TASK_STATUS: "/tasks/status/{account_id}",
  STAGE_WISE_DEALS_AMOUNT: "/deals/stage-wise-total-amount",
  STAGE_WISE_TICKET_COUNT: "/tickets/stage-count",
  TICKET_CREATED: "/tickets/created",
  DEALS_IN_A_STAGE_COUNT: "/deals/deals-stage-count",
  CREATED_LAST_24H: "/tickets/created-last-24h",
  CLOSED_LAST_24H: "/tickets/close-last-24h",
  TOTAL_COMPANY_CREATED: "/company/analytics/cards/total",
  LAST_WEEK_COMPANY_CREATED: "/company/analytics/cards/lastWeek",
  TOP_MONTH_COMPANY_CREATION: "/company/analytics/cards/topMonth",
  AVERAGE_COMPANY_CREATION_PER_MONTH: "/company/analytics/cards/avgMonth",
  TOTAL_TASK_COUNT: "/tasks/analytics/cards/total",
  LAST_WEEK_TOTAL_TASK_COUNT: "/tasks/analytics/cards/lastWeek",
  TOP_MONTH_TASK_ANALYTICS: "/tasks/analytics/cards/topMonth",
  AVG_MONTH_TASK_ANALYTICS_CARDS: "/tasks/analytics/cards/avgMonth",
  GET_MAX_DEAL_AMOUNT_MONTH: "/deals/max-amount-month",
  GET_LOWEST_DEAL_AMOUNT_MONTH: "/deals/lowest-amount-month",
  MONTH_WISE_DEALS_AMOUNT: "/deals/total-amount-by-month",
  GET_TOTAL_TICKET: "/tickets/total",
  GET_TOTAL_OPEN_TICKET: "/tickets/total-open",
  GET_TOTAL_CLOSE_TICKET: "/tickets/total-closed",
  GET_TOTAL_TICKET_IN_PROGRESS: "/tickets/total-in-progress",
  AVERAGE_TICKET_RESOLVE_TIME: "/tickets/average-resolve-time",
  TICKET_CLOSE_BEFORE: "/tickets/close-before",
  TICKET_CLOSE_AFTER: "/tickets/close-after",
  TICKET_PERSON_WISE_IN_PROGRESS: "/tickets/persons-wise-in-progress",
  TICKET_PERSON_WISE_OPEN: "/tickets/persons-wise-open",
  TICKET_PERSON_WISE_CLOSE: "/tickets/persons-wise-closed",
  KPI_TOTAL_COMPANY_TARGET: "/kpi/total-company-target",
  KPI_ACHIEVED_TILL_DATE: "/kpi/achieved-till-date",
  KPI_BACKLOG_TILL_DATE: "/kpi/backlog-till-date",
  KPI_ACHIEVED_THIS_MONTH: "/kpi/achieved-this-month",
};

export const WidgetSources = {
  report: "report",
  dashboard: "dashboard",
};

export interface IReportListResponse {
  Widgets: Widgets;
  UserWidgetAssociation: UserWidgetAssociation;
}

export interface UserWidgetAssociation {
  id: number;
  created_by: null;
  created_datetime: Date;
  user_id: number;
  dashboard_id: null;
  is_public: boolean;
  updated_by: null;
  is_active: boolean;
  updated_datetime: null;
  account_id: number;
  widget_id: number;
  user_preference: UserPreference;
}

export interface UserPreference {
  from?: number;
}

export interface Widgets {
  created_datetime: null;
  widget_name: string;
  widget_format: string;
  widget_description: string;
  is_active: boolean;
  created_by: null;
  updated_by: null;
  id: number;
  updated_datetime: null;
  widget_tags: string;
  widget_title: string;
  widget_endpoint: string;
}
