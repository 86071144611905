import React from "react";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";

function AttractBody() {
  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="drawerSubTitle">
        <div>
          <p className="title">Create your first form</p>
          <p className="title_p">
            Fill your database with leads from your website using effective
            online forms — no technical expertise required
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default AttractBody;
