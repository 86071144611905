import "./styles/notAllowed.scss";

const NotAllowed = () => {
  return (
    <div className="error-container">
      <div className="error-container-content">
        <h1>Oops! You Don't Have Access to This Page</h1>
        <p>
          We're sorry, but it seems you do not have the necessary permissions to
          view this content.
        </p>
        <h2>What to Do Next?</h2>
        <ol>
          <li>
            Check Your Login Status: Ensure that you are logged in with the
            correct account that has the required permissions.
          </li>
          <li>
            Contact Support: If you believe this is an error or if you need
            assistance, please contact our support team at{" "}
            <a href="mailto:support@roboket.com">support@roboket.com</a> or call
            us at <a href="tel:09610-770555">09610-770555</a>.
          </li>
          {/* <li>
          Go Back: <a href="#">Click here</a> to return to the previous page or{" "}
          <a href="#">home</a> to go to the main page.
        </li> */}
        </ol>
        <p>
          Thank you for your understanding, and we apologize for any
          inconvenience.
        </p>
      </div>
    </div>
  );
};

export default NotAllowed;
