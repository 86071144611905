// import Roboket_Logo from "./Roboket_Logo.svg";
// import group from "./group.png";
// import note_icon from "./note_icon_ellipse.svg";
// import comment_icon from "./comment_icon.svg";
// import email_icon from "./email_icon.svg";
// import active_icon from "./active_icon.svg";
// import call_icon from "./call_icon_ellipse.svg";
// import avatar from "./avatar.png";
// import more_filter from "./more_filter.svg";
// import red_dot from "./red_dot.svg";
// import yellow_dot from "./yellow_dot.svg";
// import down_arrow from "./down_arrow.svg";
// import right_icon from "./right_icon.svg";
// import cross_icon from "./cross_icon.svg";
// import eight_dot from "./eight_dot.svg";
// svgs dir
import arrow from "./svgs/arrow.svg";
import close_gray from "./svgs/close_gray.svg";
import cross_circle from "./svgs/cross_circle.svg";
import compare_mirror from "./svgs/compare_mirror.svg";
import dropdown_white from "./svgs/dropdown_white.svg";
import dropdown_with_bg from "./svgs/dropdown_with_bg.svg";
import edit_gray from "./svgs/edit_gray.svg";
import edit_circle from "./svgs/edit_circle.svg";
import eight_dot_off_white from "./svgs/eight_dot_off_white.svg";
import eight_dot_white from "./svgs/eight_dot_white.svg";
import forward_icon from "./svgs/forward.svg";
import info_cyan from "./svgs/info_cyan.svg";
import plus_circle from "./svgs/plus_circle.svg";
import statictics from "./svgs/statictics.svg";
import slots_one from "./svgs/slots0.svg";
import slots_two from "./svgs/slots1.svg";
import slots_three from "./svgs/slots2.svg";
import slots_four from "./svgs/slots3.svg";
import slots_five from "./svgs/slots4.svg";
import slots_six from "./svgs/slots5.svg";
import slots_seven from "./svgs/slots6.svg";
import slots_eights from "./svgs/slots7.svg";
import slots_nine from './svgs/slots8.svg';
import dash_modal from './svgs/dash_modal.svg';

// export const logoIcon = Roboket_Logo;
// export const groupIcon = group;
// export const note = note_icon;
// export const commentIcon = comment_icon;
// export const emailIcon = email_icon;
// export const activeIcon = active_icon;
// export const callIcon = call_icon;
// export const noteIcon = note_icon;
// export const avatarIcon = avatar;
// export const moreFilter = more_filter;
// export const redDotIcon = red_dot;
// export const yellowDotIcon = yellow_dot;
// export const downArrowIcon = down_arrow;
// export const rightIcon = right_icon;
// export const crossIcon = cross_icon;
// export const eightDotIcon = eight_dot;
// svgs dir
export const arrowIcon = arrow;
export const closeGray = close_gray;
export const crossCircle = cross_circle;
export const compareMirror = compare_mirror;
export const dropdownWhite = dropdown_white;
export const dropdownWithBg = dropdown_with_bg;
export const editGray = edit_gray;
export const editCircle = edit_circle;
export const eightDotOffWhite = eight_dot_off_white;
export const eightDotWhite = eight_dot_white;
export const forward = forward_icon;
export const infoCyan = info_cyan;
export const plusCircle = plus_circle;
export const staticticsIcon = statictics;
export const slots0 = slots_one;
export const slots1 = slots_two;
export const slots2= slots_three;
export const slots3 = slots_four;
export const slots4 = slots_five;
export const slots5 = slots_six;
export const slots6 = slots_seven;
export const slots7 = slots_eights;
export const slots8 = slots_nine;
export const dashboard_modal = dash_modal;
