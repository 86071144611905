import React from "react";
import { useParams } from "react-router-dom";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Dropdown } from "rsuite";
import "./snippetsHeaderActions.scss";

interface ISnippetsHeaderActions {
  handleFilterOpening?: () => void;
  handleWidgetDelete?: () => void;
  handleReportReload?: () => void;
  handleModalOpenerForOptions?: (option: string) => void;
  source?: string;
  showFilter?: boolean;
}

const SnippetsHeaderActions: React.FC<ISnippetsHeaderActions> = ({
  handleFilterOpening,
  handleWidgetDelete,
  handleReportReload,
  handleModalOpenerForOptions,
  showFilter,
}: ISnippetsHeaderActions) => {
  const params: { id?: string } = useParams();

  const handleAction = (event: string) => {
    switch (event) {
      case "refresh":
        handleReportReload && handleReportReload();
        break;

      case "rename":
        handleModalOpenerForOptions && handleModalOpenerForOptions("rename");
        break;

      case "move":
        handleModalOpenerForOptions && handleModalOpenerForOptions("move");
        break;

      case "delete":
        handleWidgetDelete && handleWidgetDelete();
        break;

      default:
        return;
    }
  };

  return (
    <div className="_sn_ac_container">
      <div>
        {showFilter === undefined || true ? (
          <>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                handleFilterOpening && handleFilterOpening();
              }}
            >
              <path
                d="M7.52525 4.5H2.75C2.05964 4.5 1.5 3.94036 1.5 3.25C1.5 2.55964 2.05964 2 2.75 2H7.52525M4.47475 10H9.25C9.94036 10 10.5 9.44036 10.5 8.75C10.5 8.05964 9.94036 7.5 9.25 7.5H4.47475M1.5 8.75C1.5 9.7165 2.2835 10.5 3.25 10.5C4.2165 10.5 5 9.7165 5 8.75C5 7.7835 4.2165 7 3.25 7C2.2835 7 1.5 7.7835 1.5 8.75ZM10.5 3.25C10.5 4.2165 9.7165 5 8.75 5C7.7835 5 7 4.2165 7 3.25C7 2.2835 7.7835 1.5 8.75 1.5C9.7165 1.5 10.5 2.2835 10.5 3.25Z"
                stroke="#1D3D70"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </>
        ) : (
          showFilter === false && <></>
        )}
      </div>
      <div>
        {params.id !== undefined && (
          <Dropdown
            renderTitle={() => {
              return (
                <BiDotsVerticalRounded
                  style={{ width: "14px", height: "14px" }}
                />
              );
            }}
            onSelect={(event) => {
              handleAction(event);
            }}
            placement="leftStart"
          >
            <Dropdown.Item eventKey={"refresh"}>Refresh</Dropdown.Item>
            <Dropdown.Item divider></Dropdown.Item>
            <Dropdown.Item eventKey={"rename"}>Rename</Dropdown.Item>
            <Dropdown.Item divider></Dropdown.Item>
            <Dropdown.Item eventKey={"move"}>Move</Dropdown.Item>
            <Dropdown.Item divider></Dropdown.Item>
            <Dropdown.Item disabled>Change access</Dropdown.Item>
            <Dropdown.Item divider></Dropdown.Item>
            <Dropdown.Item eventKey={"delete"}>Delete</Dropdown.Item>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default SnippetsHeaderActions;
