import React from "react";
import "./landing-page-dashboard.scss";

type CardPropertyType = {
  image: string;
  subText: string;
  linkForDashboard: any;
};

const SettingCard = (props: CardPropertyType) => {
  const { image, subText, linkForDashboard } = props;
  const handleOnClick = () => {
    window.location.href = linkForDashboard;
  };
  return (
    <div className="cardForQuickSetting" onClick={handleOnClick}>
      <img src={image} alt="contactIcon" />
      <p className="subTextSetting"> {subText} </p>
    </div>
  );
};

export default SettingCard;
