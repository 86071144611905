import React from "react";
import "./landing-page-dashboard.scss";

type CardPropertyType = {
  image: string;
  subText: string;
  linkForDashboard: any;
};

const CardProperty = (props: CardPropertyType) => {
  const { image, subText, linkForDashboard } = props;

  const handleOnClick = () => {
    window.location.href = linkForDashboard;
  };
  return (
    <div className="cardForQuickAction" onClick={handleOnClick}>
      <img src={image} alt="contactIcon" />
      <p className="subText"> {subText} </p>
    </div>
  );
};

export default CardProperty;
