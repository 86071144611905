export const Routes = {
  CAMPAIGN: "/campaign",
};

export const BASE_URL = process.env.REACT_APP_BASE_URL_DEV;
export const CAMPAIGN_PIPELINE_BASE_URL =
  process.env.REACT_APP_CAMPAIGN_BASE_URL;
export const CAMPAIGN_BASE_URL = process.env.REACT_APP_CAMPAIGN_BASE_URL_DEV;
export const PORTAL_DASHBOARD_URL = process.env.REACT_APP_PORTAL_DASHBOARD;
export const REACT_APP_BILLING_SYSTEM = process.env.REACT_APP_BILLING_SYSTEM;
export const USER_ONBOARD_URL = process.env.REACT_APP_USER_ONBOARD_BASE_URL;

export const mauticRoutes = {
  GET_CAMPAIGN_LIST: CAMPAIGN_BASE_URL + "/campaigns",
  GET_CAMPAIGN_CATEGORY: CAMPAIGN_BASE_URL + "/categories",
  FETCH_CONTACT_SEGMENT: CAMPAIGN_BASE_URL + "/segments",
  FETCH_CAMPAIGN_FORMS: CAMPAIGN_BASE_URL + "/forms",
  GET_CAMPAIGN: CAMPAIGN_BASE_URL + "",
  CREATE_CAMPAIGN: CAMPAIGN_BASE_URL + "/campaigns/new",
};

export const portalRoutes = {
  GET_MEETING: PORTAL_DASHBOARD_URL + "/meeting-complete-status",
  GET_DEALS: PORTAL_DASHBOARD_URL + "/deals-stage-with-range",
  GET_TICKETS: PORTAL_DASHBOARD_URL + "/ticket-list",
  GET_CALENDER: PORTAL_DASHBOARD_URL + "/meeting-list",
  GET_CAMPAIGN: PORTAL_DASHBOARD_URL + "/campaign",
};

export const ANALYTICS_AND_REPORTING_DASHBOARD_URL =
  process.env.REACT_APP_ANALYTICS_AND_REPORTING_DASHBOARD_URL;

export const ApiRoutes = {
  LOGIN: BASE_URL + "/api/v1/auth/login",
  REGISTER: BASE_URL + "/api/v1",
  GET_LAST_ACTIVE_ACCOUNT: BASE_URL + "/api/v1/users/user-and-active-account/",
  GET_USER_BY_ID: BASE_URL + "/api/v1/users/",
  GET_INDUSTRY_LIST: BASE_URL + "/api/v1/core/industry_list/",
  GET_ALL_ACCOUNTS: BASE_URL + "/api/v1/accounts/account_list/",
  UPDATE_ACTIVE_ACCOUNT: BASE_URL + "/api/v1/users/active-account/",
  BILLPAYMENT: REACT_APP_BILLING_SYSTEM + "/api/v1/billing-system",
  GET_USERS_BY_ACC_ID: BASE_URL + "/api/v1/users/accounts/",

  // For Pipeline
  GET_PIPELINES_BY_ACCOUNT:
    CAMPAIGN_PIPELINE_BASE_URL + "/pipelines/by-account/",
  // For Teams
  GET_ALL_TEAMS: `${BASE_URL}/api/v1/teams`,

  //For Auth
  AUTH_VERIFY_EMAIL: BASE_URL + "/api/v1/users/verify-email",

  //For Users
  CREATE_USER: BASE_URL + "/api/v1/users/",
  VERIFY_USER: BASE_URL + "/api/v1/users/account-confirmation/",

  USER_IMAGE: BASE_URL + "/api/v1/users/user-profile-picture/",
  GET_PAGINATED_USER_LIST:
    BASE_URL + "/api/v1/users/paginated/added_user_list/",
  //Packages section

  PACKAGES_BASE: BASE_URL + "/api/v1/admin/packages",

  //For Campaign
  FETCH_CONTACT_SEGMENT: CAMPAIGN_BASE_URL + "/segments",
  CREATE_CAMPAIGN: CAMPAIGN_BASE_URL + "/campaigns/new",
  GET_CAMPAIGN_LIST: CAMPAIGN_BASE_URL + "/campaigns",
  GET_CAMPAIGN_CATEGORY: CAMPAIGN_BASE_URL + "/categories",

  EMAIL_BASE: CAMPAIGN_BASE_URL + "/api/v1/email-campaign/",
  UPSERT_CAMPAIGN_CONTACT_ASSOCIATION:
    CAMPAIGN_BASE_URL +
    "/api/v1/email-campaign/upsert-campaign-email-and-contact-association/",
  CREATE_CAMPAIGN_LIST_CONTACT_ASSOCIATION:
    CAMPAIGN_BASE_URL +
    "/api/v1/email-campaign/create-campaign-email-and-contact-multiple-association/",
  GET_CAMPAIGN_CONTACT_ASSOCIATION:
    CAMPAIGN_BASE_URL +
    "/api/v1/email-campaign/get-campaign-email-and-contact-association/",
  DELETE_CAMPAIGN_CONTACT_ASSOCIATION:
    CAMPAIGN_BASE_URL +
    "/api/v1/email-campaign/delete-campaign-email-and-contact-association/",
  GET_CAMPAIGN_GROUP_ASSOCIATION:
    CAMPAIGN_BASE_URL +
    "/api/v1/email-campaign/get-campaign-email-and-group-association/",
  DELETE_CAMPAIGN_GROUP_ASSOCIATION:
    CAMPAIGN_BASE_URL +
    "/api/v1/email-campaign/delete-campaign-email-and-group-association/",
  GET_CURRENCY_LIST:
    CAMPAIGN_BASE_URL + "/api/v1/email-campaign/currency-list/",

  //For Social Media
  SOCIAL_MEDIA_FACEBOOK: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/",
  SOCIAL_MEDIA_FACEBOOK_PAGES:
    CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/pages/accounts/",
  SOCIAL_MEDIA_FACEBOOK_POST_COUNTS:
    CAMPAIGN_BASE_URL +
    "/api/v1/facebook-posts/all-status/post-counts/accounts/",
  SOCIAL_MEDIA_GET_POST: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/",
  SOCIAL_MEDIA_GET_POST_STASUSES:
    CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/post-status/all",
  SOCIAL_MEDIA_POST_LIST:
    CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/list/accounts/",
  SOCIAL_UPDATE_FACEBOOK_POST: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/",
  SOCIAL_DELETE_FACEBOOK_POST: CAMPAIGN_BASE_URL + "/api/v1/facebook-posts/",
  SOCIAL_GET_BEST_PRACTICE_FACEBOOK:
    CAMPAIGN_BASE_URL + "/api/v1/social-media-best-practices/accounts/",

  //For Email Campaign Event
  GET_FILTER_EMAIL_CAMPAIGN_EVENT:
    CAMPAIGN_BASE_URL + "/api/v1/email-campaign-events/filter/by-date",

  //For Contact
  GET_CONTACTS_LIST: BASE_URL + "/api/v1/contacts/contact-list-details/",
  GET_CONTACTS_LIST_BY_ACCOUNT: BASE_URL + "/api/v1/contacts/accounts/",

  //For Groups
  CONTACT_GROUP: BASE_URL + "/api/v1/contact-group/",
  GET_GROUPS: BASE_URL + "/api/v1/contact-group/by-account/",

  //For Email Best Practices
  CREATE_EMAIL_BEST_PRACTICES:
    CAMPAIGN_BASE_URL + "/api/v1/campaign-email-best-practices/",
  GET_EMAIL_BEST_PRACTICES:
    CAMPAIGN_BASE_URL + "/api/v1/campaign-email-best-practices/accounts/",
  //Generate URL
  GENERATE_URL: CAMPAIGN_BASE_URL + "/api/v1/campaign-url/email/generate/",

  //smtp section
  SMTP_BASE: CAMPAIGN_BASE_URL + "/api/v1/campaign-email-settings/",

  //campaign wise smtp section
  CAMPAIGN_SMTP_BASE:
    CAMPAIGN_BASE_URL + "/api/v1/campaign-wise-email-settings/",

  // For Roles & Permissions
  GET_USER_ROLES_AND_PERMISSIONS:
    BASE_URL + "/api/v1/role/current-user/permission_list",
  GET_ALL_ROLES_AND_PERMISSIONS: BASE_URL + "/api/v1/role/permission/get_all/",

  //Notification
  GET_NOTIFICATION:
    BASE_URL + "/api/v1/notification/paginated/notification_list/",
};

export const AnalyticsAndReportingDashBoardsApiRoutes = {
  DELETE_DASHBOARD: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/soft-delete`,
  RESTORE_DASHBOARD: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/restore-dashboard`,
  DEFAULT_DASHBOARD: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/make-default-dashboard`,
  GET_ALL_WIDGETS: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/get-all-widgets`,
  CREATE_NEW_DASHBOARD: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/create-dashboard`,
  GET_ALL_DASHBOARDS: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/get-all-dashboards`,
  GET_ALL_DELETED_DASHBOARDS: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/get-soft-deleted-dashboards`,
  GET_DASHBOARD_DETAILS: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/get-dashboard`,
  GET_CONTACT_VS_RECENT_DATA: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/contact/contact-source`,
  GET_CONTACT_CREATION_DATA: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/contact/contact-creation-stats`,
  GET_TASK_CREATION_DATA: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tasks/source`,
  CONTACT_VS_TIME: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/contact/contact-creation-by-day`,
  TOTAL_BY_SOURCE: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/contact/total-by-source`,
  TOTAL_BY_PERSON: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/contact/total-by-person`,
  COMPANY_VS_RECENT: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/company/source`,
  total_company_created_vs_last_week: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/company/creation`,
  COMPANY_VS_TIME: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/company/creation-by-day`,
  COMPANY_CREATION_TOTAL_BY_PERSON: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/company/total-by-person`,
  TOTAL_TICKET_CREATED_VS_LAST_WEEK: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/ticket/source`,
  TICKET_VS_MONTH: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/ticket/per-month-create`,
  TASK_VS_MONTH: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tasks/per-month-create`,
  STAGE_WISE_DEALS_AMOUNT: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/deals/stage-wise-total-amount`,
  STAGE_WISE_TICKET_COUNT: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/stage-count`,
  DEALS_IN_A_STAGE_COUNT: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/deals/deals-stage-count`,
  TICKET_STATUS: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/ticket/status`,
  TICKET_CREATED: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/created`,
  TASK_STATUS: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tasks/status`,
  GET_ALL_WIDGETS_FOR_REPORT: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/reports-config/get-all-widgets`,
  CREATE_NEW_REPORT: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/reports-config/create-report`,
  GET_ALL_REPORTS_LIST: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/reports-config/get-all-reports`,
  DELETE_REPORT: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/reports-config/delete-report`,
  DELETE_WIDGET_FROM_DASHBOARD: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/delete-dashboard-widgets`,
  ADD_REPORT_TO_DASHBOARD: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/reports-config/add-report-dashboard`,
  RENAME_REPORT_TITLE: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/update-dashboard-widget`,
  MOVE_REPORT_FROM_DASHBOARD: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/move-widget`,
  GET_ALL_TEAMS: `${BASE_URL}/api/v1/teams`,
  GET_REPORTS_WITH_TAG_SCOPE: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/get-widgets-tag-name`,
  UPDATE_DASHBOARD_RANK: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/dashboard-config/update-dashboard`,
  CREATED_LAST_24H: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/created-last-24h`,
  GET_FISCAL_YEAR: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/kpi/fiscal-year-generator`,
  CLOSED_LAST_24H: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/closed-last-24h`,
  TOTAL_CONTACT_CREATED_VS_LAST_WEEK: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/contact/contact-creation-stats`,
  TOTAL_COMPANY_CREATED: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/company/analytics/cards/total`,
  LAST_WEEK_COMPANY_CREATED: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/company/analytics/cards/lastWeek`,
  TOP_MONTH_COMPANY_CREATION: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/company/analytics/cards/topMonth`,
  AVERAGE_COMPANY_CREATION_PER_MONTH: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/company/analytics/cards/avgMonth`,
  TOTAL_TASK_COUNT: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tasks/analytics/cards/total`,
  LAST_WEEK_TOTAL_TASK_COUNT: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tasks/analytics/cards/lastWeek`,
  TOP_MONTH_TASK_ANALYTICS: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tasks/analytics/cards/topMonth`,
  AVG_MONTH_TASK_ANALYTICS_CARDS: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tasks/analytics/cards/avgMonth`,
  GET_MAX_DEAL_AMOUNT_MONTH: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/deals/max-amount-month`,
  GET_LOWEST_DEAL_AMOUNT_MONTH: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/deals/lowest-amount-month`,
  MONTH_WISE_DEALS_AMOUNT: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/deals/total-amount-by-month`,
  get_total_ticket: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/total`,
  GET_TOTAL_OPEN_TICKET: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/total-open`,
  GET_TOTAL_CLOSE_TICKET: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/total-closed`,
  GET_TOTAL_TICKET_IN_PROGRESS: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/total-in-progress`,
  AVERAGE_TICKET_RESOLVE_TIME: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/average-resolve-time`,
  TICKET_CLOSE_BEFORE: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/close-before`,
  TICKET_CLOSE_AFTER: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/close-after`,
  TICKET_PERSON_WISE_IN_PROGRESS: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/persons-wise-in-progress`,
  TICKET_PERSON_WISE_OPEN: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/persons-wise-open`,
  TICKET_PERSON_WISE_CLOSE: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/tickets/persons-wise-closed`,
  // ============= kpi
  KPI_TOTAL_COMPANY_TARGET: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/kpi/total-company-target`,
  KPI_ACHIEVED_TILL_DATE: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/kpi/achieved-till-date`,
  KPI_BACKLOG_TILL_DATE: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/kpi/backlog-till-date`,
  KPI_ACHIEVED_THIS_MONTH: `${ANALYTICS_AND_REPORTING_DASHBOARD_URL}/api/v1/kpi/achieved-this-month`,
};

export const CampaignApiRoutes = {
  CREATE_TASK_QUEUE: CAMPAIGN_BASE_URL + "/task-queues/",
};

export const roboketCrmUi = {
  BASE_URL: process.env.REACT_APP_CRM_UI_URL_DEV,
  Campaign_URL: process.env.REACT_APP_CAMPAIGN_UI_URL_DEV,
  Ads: process.env.REACT_APP_ADS_BASE_URL_DEV,
};

export const userOnboardUrls = {
  marketing: `${USER_ONBOARD_URL}/api/v1/user-on-board/marketing/module-progress`,
  sales: `${USER_ONBOARD_URL}/api/v1/user-on-board/sales/module-progress`,
  customer_support: `${USER_ONBOARD_URL}/api/v1/user-on-board/customer_support/module-progress`,
  setup: `${USER_ONBOARD_URL}/api/v1/user-on-board/setup/module-progress`,
};
