// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._sn_ac_container {
  display: flex;
  gap: 7px;
  align-items: center;
}
._sn_ac_container svg {
  height: 13px;
  width: 13px;
  color: #1d3d70;
}
._sn_ac_container svg:hover {
  cursor: pointer;
}
._sn_ac_container svg:nth(2) {
  width: 14px;
}`, "",{"version":3,"sources":["webpack://./src/micro/snippets/snippetsHeaderActions.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;EACA,cAAA;AACJ;AACI;EACE,eAAA;AACN;AAGE;EACE,WAAA;AADJ","sourcesContent":["._sn_ac_container {\n  display: flex;\n  gap: 7px;\n  align-items: center;\n\n  svg {\n    height: 13px;\n    width: 13px;\n    color: #1d3d70;\n\n    &:hover {\n      cursor: pointer;\n    }\n  }\n\n  svg:nth(2) {\n    width: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
