// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardParentForContact {
  width: 100%;
  flex-shrink: 0;
  border-radius: var(--radius-btn-radius, 8px);
  border: 2px solid #f0f2f5;
  padding: 28px;
  margin-bottom: 13px;
}
.cardParentForContact .contactFlex {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
.cardParentForContact .contactFlex .talk {
  color: #1d3d70;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px;
}
.cardParentForContact .contactFlex .numberPhn {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
}
.cardParentForContact .contactFlex .contentDesc {
  color: #727272;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}
.cardParentForContact .contactFlex .callBtn {
  padding: 8px 62px;
  background-color: #1d3d70;
  color: white;
  margin-top: 9px;
  font-size: 10px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/common/components/userGuideOnboard/contactusCard/contactus.card.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;EACA,4CAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,SAAA;EACA,uBAAA;AAEJ;AADI;EACE,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAGN;AADI;EACE,WAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAGN;AADI;EACE,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAGN;AADI;EACE,iBAAA;EACA,yBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;AAGN","sourcesContent":[".cardParentForContact {\n  width: 100%;\n  flex-shrink: 0;\n  border-radius: var(--radius-btn-radius, 8px);\n  border: 2px solid #f0f2f5;\n  padding: 28px;\n  margin-bottom: 13px;\n  .contactFlex {\n    display: flex;\n    gap: 20px;\n    align-items: flex-start;\n    .talk {\n      color: #1d3d70;\n      font-family: Poppins;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 19.6px;\n    }\n    .numberPhn {\n      color: #000;\n      font-family: Poppins;\n      font-size: 12px;\n      font-style: normal;\n      font-weight: 600;\n      line-height: 16.8px;\n    }\n    .contentDesc {\n      color: #727272;\n      font-family: Poppins;\n      font-size: 12px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 16.8px;\n    }\n    .callBtn {\n      padding: 8px 62px;\n      background-color: #1d3d70;\n      color: white;\n      margin-top: 9px;\n      font-size: 10px;\n      font-weight: 600;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
