// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-btn-toggle-checked {
  background-color: #00ab86 !important;
}

.iconText {
  gap: 20px;
}

.permanentBtn {
  background-color: red;
  color: white;
  font-weight: 500;
  padding: 3px 13px;
}

.restoreBtn {
  background-color: green;
  color: white;
  padding: 3px 13px;
  font-weight: 500;
}

.toggle-btn-custom:hover {
  background-color: none;
}`, "",{"version":3,"sources":["webpack://./src/components/DashboardTable/helpers/dashboards.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EACE,qBAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAGE;EACE,sBAAA;AAAJ","sourcesContent":[".rs-btn-toggle-checked {\n  background-color: #00ab86 !important;\n}\n\n.iconText {\n  gap: 20px;\n}\n\n.permanentBtn {\n  background-color: red;\n  color: white;\n  font-weight: 500;\n  padding: 3px 13px;\n}\n\n.restoreBtn {\n  background-color: green;\n  color: white;\n  padding: 3px 13px;\n  font-weight: 500;\n}\n\n.toggle-btn-custom {\n  &:hover {\n    background-color: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
