import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAllReportsList } from "src/common/analytics-and-dashboard-api-calls";
import LoadingComponent from "src/common/components/loading/Loading";

const ReportBouncer: React.FC = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getDashboardInfo = async () => {
      const response = await getAllReportsList();
      if (response.error) {
        setIsLoading(false);
        history.push("/report-creation");
      } else {
        if (response.data.response.length === 0) {
          setIsLoading(false);
          history.push("/report-creation");
        } else {
          history.push("/report-lists");
        }
      }
    };

    getDashboardInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bouncer-container">{isLoading && <LoadingComponent />}</div>
  );
};

export default ReportBouncer;
