import React from "react";
import { Button } from "rsuite";
import { useHistory } from "react-router-dom";

import "./createReportHeader.scss";

const CreateReportHeader: React.FC = () => {
  const history = useHistory();
  return (
    <div className="inbox-top-section shadow-sm">
      <div className="top-elements-wrapper">
        <div className="inner __title">Create report</div>
        <div className="actions">
          <Button
            className="green-button"
            onClick={() => {
              history.push(`/report-lists`);
            }}
          >
            View Your Reports
          </Button>
          {/* <Button
            onClick={() => {
              history.push(`/create-report`);
            }}
          >
            Create New Reports
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default CreateReportHeader;
