export const ticketText = [
  {
    id: 1,
    description:
      "In your ROBOKET account, at the top right, click the question help icon in the main navigation bar.",
  },
  {
    id: 2,
    description: "To review your support tickets, click Support inbox.",
  },
  {
    id: 3,
    description: (
      <>
        In the left sidebar of your Support inbox, you can filter your tickets.
        Users with{" "}
        <span className="highlightText">Account access permissions</span> can
        view tickets submitted by all users in the account by selecting the
        Include company tickets checkbox.
      </>
    ),
  },
];
export const percentageData=
  {
    data: 60
  }
 
