// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-container .error-container-content {
  height: 635px;
  max-width: 600px;
  margin: 50px auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.error-container .error-container-content h1 {
  font-size: 36px;
  margin-bottom: 20px;
}
.error-container .error-container-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
.error-container .error-container-content p {
  font-size: 18px;
  margin-bottom: 20px;
}
.error-container .error-container-content ol {
  text-align: left;
  margin-bottom: 20px;
}
.error-container .error-container-content ol li {
  font-size: 16px;
  margin-bottom: 10px;
}
.error-container .error-container-content ol li a {
  color: #007bff;
  text-decoration: none;
}
.error-container .error-container-content ol li a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/notAllowed.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,wCAAA;AAEJ;AAAI;EACE,eAAA;EACA,mBAAA;AAEN;AACI;EACE,eAAA;EACA,mBAAA;AACN;AAEI;EACE,eAAA;EACA,mBAAA;AAAN;AAGI;EACE,gBAAA;EACA,mBAAA;AADN;AAGM;EACE,eAAA;EACA,mBAAA;AADR;AAGQ;EACE,cAAA;EACA,qBAAA;AADV;AAGU;EACE,0BAAA;AADZ","sourcesContent":[".error-container {\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  .error-container-content {\n    height: 635px;\n    max-width: 600px;\n    margin: 50px auto;\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n\n    h1 {\n      font-size: 36px;\n      margin-bottom: 20px;\n    }\n\n    h2 {\n      font-size: 24px;\n      margin-bottom: 10px;\n    }\n\n    p {\n      font-size: 18px;\n      margin-bottom: 20px;\n    }\n\n    ol {\n      text-align: left;\n      margin-bottom: 20px;\n\n      li {\n        font-size: 16px;\n        margin-bottom: 10px;\n\n        a {\n          color: #007bff;\n          text-decoration: none;\n\n          &:hover {\n            text-decoration: underline;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
