import React, { createContext, useEffect, useState } from "react";
import "./styles/createReport.scss";
import { Container, Content, Header } from "rsuite";
import CreateReportHeader from "src/components/headers/CreateReportHeader";
import SidebarComponentReport from "src/components/Sidebar/createReport/SidebarComponentReport";
import CreateReportContainer from "src/components/CreateReportContainer/CreateReportContainer";
import { IDashboardList } from "src/common/types/analytics.types";
import { getDashboardLists } from "src/common/analytics-and-dashboard-api-calls";

interface ReportContextType {
  reportWidgetSelectedList: number[];
  setReportWidgetSelectedList: React.Dispatch<React.SetStateAction<number[]>>;
  dashboards?: IDashboardList[];
}

export const ReportContextAPI = createContext<ReportContextType>({
  reportWidgetSelectedList: [],
  setReportWidgetSelectedList: () => [],
  dashboards: [],
});

const CreateReport: React.FC = () => {
  const [reportWidgetSelectedList, setReportWidgetSelectedList] = useState<
    number[]
  >([]);
  const [dashboards, setDashboards] = useState<IDashboardList[]>([]);
  const getDashboardInfo = async () => {
    const response = await getDashboardLists();
    if (response.data.length > 0) {
      let modified: IDashboardList[] = [];
      response.data.map((data: any) => {
        return modified.push({
          value: data.id,
          label: data.dashboard_name,
        });
      });
      setDashboards(modified);
    }
  };
  useEffect(() => {
    getDashboardInfo();
  }, []);

  return (
    <ReportContextAPI.Provider
      value={{
        reportWidgetSelectedList: reportWidgetSelectedList,
        setReportWidgetSelectedList: setReportWidgetSelectedList,
        dashboards,
      }}
    >
      <Content className="main_content">
        <Container>
          <Header>
            <CreateReportHeader />
          </Header>
          <div className="__report_container">
            <SidebarComponentReport />
            <CreateReportContainer />
          </div>
        </Container>
      </Content>
    </ReportContextAPI.Provider>
  );
};

export default CreateReport;
