import React from 'react'
import { Link } from 'react-router-dom'
import learnMore from "../assets/svgs/learnMore.svg";

const FourthCard = () => {
    return (
        <div className="cardThirdMarket">
            <div className="innerCard">
                <div className="gapBetIconTxt">
                    <img src={learnMore} alt="attCustomSvg" />
                    <p>Learn more about marketing tools </p>
                </div>

                <Link to={"#"} style={{ textDecoration: "none" }}>
                    {" "}
                    <p className="subHead">
                        Take a demo of your marketing tools
                    </p>{" "}
                </Link>

                <Link to={"#"} style={{ textDecoration: "none" }}>
                    {" "}
                    <p className="subHead">
                        {" "}
                        Get started with a 4 minute lesson on attracting the right
                        audience
                    </p>{" "}
                </Link>

                <Link to={"#"} style={{ textDecoration: "none" }}>
                    {" "}
                    <p className="subHead">
                        Connect marketing apps from the App Marketplace
                    </p>{" "}
                </Link>
            </div>
        </div>
    )
}

export default FourthCard
