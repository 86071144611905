// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.__dashboard_container {
  padding: 0px 30px 0px;
  display: flex;
  gap: 20px;
}

.celVQH {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 56vh;
  overflow: auto;
}

.main_content .rs-container {
  flex-direction: column !important;
}

.bg-container {
  background-color: white;
}

.editBtn {
  border-radius: var(--Border-Radius-4-px, 4px);
  border: 1px solid #1d3d70;
  background: #fff;
  color: #1d3d70;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  display: inline-flex;
  height: 28px;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/dashboard-list.scss"],"names":[],"mappings":"AAAA;EAGE,qBAAA;EACA,aAAA;EACA,SAAA;AADF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;AAAF;;AAGE;EACE,iCAAA;AAAJ;;AAGA;EACE,uBAAA;AAAF;;AAEA;EACE,6CAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,oBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AACF","sourcesContent":[".__dashboard_container {\n  // height: 72vh;\n  // overflow: auto;\n  padding: 0px 30px 0px;\n  display: flex;\n  gap: 20px;\n}\n.celVQH {\n  display: flex;\n  flex-direction: column;\n  height: auto;\n  max-height: 56vh;\n  overflow: auto;\n}\n.main_content {\n  .rs-container {\n    flex-direction: column !important;\n  }\n}\n.bg-container {\n  background-color: white;\n}\n.editBtn {\n  border-radius: var(--Border-Radius-4-px, 4px);\n  border: 1px solid #1d3d70;\n  background: #fff;\n  color: #1d3d70;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  display: inline-flex;\n  height: 28px;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
