import React from "react";
import { Link } from "react-router-dom";
import attCustomSvg from "../assets/svgs/attractCustom.svg";
import newTabIcon from "../assets/svgs/newTabIcon.svg";
import salesClose from "../assets/svgs/salesClose.svg";
import customerPrio from "../assets/svgs/customerPrioritize.svg";
import cmsBuild from "../assets/svgs/cmsBuild.svg";
import { DrawerComponentState } from "src/common/components/adn-drawer/adn-drawer.component";
import {
  setDrawerName,
  toggleDrawer,
} from "src/common/redux/drawer/drawer.actions";
import { connect } from "react-redux";
import EngageBody from "./drawer-body/EngageBody";
import AttractBody from "./drawer-body/AttractBody";
import TrackBody from "./drawer-body/TrackBody";
import TrackDealBody from "./drawer-body/TrackDeal";
import QuickLeadBody from "./drawer-body/QuickLead";
import CloseDealBody from "./drawer-body/CloseDealBody";
import DeliverCustomerBody from "./drawer-body/DeliverCustomerBody";
import GrowAudienceBody from "./drawer-body/GrowAudience";
import OwnerWebsiteBody from "./drawer-body/OwnerWebsiteBody";

enum AttractEnum {
  DrawerName = "Attract and capture leads",
  DrawerNameEngage = "Engage leads and convert them to customers",
  DrawerNameTrack = "Track and analyze performance",
  DrawerTrackDeal = "Track your deals in one place",
  DrawerQuickLead = "Quickly connect with leads",
  DrawerCloseDeal = "Close deals and analyze performance",
  DrawerDeliverCustomer = "Deliver world-class customer service",
  DrawerGrowAudience = "Grow your audience with search engine-friendly content",
  DrawerOwnerWebsite = "Take ownership of your website",
}

const ThirdCard = (props: any) => {
  const { selectedMenuItem, drawerName, toggleDrawer, setDrawerName } = props;

  const openDrawer = () => {
    setDrawerName(AttractEnum.DrawerName);
    toggleDrawer(true);
  };

  const openDrawerEngage = () => {
    setDrawerName(AttractEnum.DrawerNameEngage);
    toggleDrawer(true);
  };

  const openDrawerTrack = () => {
    setDrawerName(AttractEnum.DrawerNameTrack);
    toggleDrawer(true);
  };

  const openDrawerTrackDeal = () => {
    setDrawerName(AttractEnum.DrawerTrackDeal);
    toggleDrawer(true);
  };

  const openDrawerQuickLead = () => {
    setDrawerName(AttractEnum.DrawerQuickLead);
    toggleDrawer(true);
  };

  const openDrawerCloseDeal = () => {
    setDrawerName(AttractEnum.DrawerCloseDeal);
    toggleDrawer(true);
  };

  const openDrawerDeliverCustomer = () => {
    setDrawerName(AttractEnum.DrawerDeliverCustomer);
    toggleDrawer(true);
  };

  const openDrawerDrawerOwnerWebsite = () => {
    setDrawerName(AttractEnum.DrawerOwnerWebsite);
    toggleDrawer(true);
  };

  const openDrawerDrawerGrowAudience = () => {
    setDrawerName(AttractEnum.DrawerGrowAudience);
    toggleDrawer(true);
  };

  const text =
    "Up next: Grow your audience with search engine-friendly content";
  const maxLength = 47;
  const truncatedText =
    text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

  return (
    <div className="cardThirdMarket">
      <div className="innerCard">
        <div className="gapBetIconTxt">
          {selectedMenuItem === "Marketing" && (
            <>
              <img src={attCustomSvg} alt="attCustomSvg" />
              <Link to={"#"} style={{ textDecoration: "none" }}>
                {" "}
                <p>Attract new customers with Marketing </p>{" "}
              </Link>
            </>
          )}
          {selectedMenuItem === "Sales" && (
            <>
              <img src={salesClose} alt="salesClose" />
              <Link to={"#"} style={{ textDecoration: "none" }}>
                {" "}
                <p>Close more deals faster with Sales </p>{" "}
              </Link>
            </>
          )}
          {selectedMenuItem === "Customer Service" && (
            <>
              <img src={customerPrio} alt="customerPrio" />
              <Link to={"#"} style={{ textDecoration: "none" }}>
                {" "}
                <p>Prioritize your customer experience with Service </p>{" "}
              </Link>
            </>
          )}
          {selectedMenuItem === "Website (CMS)" && (
            <>
              <img src={cmsBuild} alt="cmsBuild" />
              <Link to={"#"} style={{ textDecoration: "none" }}>
                {" "}
                <p>Build and manage your website with CMS </p>{" "}
              </Link>
            </>
          )}
        </div>

        {selectedMenuItem === "Marketing" && (
          <>
            <p className="subHead" onClick={openDrawer}>
              Attract and capture leads
            </p>
            <p className="subText">
              Create professional-looking forms to convert website visitors into
              leads
            </p>{" "}
            <p className="subHead" onClick={openDrawerEngage}>
              Engage leads and convert them to customers
            </p>{" "}
            <p className="subText">
              Create professional-looking forms to convert website visitors into
              leads
            </p>{" "}
            <p className="subHead" onClick={openDrawerTrack}>
              Track and analyze performance
            </p>{" "}
            <p className="subText">
              Install your ROBOKET tracking code, and learn more about your
              visitors’ website activity
            </p>
          </>
        )}

        {selectedMenuItem === "Sales" && (
          <>
            <p className="subHead" onClick={openDrawerTrackDeal}>
              Track your deals in one place
            </p>
            <p className="subText">
              Create professional-looking forms to convert website visitors into
              leads
            </p>
            <p className="subHead" onClick={openDrawerQuickLead}>
              Quickly connect with leads
            </p>{" "}
            <p className="subText">
              Create professional-looking forms to convert website visitors into
              leads
            </p>{" "}
            <p className="subHead" onClick={openDrawerCloseDeal}>
              Close deals and analyze performance
            </p>{" "}
            <p className="subText">
              Install your ROBOKET tracking code, and learn more about your
              visitors’ website activity
            </p>
          </>
        )}

        {selectedMenuItem === "Customer Service" && (
          <>
            {" "}
            <p className="subHead" onClick={openDrawerDeliverCustomer}>
              Deliver world-class customer service
            </p>
            <p className="subText">
              Create professional-looking forms to convert website visitors into
              leads
            </p>
          </>
        )}

        {selectedMenuItem === "Website (CMS)" && (
          <>
            {" "}
            <p className="subHead" onClick={openDrawerDrawerOwnerWebsite}>
              Take ownership of your website
            </p>
            <p className="subText">
              Create professional-looking forms to convert website visitors into
              leads
            </p>{" "}
            <p className="subHead" onClick={openDrawerDrawerGrowAudience}>
              Grow your audience with search engine-friendly content
            </p>{" "}
            <p className="subText">
              Create professional-looking forms to convert website visitors into
              leads
            </p>
          </>
        )}
      </div>

      {drawerName === AttractEnum.DrawerName && (
        <DrawerComponentState
          backdrop={true}
          isDrawerShow={true}
          toggleDrawer={toggleDrawer}
          setDrawerName={setDrawerName}
          title="Attract and capture leads"
          size={"xs"}
          footer={
            <div className="bottom_div">
              <div className="border_bottom">
                <p className="border_bottom_p" style={{ paddingLeft: "15px" }}>
                  {" "}
                  <p className="footerFlex">
                    {" "}
                    Up next: Engage leads and convert them to custom{" "}
                    <img src={newTabIcon} alt="newTabIcon" />{" "}
                  </p>
                </p>
              </div>
            </div>
          }
        >
          <AttractBody />
        </DrawerComponentState>
      )}

      {drawerName === AttractEnum.DrawerNameEngage && (
        <DrawerComponentState
          backdrop={true}
          isDrawerShow={true}
          toggleDrawer={toggleDrawer}
          setDrawerName={setDrawerName}
          title="Engage leads and convert 
          them to customers"
          size={"xs"}
          footer={
            <div className="bottom_div">
              <div className="border_bottom">
                <p className="border_bottom_p">
                  {" "}
                  <p className="footerFlex">
                    Up next: Track and analyze performance{" "}
                    <img src={newTabIcon} alt="newTabIcon" />
                  </p>
                </p>
              </div>
            </div>
          }
        >
          <EngageBody />
        </DrawerComponentState>
      )}

      {drawerName === AttractEnum.DrawerNameTrack && (
        <DrawerComponentState
          backdrop={true}
          isDrawerShow={true}
          toggleDrawer={toggleDrawer}
          setDrawerName={setDrawerName}
          title="Track and analyze performance"
          size={"xs"}
          footer={""}
        >
          <TrackBody />
        </DrawerComponentState>
      )}

      {drawerName === AttractEnum.DrawerTrackDeal && (
        <DrawerComponentState
          backdrop={true}
          isDrawerShow={true}
          toggleDrawer={toggleDrawer}
          setDrawerName={setDrawerName}
          title="Track your deals in one place"
          size={"xs"}
          footer={
            <div className="bottom_div">
              <div className="border_bottom">
                <p className="border_bottom_p" style={{ paddingLeft: "69px" }}>
                  {" "}
                  <p className="footerFlex">
                    {" "}
                    Up next: Quickly connect with leads{" "}
                    <img src={newTabIcon} alt="newTabIcon" />{" "}
                  </p>
                </p>
              </div>
            </div>
          }
        >
          <TrackDealBody />
        </DrawerComponentState>
      )}

      {drawerName === AttractEnum.DrawerQuickLead && (
        <DrawerComponentState
          backdrop={true}
          isDrawerShow={true}
          toggleDrawer={toggleDrawer}
          setDrawerName={setDrawerName}
          title="Quickly connect with leads"
          size={"xs"}
          footer={
            <div className="bottom_div">
              <div className="border_bottom">
                <p className="border_bottom_p" style={{ paddingLeft: "33px" }}>
                  {" "}
                  <p className="footerFlex">
                    {" "}
                    Up next: Close deals and analyze performance{" "}
                    <img src={newTabIcon} alt="newTabIcon" />{" "}
                  </p>
                </p>
              </div>
            </div>
          }
        >
          <QuickLeadBody />
        </DrawerComponentState>
      )}

      {drawerName === AttractEnum.DrawerCloseDeal && (
        <DrawerComponentState
          backdrop={true}
          isDrawerShow={true}
          toggleDrawer={toggleDrawer}
          setDrawerName={setDrawerName}
          title="Close deals and analyze performance"
          size={"xs"}
          footer={""}
        >
          <CloseDealBody />
        </DrawerComponentState>
      )}

      {drawerName === AttractEnum.DrawerDeliverCustomer && (
        <DrawerComponentState
          backdrop={true}
          isDrawerShow={true}
          toggleDrawer={toggleDrawer}
          setDrawerName={setDrawerName}
          title="Deliver world-class customer service"
          size={"xs"}
          footer={""}
        >
          <DeliverCustomerBody />
        </DrawerComponentState>
      )}

      {drawerName === AttractEnum.DrawerOwnerWebsite && (
        <DrawerComponentState
          backdrop={true}
          isDrawerShow={true}
          toggleDrawer={toggleDrawer}
          setDrawerName={setDrawerName}
          title="Take ownership of your website"
          size={"xs"}
          footer={
            <div className="bottom_div">
              <div className="border_bottom">
                <p className="border_bottom_p" style={{ paddingLeft: "15px" }}>
                  {" "}
                  <p className="footerFlex">
                    {truncatedText}
                    {text.length > maxLength && (
                      <img src={newTabIcon} alt="newTabIcon" />
                    )}
                  </p>
                </p>
              </div>
            </div>
          }
        >
          <OwnerWebsiteBody />
        </DrawerComponentState>
      )}

      {drawerName === AttractEnum.DrawerGrowAudience && (
        <DrawerComponentState
          backdrop={true}
          isDrawerShow={true}
          toggleDrawer={toggleDrawer}
          setDrawerName={setDrawerName}
          title="Grow your audience with search engine-friendly content"
          size={"xs"}
          footer={""}
        >
          <GrowAudienceBody />
        </DrawerComponentState>
      )}
    </div>
  );
};

const mapStateToProps = ({ drawer: { drawerName } }: any) => ({
  drawerName,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleDrawer: (toggle: boolean) => dispatch(toggleDrawer(toggle)),
  setDrawerName: (name: string) => dispatch(setDrawerName(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThirdCard);
