import React from "react";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";

function TrackBody() {
  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="drawerSubTitle">
        <div>
          <p className="title">Create a marketing reporting dashboard</p>
          <p className="title_p">
            Organize your marketing related reports into one view using a
            dashboard.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p className="title">Add the tracking code to your site</p>
          <p className="title_p">
            See what visitors are doing on your website and capture their
            details in your contacts database.
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default TrackBody;
