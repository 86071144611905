import React from "react";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";

function QuickLeadBody() {
  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="drawerSubTitle">
        <div>
          <p className="title">Select a sales email template </p>
          <p className="title_p">
            Use templates to make your most effective sales emails reusable.
          </p>
          <hr />
        </div>

        <div>
          <p className="title">Connect your inbox</p>
          <p className="title_p">Try out your team's new email tools.</p>
          <hr />
        </div>

        <div>
          <p className="title">Set up your meetings link</p>
          <p className="title_p">
            Schedule appointments faster without the back-and-forth.
          </p>
          <hr />
        </div>

        <div>
          <p className="title">Install the sales extension</p>
          <p className="title_p">
            Send faster, better email with tracking, templates, and productivity
            tools right inside your inbox.
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default QuickLeadBody;
