export const getLast30DaysDate = () => {
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const formattedDate = thirtyDaysAgo.toISOString().split("T")[0];
  return formattedDate;
};

export const getLastNDaysDate = (daysAgo: any) => {
  if (daysAgo) {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - daysAgo);

    const formattedDate = pastDate.toISOString().split("T")[0];
    return formattedDate;
  } else {
    return "";
  }
};

export function shortenString(str: string) {
  if (str) {
    if (str.length <= 11) {
      return str;
    } else {
      return str.slice(0, 9) + "..";
    }
  }
}

export const getFormattedDate = (date: string) => {
  const dateString = date;
  const dateObject = new Date(dateString);
  return dateObject.toISOString().split("T")[0];
};

export const getDaysAgoFromFormattedDate = (formattedDate: string) => {
  const pastDate = new Date(formattedDate);
  const today = new Date();
  const daysAgo = Math.floor(
    (today.getTime() - pastDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  return ("" + daysAgo) as string;
};
