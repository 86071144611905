// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basic_parent .basic_p {
  color: #747474;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.basic_parent .basic_p_span {
  display: block;
  margin-top: 8px;
}
.basic_parent .line_progress {
  margin-top: 30px;
}
.basic_parent .line_progress .rs-progress-line {
  padding: 0px;
}
.basic_parent .drawerSubTitle .title {
  color: #1d3d70;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.8px;
  margin-top: 30px;
  cursor: pointer;
}
.basic_parent .drawerSubTitle .titleLineThrough {
  color: #1d3d70;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.8px;
  margin-top: 30px;
  text-decoration: line-through;
}
.basic_parent .drawerSubTitle .title_p {
  color: #747474;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 19px;
}
.basic_parent .drawerSubTitle .card_top {
  margin-top: 19px;
}`, "",{"version":3,"sources":["webpack://./src/onboardCard/drawer-body/drawer-body.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAEE;EACE,cAAA;EACA,eAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AACI;EACE,YAAA;AACN;AAGI;EACE,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AADN;AAII;EACE,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,6BAAA;AAFN;AAKI;EACE,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AAHN;AAKI;EACE,gBAAA;AAHN","sourcesContent":[".basic_parent {\n  .basic_p {\n    color: #747474;\n    font-family: Poppins;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n  .basic_p_span {\n    display: block;\n    margin-top: 8px;\n  }\n  .line_progress {\n    margin-top: 30px;\n    .rs-progress-line {\n      padding: 0px;\n    }\n  }\n  .drawerSubTitle {\n    .title {\n      color: #1d3d70;\n      font-family: Poppins;\n      font-size: 18px;\n      font-style: normal;\n      font-weight: 600;\n      line-height: 28.8px;\n      margin-top: 30px;\n      cursor: pointer;\n    }\n\n    .titleLineThrough {\n      color: #1d3d70;\n      font-family: Poppins;\n      font-size: 18px;\n      font-style: normal;\n      font-weight: 600;\n      line-height: 28.8px;\n      margin-top: 30px;\n      text-decoration: line-through;\n    }\n\n    .title_p {\n      color: #747474;\n      font-family: Poppins;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: normal;\n      margin-bottom: 19px;\n    }\n    .card_top {\n      margin-top: 19px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
