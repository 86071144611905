// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parentNoData .imgResize {
  margin-top: 26px;
}
.parentNoData .imgText {
  text-align: center;
  color: #767676;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}

.imgCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgTextForModal {
  text-align: center;
  color: #767676;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-top: 90px;
}`, "",{"version":3,"sources":["webpack://./src/common/components/no-data/no-data-found.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAEE;EACE,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAF","sourcesContent":[".parentNoData {\n  .imgResize {\n    margin-top: 26px;\n  }\n  .imgText {\n    text-align: center;\n    color: #767676;\n    text-align: center;\n    font-family: Poppins;\n    font-size: 17px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 17px;\n  }\n}\n.imgCenter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.imgTextForModal {\n  text-align: center;\n  color: #767676;\n  text-align: center;\n  font-family: Poppins;\n  font-size: 17px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 23px;\n  margin-top: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
