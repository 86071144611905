// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.__piec_snip_container {
  display: flex;
  flex-direction: column;
  border: 1px solid #bcdcd5;
  border-radius: 8px;
  padding-left: 52px;
  padding-right: 52px;
  height: 100%;
  min-height: 600px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}
.__piec_snip_container:hover {
  box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
}
.__piec_snip_container .__title_with_option {
  margin-top: 35px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.__piec_snip_container .__title_with_option .__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.__piec_snip_container .__chart {
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/GraphANdChartsSnippets/PieChart/pieChart.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,gCAAA;AACF;AACE;EACE,8CAAA;AACJ;AAEE;EACE,gBAAA;EACA,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,8BAAA;AAAJ;AAEI;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAN;AAIE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFJ","sourcesContent":[".__piec_snip_container {\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #bcdcd5;\n  border-radius: 8px;\n  padding-left: 52px;\n  padding-right: 52px;\n  height: 100%;\n  min-height: 600px;\n  background-color: white;\n  transition: all 0.3s ease-in-out;\n\n  &:hover {\n    box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);\n  }\n\n  .__title_with_option {\n    margin-top: 35px;\n    font-family: Poppins;\n    font-size: 20px;\n    font-weight: 500;\n    line-height: 32px;\n    letter-spacing: 0px;\n    text-align: left;\n    display: flex;\n    justify-content: space-between;\n\n    .__container {\n      width: 100%;\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n    }\n  }\n\n  .__chart {\n    height: 75%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
