import React from "react";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";

function EngageBody() {
  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="drawerSubTitle">
        <div>
          <p className="title">Create an email list</p>
          <p className="title_p">
            Target leads with the right campaign by filtering them into
            segmented lists.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p className="title">Create your first email HubSpot campaign</p>
          <p className="title_p">
            Target leads with the right campaign by filtering them into
            segmented lists.
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default EngageBody;
