import React, { useEffect } from "react";
import { Dropdown, Nav, Sidenav } from "rsuite";
import "./sidebarForOnboard.scss";
import { useHistory } from "react-router-dom";
import marketingIcon from "../../../assets/svgs/marketSvg.svg";
import salesIcon from "../../../assets/svgs/salesSvg.svg";
import customServiceIcon from "../../../assets/svgs/customServiceSvg.svg";
import websiteCmsIcon from "../../../assets/svgs/cmsSvg.svg";
import supportIcon from "../../../assets/svgs/supportIcon.svg";
import blogIcon from "../../../assets/svgs/blogIcon.svg";
import knowledgeIcon from "../../../assets/svgs/knowledgeIcon.svg";
import contactUsTicket from "../../../assets/svgs/contactUsTicket.svg";
import submitaTicket from "../../../assets/svgs/submitATicket.svg";

interface SideNavigationProps {
  selectedMenuItem: any;
  onSelectMenuItem: (eventKey: string) => void;
  handleOpen: any;
  open: any;
}

const SideNavigation: React.FC<SideNavigationProps> = ({
  onSelectMenuItem,
  selectedMenuItem,
  handleOpen,
  open,
}) => {
  useEffect(() => {
    const storedMenuItem = localStorage.getItem("selectedMenuItem");
    if (storedMenuItem) {
      onSelectMenuItem(storedMenuItem);
    }
  }, [onSelectMenuItem]);

  const history = useHistory();

  const handleSelectMenuItem = (eventKey: string) => {
    localStorage.setItem("selectedMenuItem", eventKey);
    onSelectMenuItem(eventKey);
    history.push("/getting-started");
  };

  return (
    <div>
      <Sidenav
        onSelect={handleSelectMenuItem}
        activeKey={selectedMenuItem}
        className="sidenav"
      >
        <Sidenav.Body>
          <Nav>
            <Nav.Item
              icon={
                <img
                  src={marketingIcon}
                  height="17px"
                  width="17px"
                  alt="dashboard icon"
                />
              }
              eventKey="Marketing"
              className={
                !open ? "default-navItem-width borderBottom" : "borderBottom"
              }
            >
              {/* {expanded === true ? "Dashboard" : ""} */}
              {!open && "Marketing"}
            </Nav.Item>
            <Nav.Item
              eventKey="Sales"
              icon={
                <img
                  src={salesIcon}
                  height="22px"
                  width="22px"
                  alt="ads campaign icon"
                />
              }
              className={
                !open ? "default-navItem-width borderBottom" : "borderBottom"
              }
            >
              {" "}
              {!open ? "Sales" : null}
              {/* {expanded === true ? "Ads Campaign List" : ""} */}
            </Nav.Item>
            <Nav.Item
              eventKey="Customer Service"
              icon={
                <img
                  src={customServiceIcon}
                  height="22px"
                  width="22px"
                  alt="create ads icon"
                />
              }
              className={
                !open ? "default-navItem-width borderBottom" : "borderBottom"
              }
            >
              {!open ? "Customer Service" : null}
            </Nav.Item>
            <Nav.Item
              eventKey="Website (CMS)"
              icon={
                <img
                  src={websiteCmsIcon}
                  height="20px"
                  width="20px"
                  alt="ad library icon"
                />
              }
              className={
                !open ? "default-navItem-width borderBottom" : "borderBottom"
              }
            >
              {!open ? "Setup" : null}
            </Nav.Item>

            <Dropdown
              style={{ marginTop: "70%" }}
              eventKey="Support"
              className={
                !open ? "default-navItem-width borderBottom" : "borderBottom"
              }
              icon={
                <img
                  src={supportIcon}
                  height="17px"
                  width="17px"
                  alt="Support icon"
                />
              }
              title={!open ? "Support" : ""}
            >
              <div className="dropdown-items">
                <Dropdown.Item
                  onSelect={() => handleSelectMenuItem("Submit a ticket")}
                  icon={
                    <img
                      src={submitaTicket}
                      height="17px"
                      width="17px"
                      alt="Submit a ticket icon"
                    />
                  }
                  eventKey="Submit a ticket"
                  active={selectedMenuItem === "Submit a ticket"}
                >
                  {!open ? "Submit a ticket" : null}
                </Dropdown.Item>

                <Dropdown.Item
                  onSelect={() => handleSelectMenuItem("Contact us")}
                  icon={
                    <img
                      src={contactUsTicket}
                      height="17px"
                      width="17px"
                      alt="contactUsTicket icon"
                    />
                  }
                  eventKey="Contact us"
                  active={selectedMenuItem === "Contact us"}
                >
                  {!open ? "Contact us" : null}
                </Dropdown.Item>

                <Dropdown.Item
                  onSelect={() => handleSelectMenuItem("Status page")}
                  icon={
                    <img
                      src={supportIcon}
                      height="17px"
                      width="17px"
                      alt="web icon"
                    />
                  }
                  eventKey="Status page"
                  active={selectedMenuItem === "Status page"}
                >
                  {!open ? "Status page" : null}
                </Dropdown.Item>
              </div>
            </Dropdown>

            <Nav.Item
              eventKey="Knowledgebase "
              icon={
                <img
                  src={knowledgeIcon}
                  height="17px"
                  width="17px"
                  alt="Knowledgebase  icon"
                />
              }
              className={
                !open ? "default-navItem-width borderBottom" : "borderBottom"
              }
            >
              {!open ? "Knowledgebase " : null}
            </Nav.Item>

            <Nav.Item
              eventKey="Blog"
              icon={
                <img
                  src={blogIcon}
                  height="17px"
                  width="17px"
                  alt="Blog icon"
                />
              }
              className={
                !open ? "default-navItem-width borderBottom" : "borderBottom"
              }
            >
              {!open ? "Blog" : null}
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  );
};

interface SidebarComponentProps {
  onSelectMenuItem: (eventKey: string) => void;
  selectedMenuItem: string;
  handleOpen: any;
  open: any;
}

const SidebarForOnboard: React.FC<SidebarComponentProps> = ({
  onSelectMenuItem,
  selectedMenuItem,
  handleOpen,
  open,
}) => {
  return (
    <>
      <div>
        <div
          className={
            !open
              ? "sideNav-drawer-button-main sideNav-body-header-top-collapseIn"
              : "sideNav-drawer-button-main sideNav-body-header-top-collapseOut"
          }
        >
          {/* {!open && <p>Ads Manager</p>} */}

          <div
            // style={{
            //   visibility: width <= 1200 ? "visible" : "hidden",
            // }}
            onClick={handleOpen}
            className="collapsible-drawer"
          >
            {open ? (
              <svg
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6 17L11 12L6 7M13 17L18 12L13 7"
                  stroke="#A5A5A5"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 17L13 12L18 7M11 17L6 12L11 7"
                  stroke="#A5A5A5"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>
        </div>

        <SideNavigation
          handleOpen={handleOpen}
          open={open}
          onSelectMenuItem={onSelectMenuItem}
          selectedMenuItem={selectedMenuItem}
        />
      </div>
    </>
  );
};

export default SidebarForOnboard;
