import { Button, Toggle } from "rsuite";
import "./dashboards.scss";
import {
  DefaultDashboard,
  deleteDashboard,
  RestoreDashboard,
} from "src/common/analytics-and-dashboard-api-calls";

export const stringToSubstringSpreed = (value: string, count: number) => {
  if (value.length > count) return value.substring(0, count - 1) + " . . .";
  return value;
};

let dataDefault: any = null;

const DeleteButtonCell = ({ onButtonClick }: any) => (
  <Button color="red" onClick={onButtonClick}>
    Delete
  </Button>
);

const DeleteButtonCellPermanent = ({ onButtonClick }: any) => (
  <Button color="red" onClick={onButtonClick}>
    Permanent Delete
  </Button>
);

const RestoreButtonCell = ({ onButtonClick }: any) => (
  <Button color="green" onClick={onButtonClick}>
    Restore
  </Button>
);

const handleDeleteRow = async (id: any) => {
  try {
    const response = await deleteDashboard(id, false);

    if (response.status === 204) {
    } else {
      console.error(`Failed to delete row with ID ${id}`);
    }
  } catch (error) {
    console.error(`Error deleting row with ID ${id}:`, error);
  }
};

const handleDeleteRowPermanent = async (id: any) => {
  console.log(`Row with ID ${id} deleted`);

  try {
    // Send a DELETE request to your API endpoint with the ID
    const response = await deleteDashboard(id, true);

    if (response.status === 204) {
      console.log(`Row with ID ${id} deleted`);
    } else {
      console.error(`Failed to delete row with ID ${id}`);
    }
  } catch (error) {
    console.error(`Error deleting row with ID ${id}:`, error);
  }
};

const handleRestore = async (id: any) => {
  console.log(`Row with ID ${id} restored`);

  try {
    const response = await RestoreDashboard(id);

    if (response.status === 204) {
      console.log(`Row with ID ${id} restored`);
    } else {
      console.error(`Failed to restore row with ID ${id}`);
    }
  } catch (error) {
    console.error(`Error deleting row with ID ${id}:`, error);
  }
};

export const formatDate = (dateString: any) => {
  const options: any = { year: "numeric", month: "long", day: "numeric" };
  const timeOptions: any = { hour: "2-digit", minute: "2-digit" };

  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", options);
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  return (
    <div className="statusText" style={{ display: "flex", gap: "10px", paddingLeft: "4px" }}>
      <div>{formattedDate}</div>
      <div>{formattedTime}</div>
    </div>
  );
};

const handleDefault = async (id: any) => {
  try {
    const response = await DefaultDashboard(id);
    dataDefault = id;

    if (response.status === 204) {
      console.log(`Row with ID ${id} default`);
    } else {
      console.error(`Failed to default row with ID ${id}`);
    }
  } catch (error) {
    console.error(`Error deleting row with ID ${id}:`, error);
  }
};

export const columnsForDashboardList = [
  {
    name: "Default",
    selector: (row: any) =>
      row.id === dataDefault ? (
        <div className="ml-2">
          <svg
            width="14"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
              fill="#EFDE83"
            />
          </svg>
        </div>
      ) : (
        <div className="ml-2">
          <svg
            onClick={() => handleDefault(row.id)}
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 1.13262L9.46324 5.63602L9.54182 5.87786H9.79611H14.5313L10.7004 8.66112L10.4947 8.81058L10.5733 9.05243L12.0365 13.5558L8.20572 10.7726L8 10.6231L7.79428 10.7726L3.96346 13.5558L5.4267 9.05243L5.50528 8.81058L5.29955 8.66112L1.46874 5.87786H6.20389H6.45818L6.53676 5.63602L8 1.13262Z"
              stroke="#C4C4C4"
              stroke-width="0.7"
            />
          </svg>
        </div>
      ),
  },
  {
    name: "ID",
    selector: "id",
  },
  {
    name: "Dashboard Name",
    selector: "dashboard_name",
  },
  {
    name: "Created By",
    selector: "created_by",
    conditionalCellStyles: [
      {
        when: (cell: boolean) => cell === true,
        style: {
          color: "green",
        },
      },
      {
        when: (cell: boolean) => cell === false,
        style: {
          color: "red",
        },
      },
    ],
    cell: (row: any) => (row.created_by == null ? "-" : row.created_by),
  },
  {
    name: "Create At",
    selector: "created_datetime",
    sortable: true,
    format: (row: any) => formatDate(row.created_datetime),
  },
  {
    name: "Is Public",
    selector: "is_public",
    sortable: true,
    conditionalCellStyles: [
      {
        when: (cell: boolean) => cell === true,
        style: {
          color: "green",
        },
      },
      {
        when: (cell: boolean) => cell === false,
        style: {
          color: "red",
        },
      },
    ],
    cell: (row: any) => (row.is_public ? "Yes" : "No"),
  },
  {
    name: "Is Active",
    selector: "is_active",
    sortable: true,
    conditionalCellStyles: [
      {
        when: (cell: boolean) => cell === true,
        style: {
          color: "green",
        },
      },
      {
        when: (cell: boolean) => cell === false,
        style: {
          color: "red",
        },
      },
    ],
    cell: (row: any) => <Toggle defaultChecked={row.is_active} />,
  },
  {
    name: "Delete",
    selector: "id",
    cell: (row: any) => (
      <DeleteButtonCell onButtonClick={() => handleDeleteRow(row.id)} />
    ),
  },
];

export const columnsForDeletedDashboardList = [
  {
    name: "ID",
    selector: "id",
    sortable: true,
    cell: (row: any) => <div className="ml-2">{row.id}</div>,
  },
  {
    name: "Dashboard Name",
    selector: "dashboard_name",
    sortable: true,
  },
  {
    name: "Created By",
    selector: "created_by",
    sortable: true,
    conditionalCellStyles: [
      {
        when: (cell: boolean) => cell === true,
        style: {
          color: "green",
        },
      },
      {
        when: (cell: boolean) => cell === false,
        style: {
          color: "red",
        },
      },
    ],
    cell: (row: any) => (row.created_by == null ? "-" : row.created_by),
  },
  {
    name: "Deleted By",
    selector: "deleted_by",
    sortable: true,
    conditionalCellStyles: [
      {
        when: (cell: boolean) => cell === true,
        style: {
          color: "green",
        },
      },
      {
        when: (cell: boolean) => cell === false,
        style: {
          color: "red",
        },
      },
    ],
    cell: (row: any) => (row.created_by == null ? "-" : row.created_by),
  },
  {
    name: "Permanent Delete",
    selector: "id",
    cell: (row: any) => (
      <DeleteButtonCellPermanent
        onButtonClick={() => handleDeleteRowPermanent(row.id)}
      />
    ),
  },
  {
    name: "Restore",
    selector: "id",
    cell: (row: any) => (
      <RestoreButtonCell onButtonClick={() => handleRestore(row.id)} />
    ),
  },
];
