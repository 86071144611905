import { Button } from "rsuite";

interface IHasError {
  message?: string;
}

const HasError = ({ message }: IHasError) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        height: "50vh",
      }}
    >
      <div>
        {" "}
        {message
          ? message
          : "Currently our service is down, please wait for some times."}
      </div>

      <div>
        <Button appearance="ghost" onClick={() => (window.location.href = "/")}>
          Reload
        </Button>
      </div>
    </div>
  );
};

export default HasError;
