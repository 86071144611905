// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.__report_container {
  background-color: white;
  height: 100%;
  padding: 0px 30px 0px;
  display: flex;
  gap: 20px;
}

.main_content .rs-container {
  flex-direction: column !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/report.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,YAAA;EACA,qBAAA;EACA,aAAA;EACA,SAAA;AACF;;AAGE;EACE,iCAAA;AAAJ","sourcesContent":[".__report_container {\n  background-color: white;\n  height: 100%;\n  padding: 0px 30px 0px;\n  display: flex;\n  gap: 20px;\n}\n\n.main_content {\n  .rs-container {\n    flex-direction: column !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
