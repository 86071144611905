import React from "react";
import "./cards.scss";

interface IMicroCards {
  title?: string;
  value?: number | string;
}

const Cards: React.FC<IMicroCards> = ({ title, value }: IMicroCards) => {
  return (
    <div className="__micro_card_container">
      <div className="_element">
        <div className="title">{title}</div>
        <div className="value">{value?.toLocaleString()}</div>
      </div>
    </div>
  );
};

export default Cards;
