import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { PersistGate } from "redux-persist/integration/react";

// All are common css for all project
import "bootstrap/dist/css/bootstrap.css";
import "rsuite/dist/styles/rsuite-default.css";
import "./common/styles.scss";

import { store, persistor } from "./common/redux/store";

import App from "./App";
import { Toaster } from "react-hot-toast";

JavascriptTimeAgo.addLocale(en);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        {/* <LastLocationProvider> */}
        <App />
        <Toaster key="analytics-and-reports" />
        {/* </LastLocationProvider> */}
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
