export const filterdaterange = [
  {
    label: "All data",
    value: "All data",
  },

  {
    label: "Today",
    value: "Today",
  },
  {
    label: "Yesterday",
    value: "Yesterday",
  },
  {
    label: "Tomorrow",
    value: "Tomorrow",
  },
  {
    label: "This week",
    value: "This week",
  },
  {
    label: "Last week",
    value: "Last week",
  },
  {
    label: "Next week",
    value: "Next week",
  },
  {
    label: "This month",
    value: "This month",
  },
  {
    label: "Last month",
    value: "Last month",
  },
  {
    label: "Next month",
    value: "Next month",
  },
  {
    label: "This quarter",
    value: "This quarter",
  },
  {
    label: "This fiscal quarter",
    value: "This fiscal quarter",
  },
  {
    label: "Last quarter",
    value: "Last quarter",
  },
  {
    label: "Last fiscal quarter",
    value: "Last fiscal quarter",
  },
  {
    label: "Next quarter",
    value: "Next quarter",
  },
  {
    label: "Next fiscal quarter",
    value: "Next fiscal quarter",
  },
  {
    label: "This year",
    value: "This year",
  },
  {
    label: "This fiscal year",
    value: "This fiscal year",
  },
  {
    label: "Last fiscal year",
    value: "Last fiscal year",
  },
  {
    label: "Next year",
    value: "Next year",
  },
  {
    label: "Next fiscal year",
    value: "Next fiscal year",
  },
  {
    label: "Rolling date range",
    value: "Rolling date range",
  },
  {
    label: "Custom date range",
    value: "Custom date range",
  },
  {
    label: "Equal to",
    value: "Equal to",
  },
  {
    label: "Before",
    value: "Before",
  },
  {
    label: "After",
    value: "After",
  },
];

export const filterDate = [
  {
    label: "Last 7 days (excluding today)",
    value: "7",
  },

  {
    label: "Last 14 days (excluding today)",
    value: "14",
  },
  {
    label: "Last 30 days (excluding today)",
    value: "30",
  },
  {
    label: "Last 60 days (excluding today)",
    value: "60",
  },
  {
    label: "Last 90 days (excluding today)",
    value: "90",
  },
  {
    label: "Last 365 days (excluding today)",
    value: "365",
  },
];
