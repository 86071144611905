import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Nav } from "rsuite";
import "./reportHeader.scss";

interface ReportListsHeaderProps {
  setActive: (active: string | null) => void;
  active: string | null;
}

interface NavbarProps {
  active: string | null;
  onSelect: (eventKey: string | null) => void;
  appearance: "default" | "subtle" | "tabs" | undefined;
}

const ReportHeaderComponent: React.FC<ReportListsHeaderProps> = ({
  setActive,
  active,
}) => {
  const history = useHistory();

  const Navbar: React.FC<NavbarProps> = ({ active, onSelect }) => {
    return (
      <div className="" style={{ paddingLeft: "40px", marginTop: "32px" }}>
        <Nav
          appearance="subtle"
          className="list-header-left-nav"
          activeKey={active}
          onSelect={onSelect}
        >
          <Nav.Item eventKey="my-reports">Your Reports</Nav.Item>
        </Nav>
      </div>
    );
  };

  return (
    <>
      <div className="inbox-top-section shadow-sm">
        <div className="top-elements-wrapper">
          <div className="inner __dashboard_lists__title">Reports</div>
          <Button
            className="green-button"
            onClick={() => history.push(`/report-creation`)}
          >
            Create Reports
          </Button>
        </div>

        <div
          className="rs-divider rs-divider-horizontal"
          style={{
            height: "10px",
            backgroundColor: " rgb(240, 242, 245)",
            margin: "0px",
          }}
        ></div>
      </div>
      <Navbar appearance="subtle" active={active} onSelect={setActive} />
    </>
  );
};

export default ReportHeaderComponent;
