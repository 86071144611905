// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-elements-wrapper {
  justify-content: space-between;
}
.top-elements-wrapper .__dashboardHeader .__title .rs-badge-independent,
.top-elements-wrapper .__dashboardHeader .__title .rs-badge-content {
  background-color: #d1e9e4;
  padding: 5px;
  color: #3c8f7c;
}
.top-elements-wrapper .__dashboardHeader .__title .rs-dropdown-toggle,
.top-elements-wrapper .__dashboardHeader .__title .rs-dropdown-toggle.rs-btn {
  width: 100%;
  background: transparent;
  color: white;
}
.top-elements-wrapper .dashboardName {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  background-color: #1d3d70;
  width: 138.88px;
  height: 28px;
  border-radius: 0px !important;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
}
.top-elements-wrapper .actions {
  display: flex;
  gap: 20px;
}
.top-elements-wrapper .actions .__add_rep .rs-btn-default {
  background: #00ab86;
  color: white;
  font-weight: 600;
  border: none;
}

.___selected_dashboard_name .rs-dropdown-menu {
  max-height: 400px;
  overflow: auto;
}
.___selected_dashboard_name .rs-btn-ghost {
  border: none;
  color: #1d3d70;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/headers/dashboardHeader.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF;AAEM;;EAEE,yBAAA;EACA,YAAA;EACA,cAAA;AAAR;AAEM;;EAEE,WAAA;EACA,uBAAA;EACA,YAAA;AAAR;AAME;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,YAAA;EACA,6BAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AAJJ;AAOE;EACE,aAAA;EACA,SAAA;AALJ;AAQM;EACE,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;AANR;;AAaE;EACE,iBAAA;EACA,cAAA;AAVJ;AAYE;EACE,YAAA;EACA,cAAA;EACA,eAAA;AAVJ","sourcesContent":[".top-elements-wrapper {\n  justify-content: space-between;\n  .__dashboardHeader {\n    .__title {\n      .rs-badge-independent,\n      .rs-badge-content {\n        background-color: #d1e9e4;\n        padding: 5px;\n        color: #3c8f7c;\n      }\n      .rs-dropdown-toggle,\n      .rs-dropdown-toggle.rs-btn {\n        width: 100%;\n        background: transparent;\n        color: white;\n        // font-size: 20px;\n      }\n    }\n  }\n\n  .dashboardName {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    color: white;\n    background-color: #1d3d70;\n    width: 138.88px;\n    height: 28px;\n    border-radius: 0px !important;\n    justify-content: center;\n    font-size: 12px;\n    font-weight: 500;\n  }\n\n  .actions {\n    display: flex;\n    gap: 20px;\n\n    .__add_rep {\n      .rs-btn-default {\n        background: #00ab86;\n        color: white;\n        font-weight: 600;\n        border: none;\n      }\n    }\n  }\n}\n\n.___selected_dashboard_name {\n  .rs-dropdown-menu {\n    max-height: 400px;\n    overflow: auto;\n  }\n  .rs-btn-ghost {\n    border: none;\n    color: #1d3d70;\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
