import React from "react";
import "./drawer-body.scss";

function DeliverCustomerBody() {
  return (
    <div className="basic_parent">
      Support your customers with a shared inbox, tickets and live chat
      <div className="drawerSubTitle">
        <div>
          <p className="title">Connect your team email to Roboket</p>
          <p className="title_p">
            Collaborate faster and more efficiently with one place to view,
            manage, and answer shared emails.
          </p>
          <hr />
        </div>

        <div>
          <p className="title">Add a support form to your website</p>
          <p className="title_p">
            Every query creates a ticket. No manual entry.
          </p>
          <hr />
        </div>

        <div>
          <p className="title">Add live chat to your website</p>
          <p className="title_p">
            Give visitors the human help they need, right when they need it.
          </p>
          <hr />
        </div>

        <div>
          <p className="title">Create a chatflow</p>
          <p className="title_p">Use a bot to help web visitors 24/7.</p>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default DeliverCustomerBody;
