import React from "react";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";

function TrackDealBody() {
  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="drawerSubTitle">
        <div>
          <p className="title">Set up your deals pipeline</p>
          <p className="title_p">
            In three easy steps, you’ll hit the ground running with a custom
            deals pipeline.
          </p>
          <hr />
        </div>

        <div>
          <p className="title">Create a deal</p>
          <p className="title_p">
            Use deals in HubSpot to track potential revenue through your sales
            process.
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default TrackDealBody;
