import React from "react";
import getStatedSvg from "../assets/svgs/getStarted.svg";
import { DrawerComponentState } from "src/common/components/adn-drawer/adn-drawer.component";
import { connect } from "react-redux";
import newTabIcon from "../assets/svgs/newTabIcon.svg";
import {
  setDrawerName,
  toggleDrawer,
} from "src/common/redux/drawer/drawer.actions";
import SetUpBasicBodyForMarketing from "./drawer-body/SetUpBasicBodyForMarketing";
import SetUpBasicBodyForSales from "./drawer-body/SetUpBasicBodyForSales";
import SetUpBasicBodyForCustomerService from "./drawer-body/SetUpBasicBodyForCustomerService";
import SetUpBasicBodyForWebsite from "./drawer-body/SetUpBasicBodyForWebsite";

enum SETUPBASICEnum {
  DrawerName = "Set up the basics",
}

const SecondCard = (props: any) => {
  const { drawerName, toggleDrawer, setDrawerName, selectedMenuItem } = props;

  const openDrawer = () => {
    setDrawerName(SETUPBASICEnum.DrawerName);
    toggleDrawer(true);
  };

  return (
    <div className="cardSecMarket">
      <div className="innerCard">
        <div className="gapBetIconTxt">
          <img src={getStatedSvg} alt="getStatedSvg" />
          <p> Recommended: All you need to get started</p>
        </div>{" "}
        <p className="subHead" onClick={openDrawer}>
          {" "}
          Set up the basics{" "}
        </p>
        <p className="subText">
          Import your contacts, invite teammates, and understand roboket
          properties
        </p>
      </div>

      {drawerName === SETUPBASICEnum.DrawerName ? (
        <DrawerComponentState
          backdrop={true}
          isDrawerShow={true}
          toggleDrawer={toggleDrawer}
          setDrawerName={setDrawerName}
          title="Set up the basics"
          size={"xs"}
          footer={
            <div className="bottom_div">
              <div className="border_bottom">
                <p className="border_bottom_p">
                  {" "}
                  <div className="footerFlex">
                    {" "}
                    Up next: Attract and capture leads{" "}
                    <img src={newTabIcon} alt="newTabIcon" />{" "}
                  </div>
                </p>
              </div>
            </div>
          }
        >
          {selectedMenuItem === "Marketing" && <SetUpBasicBodyForMarketing />}
          {selectedMenuItem === "Sales" && <SetUpBasicBodyForSales />}
          {selectedMenuItem === "Customer Service" && <SetUpBasicBodyForCustomerService />}
          {selectedMenuItem === "Website (CMS)" && <SetUpBasicBodyForWebsite />}
        </DrawerComponentState>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ drawer: { drawerName } }: any) => ({
  drawerName,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleDrawer: (toggle: boolean) => dispatch(toggleDrawer(toggle)),
  setDrawerName: (name: string) => dispatch(setDrawerName(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecondCard);
