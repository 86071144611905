import React from "react";
import { Footer as AdnFooter } from "rsuite";

export const FooterComponent: React.FC = () => {
  return (
    <AdnFooter className="footer">
      Copyright &copy; {new Date().getFullYear()}. All Rights Reserved
    </AdnFooter>
  );
};
