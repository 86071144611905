// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.___dashboard_lists_header .__dashboard_lists__title {
  color: #1d3d70;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: capitalize;
}

.top-elements .rs-nav-item {
  border: 2px solid var(--white);
  border-radius: 8px 8px 0px 0px;
  margin-right: 5px;
  height: 3em;
  border-bottom: 0px;
}

.top-elements .rs-nav-tabs .rs-nav-item.rs-nav-item-active {
  background-color: #fff;
  font-weight: 600;
  color: #62626c;
}

.top-elements .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item > .rs-nav-item-content {
  padding: 10px 38px;
  border: none;
  color: #62626c;
}

.top-elements .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item > .rs-nav-item-content :hover {
  background-color: #fff !important;
}

.top-elements .rs-nav-waterline {
  border: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/headers/dashboardListsHeader.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,0BAAA;AAAJ;;AAIA;EACE,8BAAA;EACA,8BAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;AADF;;AAIA;EACE,sBAAA;EACA,gBAAA;EACA,cAAA;AADF;;AAIA;EAIE,kBAAA;EACA,YAAA;EACA,cAAA;AAJF;;AAOA;EAKE,iCAAA;AARF;;AAWA;EACE,uBAAA;AARF","sourcesContent":[".___dashboard_lists_header {\n  .__dashboard_lists__title {\n    color: #1d3d70;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 140%;\n    text-transform: capitalize;\n  }\n}\n\n.top-elements .rs-nav-item {\n  border: 2px solid var(--white);\n  border-radius: 8px 8px 0px 0px;\n  margin-right: 5px;\n  height: 3em;\n  border-bottom: 0px;\n}\n\n.top-elements .rs-nav-tabs .rs-nav-item.rs-nav-item-active {\n  background-color: #fff;\n  font-weight: 600;\n  color: #62626c;\n}\n\n.top-elements\n  .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal\n  .rs-nav-item.rs-nav-item\n  > .rs-nav-item-content {\n  padding: 10px 38px;\n  border: none;\n  color: #62626c;\n}\n\n.top-elements\n  .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal\n  .rs-nav-item.rs-nav-item\n  > .rs-nav-item-content\n  :hover {\n  background-color: #fff !important;\n}\n\n.top-elements .rs-nav-waterline {\n  border: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
