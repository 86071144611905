// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.__ReportTable_container {
  width: 100%;
}

.rdt_TableHeadRow {
  border-radius: 0px !important;
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 0px 12px 0px 12px;
}

.report-list-table-header {
  font-weight: 500;
  font-size: 14px;
  color: #1d3d70;
  text-align: left;
}
.report-list-table-header:hover {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(6, 20, 6);
}

.title_for_rt {
  font-weight: 500;
}

.reportTable-default-area-style {
  border: 1px solid #ebf2fd;
  padding-bottom: 40px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.reportTable-default-area-style .rdt_TableBody {
  display: none;
}
.reportTable-default-area-style .rdt_Pagination {
  display: none;
}
.reportTable-default-area-style .noData-msg {
  font-size: 14px;
  font-family: "Poppins";
  font-family: 500;
  font-weight: 500;
  /* text-transform: capitalize; */
  margin-top: 24px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/ReportTable/reportTable.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,cAAA;EACA,iBAAA;EACA,0BAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;AACF;AAAE;EACE,0BAAA;EACA,eAAA;EACA,oBAAA;AAEJ;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,yBAAA;EACA,oBAAA;EACA,yFAAA;AACF;AACE;EACE,aAAA;AACJ;AACE;EACE,aAAA;AACJ;AACE;EACE,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,gCAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourcesContent":[".__ReportTable_container {\n  width: 100%;\n}\n\n.rdt_TableHeadRow {\n  border-radius: 0px !important;\n}\n\n.tableHeader {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 1%;\n  margin-bottom: 1%;\n  padding: 0px 12px 0px 12px;\n}\n\n.report-list-table-header {\n  font-weight: 500;\n  font-size: 14px;\n  color: #1d3d70;\n  text-align: left;\n  &:hover {\n    text-decoration: underline;\n    cursor: pointer;\n    color: rgb(6, 20, 6);\n  }\n}\n\n.title_for_rt {\n  font-weight: 500;\n}\n\n.reportTable-default-area-style {\n  border: 1px solid #ebf2fd;\n  padding-bottom: 40px;\n  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,\n    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;\n  .rdt_TableBody {\n    display: none;\n  }\n  .rdt_Pagination {\n    display: none;\n  }\n  .noData-msg {\n    font-size: 14px;\n    font-family: \"Poppins\";\n    font-family: 500;\n    font-weight: 500;\n    /* text-transform: capitalize; */\n    margin-top: 24px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
