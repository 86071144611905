import React from "react";
import { Divider } from "rsuite";
import ContactUsCardComponent from "./contactusCard/Contactus.card.component";
import contactUsImage from "../../../assets/svgs/contactUsImage.svg";

function ContactUsSupport(props: any) {
  const { fullName, selectedMenuItem } = props;
  return (
    <div className="parentContainer">
      <>
        <p className="hiClass">Hi, {fullName} </p>
        {selectedMenuItem === "Contact us" && (
          <p className="welcomeTxt">Contact us</p>
        )}

        <p className="titleDesc">
          Want to get in touch? We'd love to hear from you. Here's how you can
          reach us.
        </p>

        <div className="ticketParentFlex">
          <div className="ticket1">
            <ContactUsCardComponent />
          </div>
          <div>
            <Divider
              vertical
              className="dividerIn"
              style={{
                height: "650.003px",
              }}
            />
          </div>
          <div className="contactUsParaG">
            <p>
              If you have a general question you'd like to ask me, you can just
              keep asking me questions here.
            </p>
            <p>
              If you'd like to provide feedback or suggestions, you can fill out
              a feedback form that's usually available through the platform
              you're using to interact with me.
            </p>

            <div style={{ marginTop: "64px" }} className="text-center">
              <img src={contactUsImage} alt="contactUsImage" />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default ContactUsSupport;
