import React from "react";
import "./no-data-found.scss";
import noDataFoundIcon from "../../../assets/svgs/noDataFoundIcon.svg";

const NoDataFound = (props: any) => {
  const modal = props;

  return (
    <div className="parentNoData">
      <p className={modal.modal === false ? "imgText" : "imgTextForModal"}>
        There is no data to show in this time frame <br />
        Try changing the date range.
      </p>
      {modal.modal === false ? (
        <div className="imgResize text-center">
          <img
            src={noDataFoundIcon}
            alt="no data found"
            height="30%"
            width="30%"
          />
        </div>
      ) : (
        <div className="imgCenter">
          <img
            src={noDataFoundIcon}
            alt="no data found"
            height="15%"
            width="15%"
          />
        </div>
      )}
    </div>
  );
};

export default NoDataFound;
