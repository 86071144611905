import React from "react";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";

function CloseDealBody() {
  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="drawerSubTitle">
        <div>
          <p className="title">Create a sales reporting dashboard</p>
          <p className="title_p">
            Organize your sales related reports into one view using a dashboard.
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default CloseDealBody;
