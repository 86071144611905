import contactIcon from "../../../assets/svgs/contactIcon.svg";
import settingIcon from "../../../assets/svgs/settingIcon.svg";
import companyIcon from "../../../assets/svgs/companyIcon.svg";
import groupIcon from "../../../assets/svgs/groupIcon.svg";
import inboxIcon from "../../../assets/svgs/groupIcon.svg";
import snippetIcon from "../../../assets/svgs/snippetIcon.svg";
import dealsIcon from "../../../assets/svgs/dealsIcon.svg";
import meetingsIcon from "../../../assets/svgs/meetingsIcon.svg";
import campaignIcon from "../../../assets/svgs/campaignIcon.svg";
import webAdsIcon from "../../../assets/svgs/webAdsIcon.svg";
import formIcon from "../../../assets/svgs/formIcon.svg";
import teamsIcon from "../../../assets/svgs/teamsIcon.svg";
import websiteChatIcon from "../../../assets/svgs/websiteChatIcon.svg";
import notificationIcon from "../../../assets/svgs/notificationIcon.svg";
import ticketsIcon from "../../../assets/svgs/ticketsIcon.svg";
import propertiseIcon from "../../../assets/svgs/propertiseIcon.svg";

export const DashboardData = [
  {
    image: contactIcon,
    subText: "Contact",
    linkForDashboard: "https://apps.roboket.com/crm/contact",
  },
  {
    image: companyIcon,
    subText: "Company",
    linkForDashboard: "https://apps.roboket.com/crm/company",
  },
  {
    image: groupIcon,
    subText: "Groups",
    linkForDashboard: "https://apps.roboket.com/crm/contacts/segments",
  },
  {
    image: inboxIcon,
    subText: "Inbox",
    linkForDashboard: "https://apps.roboket.com/crm/inbox",
  },
  {
    image: snippetIcon,
    subText: "Snippets",
    linkForDashboard: "https://apps.roboket.com/crm/snippets",
  },
  {
    image: dealsIcon,
    subText: "Deals",
    linkForDashboard: "https://apps.roboket.com/crm/conversion",
  },
  {
    image: meetingsIcon,
    subText: "Meeting",
    linkForDashboard: "https://apps.roboket.com/crm/meetings",
  },
  {
    image: campaignIcon,
    subText: "Campaigns",
    linkForDashboard: "https://apps.roboket.com/campaign",
  },
  {
    image: webAdsIcon,
    subText: "Webads",
    linkForDashboard: "https://ads.roboket.com/",
  },
];

export const DashboardDataQuickSetting = [
  {
    image: settingIcon,
    subText: "Settings",
    linkForDashboard: "https://apps.roboket.com/crm/settings",
  },
  {
    image: formIcon,
    subText: "Form",
    linkForDashboard: "https://apps.roboket.com/crm/inbox",
  },
  {
    image: teamsIcon,
    subText: "Teams",
    linkForDashboard: "https://apps.roboket.com/crm/inbox",
  },
  {
    image: websiteChatIcon,
    subText: "Website Chat",
    linkForDashboard: "https://apps.roboket.com/crm/inbox",
  },
  {
    image: notificationIcon,
    subText: "Notification",
    linkForDashboard: "https://apps.roboket.com/crm/inbox",
  },
  {
    image: ticketsIcon,
    subText: "Tickets",
    linkForDashboard: "https://apps.roboket.com/crm/tickets",
  },
  {
    image: propertiseIcon,
    subText: "Properties",
    linkForDashboard: "https://apps.roboket.com/crm/inbox",
  },
];
