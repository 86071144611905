import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { getUserPermissions } from "src/common/utils/Utils";

const PrivateRouteReport = ({ children, ...rest }: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  const FetchPermissions = async () => {
    const permissions: string[] = await getUserPermissions();

    if (permissions.length === 0) {
      setHasAccess(false);
      setIsLoading(false);
    } else if (permissions.includes("report_access")) {
      setHasAccess(true);
      setIsLoading(false);
    } else {
      setHasAccess(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    FetchPermissions();
  }, []);

  return (
    <>
      {!isLoading && (
        <Route
          {...rest}
          render={() => {
            return hasAccess ? (
              children
            ) : (
              <Redirect to="/permission-not-found" />
            );
          }}
        />
      )}
    </>
  );
};

export default PrivateRouteReport;
