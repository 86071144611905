import axios from "./axios-config";
import { ColorGroundList } from "./common.types";
import { getLast30DaysDate } from "./helpers/CustomDateHelper";
import { AnalyticsAndReportingDashBoardsApiRoutes } from "./routes";
import { IWidgets } from "./types/analytics.types";

const userId = localStorage.getItem("active_account_id");
const userUId = localStorage.getItem("userId");

// const userId = 51;

export const getAllWidgets = async () => {
  try {
    const result = await axios.get(
      AnalyticsAndReportingDashBoardsApiRoutes.GET_ALL_WIDGETS_FOR_REPORT
    );

    return {
      data: result.data.response,
      error: false,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
    };
  }
};

export const getAllWidgetsForReport = async () => {
  try {
    const result = await axios.get(
      AnalyticsAndReportingDashBoardsApiRoutes.GET_ALL_WIDGETS_FOR_REPORT
    );

    return {
      data: result.data.response,
      error: false,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
    };
  }
};

export const creteNewDashboard = async (
  dashboardName: string,
  reportTypesWillBeIncluded: IWidgets[],
  currentlySelectedAccessOption: string,
  permissionOpt: number,
  teamId: number | null
) => {
  const requestBody = {
    dashboard_name: dashboardName,
    is_default: false,
    is_public: currentlySelectedAccessOption === "PTM" ? false : true,
    widget_ids: reportTypesWillBeIncluded
      .filter(
        (data) => Object.hasOwn(data, "selected") && data.selected === true
      )
      .map((data) => data.id),
    view_edit: permissionOpt,
    team_id: teamId,
    widget_rank: reportTypesWillBeIncluded
      .filter(
        (data) => Object.hasOwn(data, "selected") && data.selected === true
      )
      .map((data) => data.id),
  };

  try {
    const result = await axios.post(
      AnalyticsAndReportingDashBoardsApiRoutes.CREATE_NEW_DASHBOARD,
      requestBody
    );
    return {
      data: result.data.response,
      error: false,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
    };
  }
};

export const getDashboardLists = async () => {
  try {
    const result = await axios.get(
      AnalyticsAndReportingDashBoardsApiRoutes.GET_ALL_DASHBOARDS
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
  // return {
  //   data: [],
  //   error: true,
  //   status: 400,
  // };
};

export const getDeletedDashboardLists = async () => {
  try {
    const result = await axios.get(
      AnalyticsAndReportingDashBoardsApiRoutes.GET_ALL_DELETED_DASHBOARDS
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getDashboardDetails = async (id: number) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_DASHBOARD_DETAILS}/${id}`
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const updateRankPositionOfDashboardWidget = async (
  id: number,
  ranks?: number[],
  layout?: any
) => {
  try {
    const result = await axios.put(
      `${AnalyticsAndReportingDashBoardsApiRoutes.UPDATE_DASHBOARD_RANK}/${id}`,
      {
        widget_rank: ranks?.map((rank) => rank),
        layout: layout,
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getContactVsRecentData = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_CONTACT_VS_RECENT_DATA}/${userId}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getContactCreationData = async () => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_CONTACT_CREATION_DATA}/${userId}`
    );
    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
export const getTaskCreationData = async () => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_TASK_CREATION_DATA}/${userId}`
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getContactCreationByDay = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.CONTACT_VS_TIME}/${userId}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getCreatedLast24 = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.CREATED_LAST_24H}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
export const getFiscalYear = async () => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_FISCAL_YEAR}`
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTicketClosedLast24 = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.CREATED_LAST_24H}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
export const getTicketCreated = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TICKET_CREATED}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTotalCompanyCreated = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TOTAL_COMPANY_CREATED}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getLastWeekCompanyCreated = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TOTAL_COMPANY_CREATED}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const geTopMonthCompanyCreation = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TOP_MONTH_COMPANY_CREATION}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const geAvgMonthCompanyCreation = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.AVERAGE_COMPANY_CREATION_PER_MONTH}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const geTotalTaskCreation = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TOTAL_TASK_COUNT}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getLastWeekTotalTaskCount = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.LAST_WEEK_TOTAL_TASK_COUNT}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTopMonthTask = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TOP_MONTH_TASK_ANALYTICS}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getAvgMonthTask = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.AVG_MONTH_TASK_ANALYTICS_CARDS}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getMaxDealAmount = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_MAX_DEAL_AMOUNT_MONTH}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getLowestAmount = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_LOWEST_DEAL_AMOUNT_MONTH}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTotalTicket = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.get_total_ticket}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTotalOpenTicket = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_TOTAL_OPEN_TICKET}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTotalClosedTicket = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_TOTAL_CLOSE_TICKET}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTotalProgressedTicket = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_TOTAL_TICKET_IN_PROGRESS}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTicketResolveTime = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.AVERAGE_TICKET_RESOLVE_TIME}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getCloseBefore = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TICKET_CLOSE_BEFORE}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getCloseAfter = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TICKET_CLOSE_AFTER}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTotalBySourceData = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TOTAL_BY_SOURCE}/${userId}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTotalByPerson = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TOTAL_BY_PERSON}/${userId}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getCompanyVsRecentData = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.COMPANY_VS_RECENT}/${userId}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getCompanyCreationData = async () => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.total_company_created_vs_last_week}/${userId}`
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getCompanyCreationByDate = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.COMPANY_VS_TIME}/${userId}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTotalCompanyByPerson = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.COMPANY_CREATION_TOTAL_BY_PERSON}/${userId}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getMonthWiseDeal = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.MONTH_WISE_DEALS_AMOUNT}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getPersonWiseProgress = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TICKET_PERSON_WISE_IN_PROGRESS}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getPersonWiseOpen = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TICKET_PERSON_WISE_OPEN}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getPersonWiseClose = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TICKET_PERSON_WISE_CLOSE}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTicketVsMonthData = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TICKET_VS_MONTH}/${userId}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
export const getTaskVsMonthData = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TASK_VS_MONTH}/${userId}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getStageWiseDealsAmount = async (pipelineId: number) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.STAGE_WISE_DEALS_AMOUNT}?pipeline_id=${pipelineId}`
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
export const getStageWiseTicketCount = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.STAGE_WISE_TICKET_COUNT}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getDealsInAStageCount = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.DEALS_IN_A_STAGE_COUNT}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTicketStatusData = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TICKET_STATUS}/${userId}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
export const getTaskStatusData = async (from?: any, to?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TASK_STATUS}/${userId}`,
      {
        params: {
          from_date: from ? from : getLast30DaysDate(),
          to_date: to ? to : new Date().toISOString().split("T")[0],
        },
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
// ====================== KPI START
export const getKpiTotalCompanyTarget = async (filters?: any) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.KPI_TOTAL_COMPANY_TARGET}`,
      {
        params: filters,
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
// export const getKpiAchievedTillDate = async (teamId: number) => {
//   try {
//     const result = await axios.get(
//       `${AnalyticsAndReportingDashBoardsApiRoutes.KPI_ACHIEVED_TILL_DATE}`,
//       {
//         params: {
//           team_id: teamId,
//           user_id: 21,
//         },
//       }
//     );

//     return {
//       data: result.data,
//       error: false,
//       status: result.status,
//     };
//   } catch (error) {
//     return {
//       data: [],
//       error: true,
//       status: 400,
//     };
//   }
// };
// export const getKpiBacklogTillDate = async (teamId: number) => {
//   try {
//     const result = await axios.get(
//       `${AnalyticsAndReportingDashBoardsApiRoutes.KPI_BACKLOG_TILL_DATE}`,
//       {
//         params: {
//           team_id: teamId,
//           user_id: 21,
//         },
//       }
//     );

//     return {
//       data: result.data,
//       error: false,
//       status: result.status,
//     };
//   } catch (error) {
//     return {
//       data: [],
//       error: true,
//       status: 400,
//     };
//   }
// };
// export const getKpiAchievedThisMonth = async (teamId: number) => {
//   try {
//     const result = await axios.get(
//       `${AnalyticsAndReportingDashBoardsApiRoutes.KPI_ACHIEVED_THIS_MONTH}`,
//       {
//         params: {
//           team_id: teamId,
//           user_id: 21,
//         },
//       }
//     );

//     return {
//       data: result.data,
//       error: false,
//       status: result.status,
//     };
//   } catch (error) {
//     return {
//       data: [],
//       error: true,
//       status: 400,
//     };
//   }
// };
export const getKpiAchievedTillDate = async (filter: any) => {
  console.log("filter for KPI 01", filter, typeof filter);

  try {
    let result;

    if (typeof filter === "object" && filter !== null) {
      // Send filter as query parameters if it's an object
      result = await axios.get(
        `${AnalyticsAndReportingDashBoardsApiRoutes.KPI_ACHIEVED_TILL_DATE}`,
        {
          params: filter, // Use filter directly as params
        }
      );
    } else {
      // Send request without params if filter is not an object
      result = await axios.get(
        `${AnalyticsAndReportingDashBoardsApiRoutes.KPI_ACHIEVED_TILL_DATE}`
      );
    }
    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
export const getKpiBacklogTillDate = async (filter: any) => {
  console.log("fitler for kpi 02", filter, typeof filter);
  try {
    // const result = await axios.get(
    //   `${AnalyticsAndReportingDashBoardsApiRoutes.KPI_BACKLOG_TILL_DATE}`,
    //   {
    //     params: {
    //       team_id: teamId,
    //       user_id: 21,
    //     },
    //   }
    // );
    let result;

    if (typeof filter === "object" && filter !== null) {
      result = await axios.get(
        `${AnalyticsAndReportingDashBoardsApiRoutes.KPI_BACKLOG_TILL_DATE}`,
        {
          params: filter,
        }
      );
    } else {
      result = await axios.get(
        `${AnalyticsAndReportingDashBoardsApiRoutes.KPI_BACKLOG_TILL_DATE}`
      );
    }

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
export const getKpiAchievedThisMonth = async (filter: any) => {
  console.log("fitler for kpi 03", filter, typeof filter);
  try {
    let result;

    if (typeof filter === "object" && filter !== null) {
      result = await axios.get(
        `${AnalyticsAndReportingDashBoardsApiRoutes.KPI_ACHIEVED_THIS_MONTH}`,
        {
          params: filter,
        }
      );
    } else {
      result = await axios.get(
        `${AnalyticsAndReportingDashBoardsApiRoutes.KPI_ACHIEVED_THIS_MONTH}`
      );
    }

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
// ======================== KPI END

export const getTotalTicketCreatedByLastDate = async () => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.TOTAL_CONTACT_CREATED_VS_LAST_WEEK}/${userId}`
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export async function deleteDashboard(id: number, is_hard_delete: boolean) {
  try {
    const result = await axios.put(
      AnalyticsAndReportingDashBoardsApiRoutes.DELETE_DASHBOARD +
        "/" +
        id +
        "?is_hard_delete=" +
        `${is_hard_delete}`
    );
    return {
      data: result,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
}

export async function RestoreDashboard(id: number) {
  try {
    const result = await axios.put(
      // eslint-disable-next-line no-useless-concat
      AnalyticsAndReportingDashBoardsApiRoutes.RESTORE_DASHBOARD + "/" + `${id}`
    );
    return {
      data: result,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
}

export async function DefaultDashboard(id: number) {
  try {
    const result = await axios.put(
      // eslint-disable-next-line no-useless-concat
      AnalyticsAndReportingDashBoardsApiRoutes.DEFAULT_DASHBOARD + "/" + `${id}`
    );
    return {
      data: result,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
}

export const createNewReport = async (
  id: number,
  preference: {
    title?: string;
    summary?: string;
    description?: string;
    date_range?: number | string;
    accessOption?: "PTM" | "EVE" | "TEM";
    permission?: number | string;
    dashboardId?: number | string | null;
    teamId?: number | string | null;
    kpiFilterName?: string | null;
    kpiFilterTypeValues?: number | null;
    filterByTeamID?: number | null;
    filterByUserId?: number | null;
    fiscalYearValue?: string | null;
    pipelineFilter?: boolean;
    pipelineFilterId?: number | null;
  }
) => {
  const requestBody = {
    report: {
      widget_id: id,
      dashboard_id: preference.dashboardId ?? null,
      is_public: preference.accessOption === "PTM" ? false : true,
      view_edit: preference.accessOption === "PTM" ? 0 : preference.permission,
      team_id: preference.teamId,
    },
    preference: {
      title: preference.title ?? "",
      summary: preference.summary ?? "",
      description: preference.description ?? "",
      date_range: preference.date_range,
      others: [
        {
          kpiFilterName: preference.kpiFilterName,
          kpiFilterTypeValues: preference.kpiFilterTypeValues,
          filterByTeamID: preference.filterByTeamID,
          filterByUserID: preference.filterByUserId,
          fiscalYearValue: preference.fiscalYearValue,
          pipelineFilter: preference.pipelineFilter,
          pipelineFilterId: preference.pipelineFilterId,
        },
      ],
    },
  };

  try {
    const result = await axios.post(
      AnalyticsAndReportingDashBoardsApiRoutes.CREATE_NEW_REPORT,
      requestBody
    );
    return {
      data: result,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getAllReportsList = async () => {
  try {
    const result = await axios.get(
      AnalyticsAndReportingDashBoardsApiRoutes.GET_ALL_REPORTS_LIST
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const deleteReport = async (id: number) => {
  try {
    const result = await axios.delete(
      `${AnalyticsAndReportingDashBoardsApiRoutes.DELETE_REPORT}/${id}`
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const deleteWidgetFromDashboard = async (
  dashboardId: number,
  widgetId: number
) => {
  try {
    const requestBody = {
      widget_ids: [widgetId],
    };
    const result = await axios.delete(
      `${AnalyticsAndReportingDashBoardsApiRoutes.DELETE_WIDGET_FROM_DASHBOARD}/${dashboardId}`,
      {
        data: requestBody,
      }
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const reportAssignToDashboard = async (
  toDashboardId: string | number | null | undefined,
  reportId: string | number | null
) => {
  try {
    const requestBody = {
      dashboard_id: toDashboardId,
    };
    const result = await axios.patch(
      `${AnalyticsAndReportingDashBoardsApiRoutes.ADD_REPORT_TO_DASHBOARD}/${reportId}`,
      requestBody
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const renameReport = async (reportId: number, title: string) => {
  try {
    const requestBody = {
      title: title,
    };

    const result = await axios.patch(
      `${AnalyticsAndReportingDashBoardsApiRoutes.RENAME_REPORT_TITLE}/${reportId}`,
      requestBody
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const renameDescription = async (
  reportId: number,
  description: string
) => {
  try {
    const requestBody = {
      description: description,
    };

    const result = await axios.patch(
      `${AnalyticsAndReportingDashBoardsApiRoutes.RENAME_REPORT_TITLE}/${reportId}`,
      requestBody
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};
export const customizationUpdate = async (
  reportId: number,
  color: ColorGroundList
) => {
  try {
    const requestBody = {
      foreground_color: color.forGroundColor,
      background_color: color.backGroundColor,
    };

    const result = await axios.patch(
      `${AnalyticsAndReportingDashBoardsApiRoutes.RENAME_REPORT_TITLE}/${reportId}`,
      requestBody
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const moveReport = async (
  fromDashboardId: number,
  reportId: number,
  toDashboardId: number
) => {
  try {
    const requestBody = {
      dashboard_id: toDashboardId,
    };

    const result = await axios.patch(
      `${AnalyticsAndReportingDashBoardsApiRoutes.MOVE_REPORT_FROM_DASHBOARD}/${fromDashboardId}/${reportId}`,
      requestBody
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getTeamsOfAnUser = async () => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_ALL_TEAMS}/${userUId}/`
    );

    return {
      data: result.data,
      error: false,
      status: result.status,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
      status: 400,
    };
  }
};

export const getAllWidgetsWithTag = async (tag: string) => {
  try {
    const result = await axios.get(
      `${AnalyticsAndReportingDashBoardsApiRoutes.GET_REPORTS_WITH_TAG_SCOPE}?tag_name=${tag}`
    );

    return {
      data: result.data.response,
      error: false,
    };
  } catch (error) {
    return {
      data: [],
      error: true,
    };
  }
};
