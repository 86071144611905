import { useProgress } from "src/common/components/progress/ProgressContext";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";
import { Progress } from "rsuite";
import { ViewRoutes } from "src/common/view-routes";

const SetUpBasicBodyForCustomerService = () => {
  const { Line } = Progress;
  const { customerSupport } = useProgress();

  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="line_progress">
        <Line percent={customerSupport?.total} strokeColor="#319F69" />
      </div>

      <div className="drawerSubTitle">
        <div>
          {" "}
          <p
            onClick={() => {
              if (customerSupport.snippets !== 100) {
                window.location.href =
                  ViewRoutes.Snippets + "?getting-started=true";
              }
            }}
            className={
              customerSupport.snippets === 100 ? "titleLineThrough" : "title"
            }
          >
            Create Snippets
          </p>
          <p className="title_p">
            Snippets allow you to create reusable blocks of text that you can
            access quickly through keyboard shortcuts. Get started by entering a
            phrase or line of text that you find yourself typing often, like a
            greeting or meeting agenda.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (customerSupport.email !== 100) {
                window.location.href = ViewRoutes.Inbox;
              }
            }}
            className={
              customerSupport.email === 100 ? "titleLineThrough" : "title"
            }
          >
            Create Email
          </p>

          <p className="title_p">
            Streamline customer support and issue resolution by generating
            tickets within Roboket to prioritize and address customer inquiries
            and concerns effectively
          </p>
          <hr />
        </div>

        {/* <div className="card_top">
          <p
            onClick={() => {
              if (customerSupport.messenger !== 100) {
                window.location.href =
                  ViewRoutes.Conversion + "?getting-started=true";
              }
            }}
            className={
              customerSupport.messenger === 100 ? "titleLineThrough" : "title"
            }
          >
            Create Messenger
          </p>
          <p className="title_p">
            Drive revenue growth by creating and managing sales deals within
            Roboket to track progress, forecast revenue, and close deals
            efficiently.
          </p>
          <hr />
        </div> */}

        <div className="card_top">
          <p
            onClick={() => {
              if (customerSupport.chatbot !== 100) {
                window.location.href =
                  ViewRoutes.Inbox + "/chatbot?getting-started=true";
              }
            }}
            className={
              customerSupport.chatbot === 100 ? "titleLineThrough" : "title"
            }
          >
            Create Chatbot
          </p>
          <p className="title_p">
            Customize and deploy forms within Roboket to gather valuable
            customer information and streamline data collection for sales and
            marketing efforts.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (customerSupport.webform !== 100) {
                window.location.href =
                  ViewRoutes.Inbox + "/connect-channel/form";
              }
            }}
            className={
              customerSupport.webform === 100 ? "titleLineThrough" : "title"
            }
          >
            Create Webform
          </p>
          <p className="title_p">
            Coordinate and schedule meetings seamlessly within Roboket to
            facilitate communication and collaboration among your sales team and
            clients, ensuring productive interactions and successful outcomes.
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default SetUpBasicBodyForCustomerService;
