import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Dropdown, Badge, Button } from "rsuite";
import "./dashboardHeader.scss";
import { getDashboardLists } from "src/common/analytics-and-dashboard-api-calls";
import { GlobalContext } from "src/App";

type DashboardHeaderComponentType = {
  hasError: Boolean;
};

const DashboardHeaderComponent: React.FC<DashboardHeaderComponentType> = ({
  hasError,
}) => {
  const {
    setIsLoading: setIsLoadingForDashboardChange,
    // isLoading: globalIsLoading,
  } = useContext(GlobalContext);
  const { id }: any = useParams();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [dashboards, setDashboards] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState<any>("Sales");
  const [isDefault, setIsDefault] = useState<boolean>(false);

  useEffect(() => {
    const getDashboardInfo = async () => {
      const response = await getDashboardLists();

      if (response.error) {
        setIsLoading(false);
      } else {
        if (response.data.length === 0) {
          setIsLoading(false);
          history.push("/create-dashboard");
        } else {
          setIsLoading(false);
          setDashboards(response.data);
          let selectedDashboardInfo = response.data.filter(
            (data: any) => data.id === Number(id)
          );
          setSelectedTitle(selectedDashboardInfo[0]?.dashboard_name ?? "");
          setIsDefault(selectedDashboardInfo[0]?.is_default ?? false);
        }
      }
    };

    getDashboardInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="inbox-top-section shadow-sm">
      <div className="top-elements-wrapper">
        <div className="inner __dashboard_lists__title">
          <div className="inner __dashboard_lists__title">
            {!hasError && (
              <Badge
                content={!isLoading && isDefault ? "Default" : false}
                className="__dashboard_lists__title_badge"
              >
                <Dropdown
                  title={isLoading ? "loading" : selectedTitle}
                  appearance="ghost"
                  style={{ minWidth: "200px" }}
                  className="___selected_dashboard_name"
                >
                  {dashboards.length > 0 &&
                    dashboards.map((dashboard: any) => (
                      <Dropdown.Item
                        onSelect={() => {
                          if (String(dashboard.id) !== id) {
                            setIsLoadingForDashboardChange(true);
                          }
                          setIsDefault(dashboard.is_default);
                          setSelectedTitle(dashboard.dashboard_name);
                          history.push(`/dashboard/${dashboard.id}`);
                        }}
                        style={{ padding: "10px 0px" }}
                        key={dashboard.id}
                      >
                        {dashboard.dashboard_name}
                      </Dropdown.Item>
                    ))}
                </Dropdown>
              </Badge>
            )}
          </div>
        </div>
        <div className="actions">
          <Button
            className="green-button"
            onClick={() => history.push(`/dashboard-lists`)}
          >
            Manage Dashboards
          </Button>
          <Button
            className="green-button"
            onClick={() => history.push(`/create-dashboard`)}
          >
            Create New dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeaderComponent;
