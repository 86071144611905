import React from "react";
import { intersection } from "lodash";
import { RoleViewMapperConfig } from "../config/RoleViewMapperConfig";
import { Dropdown } from "rsuite";
import {
  GetAllPermissionList,
  GetCurrentUserPermissionList,
} from "../components/api-common";

export function isLoggedIn() {
  return !!localStorage.getItem("token");
}

export function isArrayWithLength(arr: any) {
  return Array.isArray(arr) && arr.length;
}

async function getAllPermission() {
  const permissions = await GetAllPermissionList();
  if (permissions) {
    let permissionNames = permissions.data.map((s: any) => s.name);
    return permissionNames;
  }
  return [];
}

export async function getAllowedRoutesNav(
  allowedPermissions: string[],
  history: any
) {
  const allPermissions = await getAllPermission();
  const filtered = allowedPermissions.filter(
    ({ permission_names, method }: any) => {
      if (!permission_names) {
        return true;
      } else if (!isArrayWithLength(permission_names)) {
        return true;
      } else {
        let val = intersection(permission_names, allPermissions);
        return val.length;
      }
    }
  );

  const mappers = [] as any[];

  RoleViewMapperConfig.forEach((mapper) => {
    if (filtered.includes(mapper.name)) {
      mappers.push(mapper);
    }
  });

  const parentNavs = mappers.filter((mapper) => {
    if (mapper && !("parent" in mapper) && mapper.navVisibility) {
      return true;
    }
    return false;
  });

  var navsMappers = [] as any[];
  parentNavs.forEach((nav) => {
    const navChilds = mappers.filter((s) => s?.parent === nav?.name);
    navsMappers.push({ nav: nav, childs: navChilds });
  });

  var navs = navsMappers.map((navMap, index: number) => {
    return (
      <Dropdown title={navMap.nav.title} key={index} className="DropDownMod">
        {navMap.childs.map((childMap: any, index: number) => (
          <Dropdown.Item
            key={index}
            onSelect={() => {
              let path = "";

              if (Array.isArray(childMap.path)) {
                path = childMap.path[0];
              } else {
                path = childMap.path;
              }

              if (childMap.hyperlink) {
                window.location.href = path;
              } else {
                history.push(path);
              }
            }}
          >
            {childMap.title}
          </Dropdown.Item>
        ))}
      </Dropdown>
    );
  });

  return navs;
}

export const getUserPermissions = async () => {
  const permissions = await GetCurrentUserPermissionList();
  if (permissions !== null && permissions.permission_names.length) {
    var perms = permissions.permission_names;
    if (process.env.REACT_APP_PERMISSION_TEST_MODE) {
      perms = permissions.permission_names.concat([
        "import_access",
        "import_google_access",
        "import_file_access",
        "tour_access",
        "notification_access",
        "contact_company_setting_access",
        "contact_property_assign_access",
        "company_property_assign_access",
        "setting_access",
        "conversation_menu_access",
        "email_connect_access",
        "page_connect_access",
        "website_chat_connect_access",
        "webform_connect_access",
        "chat_flow_setting_access",
        "chat_flow_access",
        "sales_menu_access",
        "fb_page_for_chat_access",
        "account_access",
        "integrations_setting_access",
        "role_permission_setting_access",
        "email_campaign",
        "fb_campaign",
      ]);
    }
    return perms;
  }
  return [];
};

export const usePrevious = (value: any) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const getCurrentUserToken = () => {
  const token = localStorage.getItem("token");
  return token;
};
