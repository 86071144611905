import { useProgress } from "src/common/components/progress/ProgressContext";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";
import { Progress } from "rsuite";
import { ViewRoutes } from "src/common/view-routes";

type state_type = "users" | "teams";
type property_type = "contact" | "company";
type deals_pipeline_type = "deals_pipeline" | "deals_pipeline_stage";
type ticket_pipeline_type = "ticket_pipeline" | "ticket_pipeline_stage";

const SetUpBasicBodyForWebsite = () => {
  const { Line } = Progress;
  let stateTypeTeams: state_type = "teams";
  let stateTypeUsers: state_type = "users";

  let propertyTypeContact: property_type = "contact";
  let propertyTypeCompany: property_type = "company";

  let dealsPipelineType: deals_pipeline_type = "deals_pipeline";
  let dealsPipelineStageType: deals_pipeline_type = "deals_pipeline_stage";

  let ticketPipelineType: ticket_pipeline_type = "ticket_pipeline";
  let ticketPipelineStageType: ticket_pipeline_type = "ticket_pipeline_stage";

  const { setupSupport } = useProgress();

  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="line_progress">
        <Line percent={setupSupport?.total} strokeColor="#319F69" />
      </div>

      <div className="drawerSubTitle">
        <div>
          {" "}
          <p
            onClick={() => {
              localStorage.setItem("activeSidebar", "basic");
              if (setupSupport?.user_signature !== 100) {
                window.location.href =
                  ViewRoutes.Settings + "?getting-started=true";
              }
            }}
            className={
              setupSupport?.user_signature === 100
                ? "titleLineThrough"
                : "title"
            }
          >
            Create Signature
          </p>
          <p className="title_p">
            Generate a personalized digital signature effortlessly with
            Roboket's intuitive signature creation tool.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              localStorage.setItem("activeSidebar", "property_setting_access");
              if (setupSupport?.contact_dynamic_properties !== 100) {
                window.location.href =
                  ViewRoutes.Settings +
                  "?getting-started=true&property_type=" +
                  `${propertyTypeContact}`;
              }
            }}
            className={
              setupSupport?.contact_dynamic_properties === 100
                ? "titleLineThrough"
                : "title"
            }
          >
            Create Contact Dynamic Properties
          </p>

          <p className="title_p">
            Customize contact attributes to fit your unique CRM needs with
            Roboket's dynamic property creation.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              localStorage.setItem("activeSidebar", "property_setting_access");
              if (setupSupport?.company_dynamic_properties !== 100) {
                window.location.href =
                  ViewRoutes.Settings +
                  "?getting-started=true&property_type=" +
                  `${propertyTypeCompany}`;
              }
            }}
            className={
              setupSupport?.company_dynamic_properties === 100
                ? "titleLineThrough"
                : "title"
            }
          >
            Create Company Dynamic Properties
          </p>
          <p className="title_p">
            Define and manage specific attributes for companies with Roboket's
            flexible dynamic property settings.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (setupSupport?.invited_user !== 100) {
                localStorage.setItem(
                  "activeSidebar",
                  "user_team_setting_access"
                );
                window.location.href =
                  ViewRoutes.Settings +
                  "?getting-started=true&state_type=" +
                  `${stateTypeUsers}`;
              }
            }}
            className={
              setupSupport?.invited_user === 100 ? "titleLineThrough" : "title"
            }
          >
            Create Invited User
          </p>
          <p className="title_p">
            Seamlessly invite and manage new users to your Roboket platform with
            a simple, streamlined process.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (setupSupport?.teams !== 100) {
                localStorage.setItem(
                  "activeSidebar",
                  "user_team_setting_access"
                );
                window.location.href =
                  ViewRoutes.Settings +
                  "?getting-started=true&state_type=" +
                  `${stateTypeTeams}`;
              }
            }}
            className={
              setupSupport?.teams === 100 ? "titleLineThrough" : "title"
            }
          >
            Create Teams
          </p>
          <p className="title_p">
            Organize and manage your workforce efficiently by creating teams
            within Roboket.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (setupSupport?.deal_pipeline !== 100) {
                localStorage.setItem(
                  "activeSidebar",
                  "pipeline_setting_access"
                );
                window.location.href =
                  ViewRoutes.Settings +
                  "?getting-started=true&deals_pipeline_type=" +
                  `${dealsPipelineType}`;
              }
            }}
            className={
              setupSupport?.deal_pipeline === 100 ? "titleLineThrough" : "title"
            }
          >
            Create Deal Pipeline
          </p>
          <p className="title_p">
            Design and manage your sales process with customizable deal
            pipelines in Roboket."
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (setupSupport?.deal_pipeline_stage !== 100) {
                localStorage.setItem(
                  "activeSidebar",
                  "pipeline_setting_access"
                );
                window.location.href =
                  ViewRoutes.Settings +
                  "?getting-started=true&deals_pipeline_type=" +
                  `${dealsPipelineStageType}`;
              }
            }}
            className={
              setupSupport?.deal_pipeline_stage === 100
                ? "titleLineThrough"
                : "title"
            }
          >
            Create Deal Pipeline Stage
          </p>
          <p className="title_p">
            Define the stages of your sales process to track deal progress
            accurately with Roboket's pipeline stage creation.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (setupSupport?.ticket_pipeline !== 100) {
                localStorage.setItem(
                  "activeSidebar",
                  "pipeline_setting_access"
                );
                window.location.href =
                  ViewRoutes.Settings +
                  `?getting-started=true&state_type=${ticketPipelineType}&ticket_pipeline_type=` +
                  `${ticketPipelineType}`;
              }
            }}
            className={
              setupSupport?.ticket_pipeline === 100
                ? "titleLineThrough"
                : "title"
            }
          >
            Create Ticket Pipeline
          </p>
          <p className="title_p">
            Set up and manage your support process with tailored ticket
            pipelines in Roboket.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (setupSupport?.ticket_pipeline !== 100) {
                localStorage.setItem(
                  "activeSidebar",
                  "pipeline_setting_access"
                );
                window.location.href =
                  ViewRoutes.Settings +
                  `?getting-started=true&state_type=${ticketPipelineStageType}&ticket_pipeline_type=` +
                  `${ticketPipelineStageType}`;
              }
            }}
            className={
              setupSupport?.ticket_pipeline === 100
                ? "titleLineThrough"
                : "title"
            }
          >
            Create Ticket Pipeline Stage
          </p>
          <p className="title_p">
            Establish and monitor the stages of your support tickets for better
            issue resolution with Roboket.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (setupSupport?.ticket_pipeline_stage !== 100) {
                window.location.href =
                  ViewRoutes.Meetings + "/connect-calendar";
              }
            }}
            className={
              setupSupport?.ticket_pipeline_stage === 100
                ? "titleLineThrough"
                : "title"
            }
          >
            Create tags
          </p>
          <p className="title_p">
            Coordinate and schedule meetings seamlessly within Roboket to
            facilitate communication and collaboration among your sales team and
            clients, ensuring productive interactions and successful outcomes.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (setupSupport?.notification !== 100) {
                localStorage.setItem(
                  "activeSidebar",
                  "notification_setting_access"
                );
                window.location.href = ViewRoutes.Settings;
              }
            }}
            className={
              setupSupport?.notification === 100 ? "titleLineThrough" : "title"
            }
          >
            Create Notification
          </p>
          <p className="title_p">
            Keep your team informed with customizable notifications tailored to
            your workflow in Roboket.
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default SetUpBasicBodyForWebsite;
