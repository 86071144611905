// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nameProperty {
  color: #1d3d70;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}

.dateProperty {
  color: #1d3d70;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px;
  display: flex;
  align-items: center;
  gap: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/GraphANdChartsSnippets/Table/helpers/column.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AACA;EAEE,cAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AACF","sourcesContent":[".nameProperty {\n  color: #1d3d70;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 19.6px;\n}\n.dateProperty {\n  // color: #959595;\n  color: #1d3d70;\n  font-family: Poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 19.6px;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
