// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.___create_report_header .__create_report__title {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0.03em;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/headers/createReportHeader.scss"],"names":[],"mappings":"AACE;EACE,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".___create_report_header {\n  .__create_report__title {\n    font-family: Poppins;\n    font-size: 30px;\n    font-weight: 600;\n    line-height: 45px;\n    letter-spacing: 0.03em;\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
