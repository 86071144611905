// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.__report_container .rs-sidebar {
  flex: 0 0 350px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/createReport.scss"],"names":[],"mappings":"AACE;EACE,0BAAA;AAAJ","sourcesContent":[".__report_container {\n  .rs-sidebar {\n    flex: 0 0 350px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
