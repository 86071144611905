import React, { createContext, useState, useContext } from "react";

const ProgressContext = createContext();

export const ProgressProvider = ({ children }) => {
  const [totalProgressSales, setTotalProgressSales] = useState(0);
  const [dealsProgress, setDealsProgress] = useState(0);
  const [formSalesProgress, setFormSalesProgress] = useState(0);
  const [meetingProgress, setMeetingProgress] = useState(0);
  const [taskProgress, setTaskProgress] = useState(0);
  const [ticketProgress, setTicketProgress] = useState(0);

  const [totalProgressMarketing, setTotalProgressMarketing] = useState(0);
  const [campaignProgress, setCampaignProgress] = useState(0);
  const [adsProgress, setAdsProgress] = useState(0);
  const [assetsProgress, setAssetsProgress] = useState(0);
  const [emailProgress, setEmailProgress] = useState(0);
  const [formProgress, setFormProgress] = useState(0);
  const [pageProgress, setPageProgress] = useState(0);
  const [txtMsgProgress, setTxtMsgProgress] = useState(0);
  const [whatsAppProgress, setWhatsAppProgress] = useState(0);

  const [setupSupport, setSetupSupport] = useState(0);

  const [customerSupport, setCustomerSupport] = useState(0);

  return (
    <ProgressContext.Provider
      value={{
        totalProgressSales,
        setTotalProgressSales,
        totalProgressMarketing,
        setTotalProgressMarketing,
        campaignProgress,
        setCampaignProgress,
        adsProgress,
        setAdsProgress,
        dealsProgress,
        setDealsProgress,
        formSalesProgress,
        setFormSalesProgress,
        meetingProgress,
        setMeetingProgress,
        taskProgress,
        setTaskProgress,
        ticketProgress,
        setTicketProgress,
        assetsProgress,
        setAssetsProgress,
        emailProgress,
        setEmailProgress,
        formProgress,
        setFormProgress,
        pageProgress,
        setPageProgress,
        txtMsgProgress,
        setTxtMsgProgress,
        whatsAppProgress,
        setWhatsAppProgress,
        customerSupport,
        setCustomerSupport,
        setupSupport,
        setSetupSupport,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export const useProgress = () => useContext(ProgressContext);
