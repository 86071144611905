import "./style.scss";

const NotFound = () => {
  return (
    <div className="not-found-container">
      The Page You Are requesting does not exist
    </div>
  );
};

export default NotFound;
