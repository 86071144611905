import React, { useContext, useEffect, useState } from "react";
import { ReportContextAPI } from "src/pages/CreateReport";
import { getAllWidgetsForReport } from "src/common/analytics-and-dashboard-api-calls";

import LoadingComponent from "src/common/components/loading/Loading";
import HasError from "src/common/components/hasError/HasError";

import TableSnippet from "../GraphANdChartsSnippets/Table/Table";
import MicroCardGroup from "../GraphANdChartsSnippets/MicroCardGroup/MicroCardGroup";
import VerticalLineChart from "../GraphANdChartsSnippets/VerticalLineChart/VerticalLineChart";
import HorizontalBarGraph from "../GraphANdChartsSnippets/HorizontalBarGraph/HorizontalBarGraph";
import PieChart from "../GraphANdChartsSnippets/PieChart/pieChart";

import { IWidgetInfo, WidgetSources } from "src/common/types/analytics.types";
import "./createReportContainer.scss";
import SingleCard from "../GraphANdChartsSnippets/SingleCard/SingleCard";
import { registeredWidgets } from "src/widget.register";

const CreateReportContainer: React.FC = () => {
  const { reportWidgetSelectedList } = useContext(ReportContextAPI);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [widgetLists, setWidgetsList] = useState([]);

  const Widget = ({
    widgetData,
    content,
  }: {
    widgetData: IWidgetInfo;
    content: string;
  }) => {
    return (
      <div style={{ height: "100%", cursor: "pointer" }}>
        {content === "contact_vs_recent" && (
          <TableSnippet
            key={content}
            title={widgetData.widget_title}
            endPoint={widgetData.widget_endpoint}
            source={WidgetSources.report}
            description={widgetData.widget_description}
            widgetData={widgetData}
          />
        )}
        {content === "total_contact_created_vs_last_week" && (
          <MicroCardGroup
            title={widgetData.widget_title}
            endpoint={widgetData.widget_endpoint}
          />
        )}
        {content === "total_task_created_vs_last_week" && (
          <MicroCardGroup
            title={widgetData.widget_title}
            endpoint={widgetData.widget_endpoint}
          />
        )}
    

        {content === "contact_vs_time" && (
          <VerticalLineChart
            title={widgetData.widget_title}
            endPoint={widgetData.widget_endpoint}
            source={WidgetSources.report}
            description={widgetData.widget_description}
            widgetData={widgetData}
          />
        )}
        {content === "task_vs_month" && (
          <VerticalLineChart
            title={widgetData.widget_title}
            endPoint={widgetData.widget_endpoint}
            source={WidgetSources.report}
            description={widgetData.widget_description}
            widgetData={widgetData}
          />
        )}

        {content === "contact_creation_total_by_source" && (
          <HorizontalBarGraph
            title={widgetData.widget_title}
            endPoint={widgetData.widget_endpoint}
            source={WidgetSources.report}
            description={widgetData.widget_description}
            widgetData={widgetData}
          />
        )}

        {content === "contact_creation_total_by_person" && (
          <>
            <HorizontalBarGraph
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              source={WidgetSources.report}
              description={widgetData.widget_description}
              widgetData={widgetData}
            />
          </>
        )}

        {content === "company_vs_recent" && (
          <>
            <TableSnippet
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              source={WidgetSources.report}
              description={widgetData.widget_description}
              widgetData={widgetData}
            />
          </>
        )}

        {content === "total_company_created_vs_last_week" && (
          <>
            <MicroCardGroup
              title={widgetData.widget_title}
              endpoint={widgetData.widget_endpoint}
            />
          </>
        )}

        {content === "company_vs_time" && (
          <>
            <VerticalLineChart
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              source={WidgetSources.report}
              description={widgetData.widget_description}
              widgetData={widgetData}
            />
          </>
        )}

        {content === "company_creation_total_by_person" && (
          <>
            <HorizontalBarGraph
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              source={WidgetSources.report}
              description={widgetData.widget_description}
              widgetData={widgetData}
            />
          </>
        )}

        {content === "total_ticket_created_vs_last_week" && (
          <>
            <MicroCardGroup
              title={widgetData.widget_title}
              endpoint={widgetData.widget_endpoint}
            />
          </>
        )}

        {content === "ticket_vs_month" && (
          <>
            <VerticalLineChart
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              source={WidgetSources.report}
              description={widgetData.widget_description}
              widgetData={widgetData}
            />
          </>
        )}

        {content === "ticket_status" && (
          <>
            <PieChart
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              source={WidgetSources.report}
              description={widgetData.widget_description}
              widgetData={widgetData}
            />
          </>
        )}
        {content === "task_status" && (
          <>
            <PieChart
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              source={WidgetSources.report}
              description={widgetData.widget_description}
              widgetData={widgetData}
            />
          </>
        )}

        {content === "stage_wise_deals_amount" && (
          <>
            <VerticalLineChart
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}
        {content === "get_stage_ticket_count" && (
          <>
            <VerticalLineChart
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "deals_in_a_stage_count" && (
          <>
            <VerticalLineChart
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "get_created_last_24h" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}
       
        {content === "get_total_created" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "get_close_last_24h" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "total_company_created" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "last_week_company_created" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "top_month_company_creation" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "average_company_creation_per_month" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "total_task_count" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "last_week_total_task_count" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "top_month_task_analytics" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "get_max_deal_amount_month" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "avg_month_task_analytics_cards" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "get_lowest_deal_amount_month" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "month_wise_deals_amount" && (
          <>
            <HorizontalBarGraph
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "get_total_ticket" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}

        {content === "get_total_open_ticket" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "get_total_close_ticket" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "get_total_ticket_in_progress" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "average_ticket_resolve_time" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "ticket_close_before" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "ticket_close_after" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "ticket_person_wise_in_progress" && (
          <>
            <HorizontalBarGraph
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "ticket_person_wise_open" && (
          <>
            <HorizontalBarGraph
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "ticket_person_wise_close" && (
          <>
            <HorizontalBarGraph
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
            />
          </>
        )}

        {content === "total_company_target" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}
        {content === "achieved_till_date" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}
        {content === "backlog_till_date" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}
        {content === "achieved_this_month" && (
          <>
            <SingleCard
              title={widgetData.widget_title}
              endPoint={widgetData.widget_endpoint}
              description={widgetData.widget_description}
              widgetData={widgetData}
              source={WidgetSources.report}
              showFilter={true}
            />
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchAllWidgetsList = async () => {
      try {
        setHasError(false);
        const response = await getAllWidgetsForReport();
        if (response.error === true) {
          setIsLoading(false);
          setHasError(true);
        } else {
          setIsLoading(false);
          const widgets = response.data?.filter((wid: any) => {
            return registeredWidgets.includes(wid?.widget_name);
          });
          setWidgetsList(widgets);
        }
      } catch (error) {
        console.log("error occurred at fetchAllWidgetsList");
      }
    };

    fetchAllWidgetsList();
  }, []);

  return (
    <div className="createReportContainer__container shadow-sm">
      <div className="title">Create New Custom Reports</div>

      {isLoading && !hasError && <LoadingComponent message="Fetching Data" />}

      {!isLoading && hasError && <HasError />}

      <div className="graph_container">
        {!isLoading && !hasError && widgetLists.length > 0 ? (
          <>
            {widgetLists.map((widget: any) => {
              return (
                reportWidgetSelectedList.includes(widget.id) && (
                  <Widget content={widget.widget_name} widgetData={widget} />
                )
              );
            })}
          </>
        ) : (
          <>{!isLoading && <>No widget Found</>}</>
        )}

        {!isLoading && !hasError && reportWidgetSelectedList.length === 0 && (
          <div className="no-widget-found">
            No widget Found. Please use the filter option to search for widgets.
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateReportContainer;
