// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content-onboard {
  overflow: hidden !important;
}
.main-content-onboard .rs-content {
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/onboard.scss"],"names":[],"mappings":"AAAA;EAIE,2BAAA;AAFF;AADE;EACE,gBAAA;AAGJ","sourcesContent":[".main-content-onboard {\n  .rs-content {\n    overflow: hidden;\n  }\n  overflow: hidden !important ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
