import React, { useState, useCallback, useEffect } from "react";
import { Responsive, WidthProvider, Layouts, Layout } from "react-grid-layout";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface GridLayoutProps {
  data: any[];
  dashboardId?: string;
}

const data = [
  { id: 1, content: "Card 1" },
  { id: 2, content: "Card 2" },
  { id: 3, content: "Card 3" },
];

const GridLayout: React.FC<GridLayoutProps> = ({
  data,
  dashboardId = "default",
}) => {
  const [layouts, setLayouts] = useState<Layouts>({});

  const generateLayout = useCallback(
    (breakpoint: string) => {
      return data.map((_, index) => ({
        i: `layout-item-${index}`,
        x: (index * 6) % 12,
        y: Math.floor(index / 2),
        w: 6,
        h: 4,
        minW: 2,
        minH: 1,
      }));
    },
    [data]
  );

  const handleBreakPointChange = useCallback((newBreakpoint: string) => {
    const layout = localStorage.getItem(
      `@layouts-${dashboardId}-${newBreakpoint}`
    );
    if (layout) {
      setLayouts((prevLayouts) => ({
        ...prevLayouts,
        [newBreakpoint]: JSON.parse(layout),
      }));
    } else {
      const newLayout = generateLayout(newBreakpoint);
      setLayouts((prevLayouts) => ({
        ...prevLayouts,
        [newBreakpoint]: newLayout,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLayoutChange = useCallback(
    (newLayout: Layout[], allLayouts: Layouts, breakpoint: string) => {
      setLayouts((prevLayouts) => ({
        ...prevLayouts,
        [breakpoint]: newLayout,
      }));
      localStorage.setItem(
        `@layouts-${dashboardId}-${breakpoint}`,
        JSON.stringify(newLayout)
      );
    },
    [dashboardId]
  );

  useEffect(() => {
    const persistedLayouts = Object.keys(layouts).reduce((acc, breakpoint) => {
      const layout = localStorage.getItem(
        `@layouts-${dashboardId}-${breakpoint}`
      );
      if (layout) {
        acc[breakpoint] = JSON.parse(layout);
      } else {
        const newLayout = generateLayout(breakpoint);
        acc[breakpoint] = newLayout;
        localStorage.setItem(
          `@layouts-${dashboardId}-${breakpoint}`,
          JSON.stringify(newLayout)
        );
      }
      return acc;
    }, {} as Layouts);

    setLayouts(persistedLayouts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dashboardId, generateLayout]);

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={layouts}
      onBreakpointChange={handleBreakPointChange}
      onLayoutChange={handleLayoutChange as any}
      draggableHandle=".drag"
      breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 4 }}
    >
      {data.map((item, index) => (
        <div
          key={`layout-item-${index}`}
          className="grid-item"
          style={{ border: "1px solid #D1D8E3", background: "white" }}
        >
          <div
            className="grid-item__title drag"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {/* <TemplateRenderer item={item} key={item?.widget_name} /> */}
            Card {index}
          </div>
        </div>
      ))}
    </ResponsiveGridLayout>
  );
};

const GridTest = () => {
  return (
    <div>
      <GridLayout data={data} dashboardId="my-dashboard" />
    </div>
  );
};

export default GridTest;
