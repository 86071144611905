import React, { useEffect, useState } from "react";
import { Content, Header } from "rsuite";
import SidebarForOnboard from "src/common/components/sidebarForOnboard/SidebarForOnboard";
import ContactUsSupport from "src/common/components/userGuideOnboard/ContactUsSupport";
import OnboardUser from "src/common/components/userGuideOnboard/OnboardUser";
import StatusPage from "src/common/components/userGuideOnboard/StatusPage";
import SubmitATicket from "src/common/components/userGuideOnboard/SubmitATicket";
import { getUserId } from "src/common/constants";
import { HeaderBadge } from "src/view";
import "./styles/onboard.scss";
import { GetUserById } from "src/common/api-common";

const Onboard = () => {
  const [open, setOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Marketing");
  const [fullName, setFullName] = useState("");
  const userId = getUserId();

  const getAccountInfo = async () => {
    try {
      const result = await GetUserById(userId);
      setFullName(
        result?.data?.userDetails?.firstName +
          " " +
          result?.data?.userDetails?.lastName
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleSelectMenuItem = (eventKey: string) => {
    setSelectedMenuItem(eventKey);
  };

  useEffect(() => {
    getAccountInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content className="main-content-onboard">
      <Header>
        <HeaderBadge title="User Guide" />
      </Header>

      <div style={{ display: "flex" }}>
        <div className="main-sideNav">
          <SidebarForOnboard
            handleOpen={handleOpen}
            open={open}
            onSelectMenuItem={handleSelectMenuItem}
            selectedMenuItem={selectedMenuItem}
          />
        </div>

        <div
          style={{
            padding: "0px 20px",
            background: "white",
            overflow: "auto",
            width: "100%",
            height: "81vh",
          }}
        >
          {selectedMenuItem === "Marketing" && (
            <OnboardUser
              fullName={fullName}
              selectedMenuItem={selectedMenuItem}
            />
          )}
          {selectedMenuItem === "Sales" && (
            <OnboardUser
              fullName={fullName}
              selectedMenuItem={selectedMenuItem}
            />
          )}
          {selectedMenuItem === "Customer Service" && (
            <OnboardUser
              fullName={fullName}
              selectedMenuItem={selectedMenuItem}
            />
          )}
          {selectedMenuItem === "Website (CMS)" && (
            <OnboardUser
              fullName={fullName}
              selectedMenuItem={selectedMenuItem}
            />
          )}
          {selectedMenuItem === "Submit a ticket" && (
            <SubmitATicket
              fullName={fullName}
              selectedMenuItem={selectedMenuItem}
            />
          )}
          {selectedMenuItem === "Contact us" && (
            <ContactUsSupport
              fullName={fullName}
              selectedMenuItem={selectedMenuItem}
            />
          )}
          {selectedMenuItem === "Status page" && (
            <StatusPage
              fullName={fullName}
              selectedMenuItem={selectedMenuItem}
            />
          )}
        </div>
      </div>
    </Content>
  );
};

export default Onboard;
