import { roboketCrmUi } from "./routes";

export const ViewRoutes = {
  Root: "/",
  RootSub: roboketCrmUi.BASE_URL,
  Contact: roboketCrmUi.BASE_URL + "/contact",
  ContactSegment: roboketCrmUi.BASE_URL + "/contacts/segments",
  Company: roboketCrmUi.BASE_URL + "/company",
  Settings: roboketCrmUi.BASE_URL + "/settings",
  Tasks: roboketCrmUi.BASE_URL + "/task",
  Tickets: roboketCrmUi.BASE_URL + "/tickets",
  Form: roboketCrmUi.BASE_URL + "/form",
  Campaign: roboketCrmUi.Campaign_URL + "/",
  Import: roboketCrmUi.BASE_URL + "/import",
  Conversion: roboketCrmUi.BASE_URL + "/deals",
  Account: roboketCrmUi.BASE_URL + "/account",
  Snippets: roboketCrmUi.BASE_URL + "/snippets",
  Inbox: roboketCrmUi.BASE_URL + "/inbox",
  Email: "/email",
  EmailTemplate: "/email-template",
  Meetings: roboketCrmUi.BASE_URL + "/meetings",
  Products: roboketCrmUi.BASE_URL + "/product",
  Kpi: roboketCrmUi.BASE_URL + "/kpi",
  Create_Campaign_URL: roboketCrmUi.Campaign_URL,
  Dashboard: "/",
  Report: "/create-report",
  Ads: roboketCrmUi.Ads,
  Attendance: roboketCrmUi.BASE_URL + "/attendance",
};
