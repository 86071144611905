import React from "react";
import "./landing-page-dashboard.scss";
import landingpgaImage from "../../../assets/svgs/landingPageImage.svg";
import CardProperty from "./CardProperty";
import { DashboardData, DashboardDataQuickSetting } from "./DashboardData";
import SettingCard from "./SettingCard";

const LandingPageDashboard = () => {
  return (
    <div className="landingPageParentContainer">
      <div className="flexBody">
        <div className="flex1">
          <p className="flex1Text">
            We’re delighted you’re here! You’ll be a big asset within our
            company and we can’t wait to see what you’ll accomplish
          </p>

          <p className="noData">No Data Available </p>

          <div className="imgResize">
            {" "}
            <img src={landingpgaImage} alt="landingpgaImage" />{" "}
          </div>
        </div>

        <div className="flexMiddle">
          <div className="vl"></div>
        </div>

        <div className="flex2">
          <p className="title">Quick Options</p>

          <div className="hl"></div>

          <div className="flexForCard">
            {DashboardData.map((DashboardDataIndex) => (
              <div className="cardContainer">
                <CardProperty
                  image={DashboardDataIndex.image}
                  subText={DashboardDataIndex.subText}
                  linkForDashboard={DashboardDataIndex.linkForDashboard}
                />
              </div>
            ))}
          </div>

          <div className="quickSetting">
            <p className="title">Quick Settings</p>

            <div className="hl"></div>

            <div className="flexForCardQuickSetting">
              {DashboardDataQuickSetting.map((DashboardDataIndex) => (
                <div className="cardContainer">
                  <SettingCard
                    image={DashboardDataIndex.image}
                    subText={DashboardDataIndex.subText}
                    linkForDashboard={DashboardDataIndex.linkForDashboard}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageDashboard;
