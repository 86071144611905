import React from "react";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";

function OwnerWebsiteBody() {
  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="drawerSubTitle">
        <div>
          <p className="title">Select a theme for your site</p>
          <p className="title_p">
            A theme is a collection of page templates, modules, and style
            settings that make it easy to create a cohesive website. Choose one
            that fits your brand and get started — no coding required.
          </p>
          <hr />
        </div>

        <div>
          <p className="title">Customize your theme's settings</p>
          <p className="title_p">
            Set your theme's style by selecting colors, fonts, and layouts that
            fit your brand.
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default OwnerWebsiteBody;
