import React from "react";
import { Container, Content, Header } from "rsuite";

import DashboardListsHeader from "src/components/headers/DashboardListsHeader";
import DashboardTable from "src/components/DashboardTable/DashboardTable";

import "./styles/dashboard-list.scss";

const DashboardLists: React.FC = () => {
  const [active, setActive] = React.useState<string | null>("my-reports");

  return (
    <>
      <Content className="main_content">
        <Container className="bg-container">
          <Header>
            <DashboardListsHeader setActive={setActive} active={active} />
          </Header>
          <div className="__dashboard_container">
            {/* <SidebarComponentDashboard setSelectedOption={setSelectedOption} /> */}
            {active === "my-reports" ? (
              <DashboardTable pass="my-reports" />
            ) : active === "my-reports2" ? (
              <DashboardTable pass="my-reports-2" />
            ) : null}
          </div>
        </Container>
      </Content>
    </>
  );
};

export default DashboardLists;
