import { connect } from "react-redux";
import { Drawer } from "rsuite";
import { setDrawerName, toggleDrawer } from "../../redux/drawer/drawer.actions";
import "./adn-drawer.component.scss";

export const DrawerComponentState = (props: any) => {
  const {
    isDrawerShow,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    activePanel,
    toggleDrawer,
    backdrop = "static",
    placement = "right",
    size,
    title,
    drawerClose,
    setDrawerName,
    footer,
  } = props;

  const handleClose = () => {
    toggleDrawer(false);
    setDrawerName("");
    if (props.drawerClose) {
      drawerClose();
    }
  };

  const text = title;
  const maxLength = 28;
  const truncatedText =
    text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

  return (
    <Drawer
      show={isDrawerShow}
      onHide={handleClose}
      backdrop={backdrop}
      placement={placement}
      size={size}
    >
      <div className="drawerTitle">
        <Drawer.Header>
          <Drawer.Title> {truncatedText} </Drawer.Title>
        </Drawer.Header>
      </div>
      <Drawer.Body>{props.children}</Drawer.Body>

      <Drawer.Footer style={{ position: "fixed", bottom: "0" }}>
        <hr />
        <div style={{ marginTop: "10px" }}>{footer}</div>
      </Drawer.Footer>
    </Drawer>
  );
};

const mapStateToProps = ({
  drawer: { isDrawerShow },
  drawer: { activePanel },
}: any) => ({
  isDrawerShow,
  activePanel,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleDrawer: (toggle: boolean) => dispatch(toggleDrawer(toggle)),
  setDrawerName: (name: string) => dispatch(setDrawerName(name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerComponentState);
