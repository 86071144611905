export enum EmailMenu {
  AllMails = "AllMails",
  Draft = "Draft",
  Scheduled = "Scheduled",
  Sent = "Sent",
  Archived = "Archived",
  EmailSetup = "EmailSetup",
  BestPractice = "BestPractice",
}

export enum DeleteModalMessage {
  EmailCampaign = "Do you want to delete this email campaign ?",
}

export enum ModalFor {
  ContactAssign = "ContactAssign",
  ContactDelete = "ContactDelete",
  EmailCampaignEdit = "EmailCampaignEdit",
  CampaignEdit = "CampaignEdit",
  ABTesting = "ABTesting",
}

export enum SnippetOptions {
  filter = "__filter__",
  delete = "__delete__",
}

export const kpi_Type = [
  { label: "Company", value: 1 },
  { label: "Team", value: 2 },
  { label: "Individual", value: 3 },
];