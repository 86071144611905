import { Progress } from "rsuite";
import marketTool from "../assets/svgs/marketTool.svg";
import { useProgress } from "src/common/components/progress/ProgressContext";
import { useEffect } from "react";
import {
  _getCustomerSupportProgressData,
  _getMarketingProgressData,
  _getSalesProgressData,
  _getSetUpProgressData,
} from "src/common/api-common";

const progressStyle = {
  width: 131,
  display: "inline-block",
  marginRight: 10,
};
const FirstCard = (props: any) => {
  const { selectedMenuItem } = props;
  const {
    totalProgressSales,
    setTotalProgressMarketing,
    totalProgressMarketing,
    setCampaignProgress,
    setAdsProgress,
    setAssetsProgress,
    setEmailProgress,
    setFormProgress,
    setPageProgress,
    setTxtMsgProgress,
    setWhatsAppProgress,
    setCustomerSupport,
    customerSupport,
    setupSupport,
    setSetupSupport,
    setTotalProgressSales,
    setDealsProgress,
    setFormSalesProgress,
    setMeetingProgress,
    setTaskProgress,
    setTicketProgress,
  } = useProgress();
  const { Circle } = Progress;

  const getMarketingData = async () => {
    try {
      const response = await _getMarketingProgressData();
      setTotalProgressMarketing(response?.total ?? 0);
      setCampaignProgress(response?.campaigns ?? 0);
      setAdsProgress(response?.ads ?? 0);
      setAssetsProgress(response?.assets ?? 0);
      setEmailProgress(response?.emails ?? 0);
      setFormProgress(response?.forms ?? 0);
      setPageProgress(response?.pages ?? 0);
      setTxtMsgProgress(response?.text_messages ?? 0);
      setWhatsAppProgress(response?.whatsapp_messages ?? 0);
    } catch (error) {
      console.log(error);
      setTotalProgressMarketing(0);
      setCampaignProgress(0);
      setAdsProgress(0);
      setAssetsProgress(0);
      setEmailProgress(0);
      setFormProgress(0);
      setPageProgress(0);
      setTxtMsgProgress(0);
      setWhatsAppProgress(0);
    }
  };

  const getCustomerSupportData = async () => {
    try {
      const response = await _getCustomerSupportProgressData();
      setCustomerSupport(response);
    } catch (error) {
      console.log(error);
      setCustomerSupport(0);
    }
  };

  const getSetupData = async () => {
    try {
      const response = await _getSetUpProgressData();
      setSetupSupport(response);
    } catch (error) {
      console.log(error);
      setSetupSupport(0);
    }
  };

  const getSalesData = async () => {
    try {
      const response = await _getSalesProgressData();
      setTotalProgressSales(response?.total ?? 0);
      setDealsProgress(response?.deals ?? 0);
      setFormSalesProgress(response?.forms ?? 0);
      setMeetingProgress(response?.meetings ?? 0);
      setTaskProgress(response?.tasks ?? 0);
      setTicketProgress(response?.tickets ?? 0);
    } catch (error) {
      console.log(error);
      setTotalProgressSales(0);
      setDealsProgress(0);
      setFormSalesProgress(0);
      setMeetingProgress(0);
      setTaskProgress(0);
      setTicketProgress(0);
    }
  };

  useEffect(() => {
    if (selectedMenuItem === "Marketing") {
      getMarketingData();
    } else if (selectedMenuItem === "Customer Service") {
      getCustomerSupportData();
    } else if (selectedMenuItem === "Website (CMS)") {
      getSetupData();
    } else if (selectedMenuItem === "Sales") {
      getSalesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="cardMarket">
      <div className="innerCard">
        <div className="gapBetIconTxt">
          <img src={marketTool} alt="marketTool" />
          {selectedMenuItem === "Marketing" && <p> Marketing tools progress</p>}
          {selectedMenuItem === "Sales" && <p> Sales tools progress</p>}
          {selectedMenuItem === "Customer Service" && (
            <p> Customer tools progress</p>
          )}
          {selectedMenuItem === "Website (CMS)" && (
            <p> Customer tools progress</p>
          )}
        </div>

        <div className="text-center">
          <div className="progressStyle" style={progressStyle}>
            {selectedMenuItem === "Marketing" && (
              <Circle percent={totalProgressMarketing} strokeColor="#00AB86" />
            )}
            {selectedMenuItem === "Sales" && (
              <Circle percent={totalProgressSales} strokeColor="#00AB86" />
            )}
            {selectedMenuItem === "Customer Service" && (
              <Circle percent={customerSupport?.total} strokeColor="#00AB86" />
            )}
            {selectedMenuItem === "Website (CMS)" && (
              <Circle percent={setupSupport?.total} strokeColor="#00AB86" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstCard;
