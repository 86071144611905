import Axios from "axios";

export const access_token = localStorage.getItem("token");
const axios = Axios.create({
  headers: {
    Authorization: `Bearer ${access_token}`,
  },
});

export default axios;
