import React, { useContext } from "react";
import { DashboardContext } from "../CreateNewDashboard";
interface ISlot {
  img: string;
  title: string;
  content: string;
  tag?: string;
}
const Slots: React.FC<ISlot> = ({ img, content, title, tag }: ISlot) => {
  const { handleModalOpenClose } = useContext(DashboardContext);

  return (
    <div
      className="create_new_container"
      onClick={() => handleModalOpenClose(tag)}
    >
      <div className="imageContainer">
        <img src={img} alt="Temp01" />
      </div>
      <div className="content">
        <div className="title">{title}</div>
        <div className="description">{content}</div>
      </div>
    </div>
  );
};

export default Slots;
