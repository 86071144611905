import React from "react";
import { Container, Content, Header } from "rsuite";

import ReportHeaderComponent from "src/components/headers/ReportHeader";
// import SidebarComponent from "src/components/Sidebar/SidebarComponent";
import ReportTable from "src/components/ReportTable/ReportTable";

import "./styles/report.scss";

const Report: React.FC = () => {
  const [active, setActive] = React.useState<string | null>("my-reports");
  return (
    <>
      <Content className="main_content">
        <Container>
          <Header>
            <ReportHeaderComponent setActive={setActive} active={active} />
          </Header>
          <div className="__report_container">
            {/* <SidebarComponent /> */}
            <ReportTable />
          </div>
        </Container>
      </Content>
    </>
  );
};

export default Report;
