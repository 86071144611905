/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { formatDate } from "./helpers/dashboards";
import { Col, Icon, Input, InputGroup, Modal, Row, SelectPicker } from "rsuite";
import {
  DefaultDashboard,
  RestoreDashboard,
  deleteDashboard,
  getDashboardLists,
  getDeletedDashboardLists,
} from "src/common/analytics-and-dashboard-api-calls";
import { Button, Toggle } from "rsuite";

import "./dashboardTable.scss";
import toast from "react-hot-toast";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      borderRadius: "0px",
      backgroundColor: "white",
    },
  },
  headCells: {
    style: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
};

interface DashboardTableProps {
  pass: string;
}

const DashboardTable: React.FC<DashboardTableProps> = ({ pass }) => {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSoftDelete, setIsSoftDelete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredList, setFilteredList] = useState<any>([]);
  const [publicState, setPublicState] = useState<null | string>(null);
  const [activeState, setActiveState] = useState<null | string>(null);
  const [deletedData, setDeletedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowForDelete, setRowForDelete] = useState(0);

  const handleDeleteDashboard = async (id: number, isHard: boolean) => {
    setIsLoading(true);
    const response = await deleteDashboard(id, isHard);
    if (!response.error) {
      getDashboardInfo();
      getDeletedDashboardInfo();
      toast.success("Dashboard has been deleted.");
      setIsLoading(false);
      setIsDeleteModalOpen(false);
    } else {
      setIsLoading(false);
      toast.error("Failed to delete dashboard.");
    }
  };

  const handleRestoreDashboard = async (id: any) => {
    setIsLoading(true);
    const response = await RestoreDashboard(id);
    if (!response.error) {
      getDeletedDashboardInfo();
      toast.success("Dashboard has been Restored.");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      getDeletedDashboardInfo();
      toast.error("Currently service is down, please wait");
    }
  };

  const handleDefaultDashboardSetup = async (id: any) => {
    const response = await DefaultDashboard(id);
    if (!response.error) {
      getDashboardInfo();
      toast.success("Dashboard has been Selected as Default");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error(
        "Can not assign as default dashboard, please try after sometime"
      );
    }
  };

  const handlePublicChange = (value: string) => {
    setPublicState(value);
  };

  const handleActiveChange = (value: string) => {
    setActiveState(value);
  };

  function mapLabelToValue(label: string) {
    switch (label) {
      case "Yes":
        return true;
      case "No":
        return false;

      default:
        return label;
    }
  }

  function mapLabelToValueActive(label: string) {
    switch (label) {
      case "Yes":
        return true;
      case "No":
        return false;

      default:
        return label;
    }
  }

  const publicData = ["Yes", "No"].map((item) => ({
    label: item,
    value: mapLabelToValue(item),
  }));

  const activeData = ["Yes", "No"].map((item) => ({
    label: item,
    value: mapLabelToValueActive(item),
  }));

  const columnsForDashboardList = [
    {
      name: "Default",
      sortable: true,
      selector: (row: any) =>
        row.is_default === true ? (
          <div className="ml-2">
            <AiFillStar fill="green" className="star-icon-default" />
          </div>
        ) : (
          <div
            className="ml-2 hover-effect"
            onClick={() => {
              handleDefaultDashboardSetup(row.id);
            }}
          >
            <AiOutlineStar className="star-icon-default" />
          </div>
        ),
      width: "110px",
    },
    {
      name: "ID",
      sortable: true,
      cell: (row: any) => <div className="ml-1 statusText">{row.id}</div>,
      selector: "id",
      width: "80px",
    },
    {
      name: "Dashboard Name",
      sortable: true,
      selector: (row: any) => {
        return (
          <div
            className="dashboard_list_names_custom nameProperty"
            onClick={() => {
              history.push(`/dashboard/${row.id}`);
            }}
          >
            &nbsp;{row.dashboard_name}
          </div>
        );
      },
      width: "280px",
    },
    {
      name: "Created By",
      sortable: true,
      selector: "created_by",
      conditionalCellStyles: [
        {
          when: (cell: boolean) => cell === true,
          style: {
            color: "green",
          },
        },
        {
          when: (cell: boolean) => cell === false,
          style: {
            color: "red",
          },
        },
      ],
      cell: (row: any) =>
        row.created_by == null ? (
          <span className="statusText" style={{ marginLeft: "8px" }}>
            -
          </span>
        ) : (
          <span className="statusText" style={{ marginLeft: "8px" }}>
            {" "}
            {row.created_by}{" "}
          </span>
        ),
      width: "150px",
    },
    {
      name: "Create At",
      selector: "created_datetime",
      sortable: true,
      format: (row: any) => formatDate(row.created_datetime),
      width: "280px",
    },
    {
      name: "Is Public",
      selector: "is_public",
      sortable: true,
      conditionalCellStyles: [
        {
          when: (cell: boolean) => cell === true,
          style: {
            color: "green",
          },
        },
        {
          when: (cell: boolean) => cell === false,
          style: {
            color: "red",
          },
        },
      ],
      cell: (row: any) =>
        row.is_public ? (
          <span className="statusText" style={{ marginLeft: "8px" }}>
            Yes
          </span>
        ) : (
          <span className="statusText" style={{ marginLeft: "8px" }}>
            No
          </span>
        ),
      width: "180px",
    },
    {
      name: "Is Active",
      selector: "is_active",
      sortable: true,
      conditionalCellStyles: [
        {
          when: (cell: boolean) => cell === true,
          style: {
            color: "green",
          },
        },
        {
          when: (cell: boolean) => cell === false,
          style: {
            color: "red",
          },
        },
      ],
      cell: (row: any) => (
        <div style={{ marginLeft: "8px" }}>
          <Toggle defaultChecked={row.is_active} />
        </div>
      ),
      width: "180px",
    },
    {
      name: "Delete",
      sortable: true,
      cell: (row: any) => (
        <Button
          className="ml-1 deleteBtn"
          appearance="ghost"
          color="red"
          onClick={() => {
            setRowForDelete(row.id);
            setIsSoftDelete(false);
            // setIsLoading(true);
            setIsDeleteModalOpen(true);

            // handleDeleteDashboard(row.id, false);
          }}
          disabled={isLoading}
        >
          Delete
        </Button>
      ),
      width: "120px",
    },
  ];

  const columnsForDeletedDashboardList = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      cell: (row: any) => <div className="ml-1 statusText">{row.id}</div>,
      width: "80px",
    },
    {
      name: "Dashboard Name",
      selector: "dashboard_name",
      sortable: true,
      width: "380px",
      cell: (row: any) => (
        <div className="ml-1 nameProperty">{row.dashboard_name}</div>
      ),
    },

    {
      name: "Created By",
      selector: "created_by",
      sortable: true,
      conditionalCellStyles: [
        {
          when: (cell: boolean) => cell === true,
          style: {
            color: "green",
          },
        },
        {
          when: (cell: boolean) => cell === false,
          style: {
            color: "red",
          },
        },
      ],
      cell: (row: any) =>
        row.created_by == null ? (
          <span className="ml-2 statusText">-</span>
        ) : (
          <span className="ml-1 statusText"> {row.created_by} </span>
        ),
      width: "180px",
    },
    {
      name: "Deleted By",
      selector: "deleted_by",
      sortable: true,
      conditionalCellStyles: [
        {
          when: (cell: boolean) => cell === true,
          style: {
            color: "green",
          },
        },
        {
          when: (cell: boolean) => cell === false,
          style: {
            color: "red",
          },
        },
      ],
      cell: (row: any) =>
        row.deleted_by == null ? (
          <span className="ml-2 statusText">-</span>
        ) : (
          <span className="ml-1 statusText"> {row.deleted_by} </span>
        ),
      width: "180px",
    },
    {
      name: "Permanent Delete",
      selector: "id",
      cell: (row: any) => (
        <Button
          appearance="ghost"
          color="red"
          className="ml-1 deleteBtn"
          onClick={() => {
            setRowForDelete(row.id);
            setIsSoftDelete(true);
            setIsDeleteModalOpen(true);
            // handleDeleteDashboard(row.id, true);
          }}
          disabled={isLoading}
        >
          Permanent Delete
        </Button>
      ),
      width: "180px",
    },
    {
      name: "Restore",
      selector: "id",
      cell: (row: any) => (
        <Button
          appearance="ghost"
          className="ml-1 editBtn"
          color="green"
          onClick={() => {
            handleRestoreDashboard(row.id);
          }}
          disabled={isLoading}
        >
          Restore
        </Button>
      ),
      width: "150px",
    },
  ];

  const getDashboardInfo = async () => {
    const response = await getDashboardLists();
    setData(response.data);
    setFilteredList(response.data);
  };

  const getDeletedDashboardInfo = async () => {
    const response = await getDeletedDashboardLists();
    setDeletedData(response.data.response);
  };

  useEffect(() => {
    if (publicState !== null && activeState !== null) {
      const filtered = data.filter(
        (_d: any) =>
          _d?.is_active === activeState && _d?.is_public === publicState
      );
      setFilteredList(filtered);
    } else if (activeState !== null) {
      const filtered = data.filter((_d: any) => _d?.is_active === activeState);
      setFilteredList(filtered);
    } else if (publicState !== null) {
      const filtered = data.filter((_d: any) => _d?.is_public === publicState);
      setFilteredList(filtered);
    } else {
      setFilteredList(data);
    }
  }, [publicState, activeState]);

  useEffect(() => {
    const filtered = data.filter((dataIndex: any) =>
      dataIndex?.dashboard_name
        ?.toLowerCase()
        .includes(searchText?.toLowerCase())
    );
    setFilteredList(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (pass === "my-reports") {
      getDashboardInfo();
    } else getDeletedDashboardInfo();
  }, [pass]);

  return (
    <>
      <div className="__DashboardTable_container">
        <div style={{ padding: "16px 0px" }}>
          {pass === "my-reports" && (
            <Row>
              <Col lg={3} md={5} sm={5}>
                <SelectPicker
                  data={publicData}
                  searchable={false}
                  placeholder="Public"
                  onChange={handlePublicChange}
                  style={{ width: 224 }}
                />
              </Col>

              <Col lg={3} md={5} sm={5}>
                <SelectPicker
                  data={activeData}
                  searchable={false}
                  placeholder="Active"
                  onChange={handleActiveChange}
                  style={{ width: 224 }}
                />
              </Col>

              <Col lg={6} md={2} sm={2}></Col>
              <Col lg={4} md={2} sm={2}></Col>
              <Col lg={4} md={2} sm={2}></Col>

              <Col lg={4} md={8} sm={8}>
                <InputGroup className="searchInput">
                  <Input
                    placeholder="Search"
                    value={searchText}
                    onChange={(value) => setSearchText(value)}
                  />
                  <InputGroup.Button>
                    <Icon icon="search" />
                  </InputGroup.Button>
                </InputGroup>
              </Col>
            </Row>
          )}
        </div>

        <div className="dataTable">
          {pass === "my-reports" ? (
            <DataTable
              columns={columnsForDashboardList as any}
              data={filteredList}
              pagination={true}
              customStyles={customStyles}
              persistTableHead={true}
            />
          ) : (
            <DataTable
              columns={columnsForDeletedDashboardList as any}
              data={deletedData}
              pagination={true}
              customStyles={customStyles}
              persistTableHead={true}
            />
          )}
        </div>
      </div>

      <Modal
        className="__generic_modal"
        backdrop={"static"}
        show={isDeleteModalOpen}
        onHide={() => setIsDeleteModalOpen(false)}
      >
        <Modal.Header className="modal-header-custom">
          <Modal.Title className="title">Delete Dashboard</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            overflow: "auto",
            maxHeight: "100px !important",
            padding: "25px",
            marginTop: 0,
          }}
        >
          {/* <h4>Are You Sure?</h4> */}
          <p>Are you sure you want to delete Dashboard?</p>
        </Modal.Body>
        <Modal.Footer style={{ padding: "20px" }}>
          <Button
            color="red"
            appearance="primary"
            onClick={() => handleDeleteDashboard(rowForDelete, isSoftDelete)}
          >
            Delete
          </Button>

          <Button
            color="green"
            appearance="primary"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DashboardTable;
