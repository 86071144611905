import "./drawer-body.scss";
const DrawerCommonBody = () => {
  return (
    <div>
      <p className="basic_p">
        Import your contacts, invite teammates, and understand Roboket
        properties
        <span className="basic_p_span">2 of 5 complete</span>
      </p>
    </div>
  );
};

export default DrawerCommonBody;
