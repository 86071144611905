import React from "react";
import "./createFromTemplate.scss";
// import Temp01 from "../../assets/images/Temp01.png";
// import Temp02 from "../../assets/images/Temp02.png";
// import Temp03 from "../../assets/images/Temp03.png";
// import Temp04 from "../../assets/images/Temp04.png";
// import Temp05 from "../../assets/images/Temp05.png";
// import Temp06 from "../../assets/images/Temp06.png";
// import Temp07 from "../../assets/images/Temp07.png";
// import Temp08 from "../../assets/images/Temp08.png";

import Slots from "./Slots";
import {
  slots0,
  slots1,
  slots2,
  slots3,
  slots4,
  slots5,
  slots6,
  slots7,
  slots8,
} from "src/assets";

const CreateFromTemplate: React.FC = () => {
  return (
    <div className="CreateFromTemplate">
      <div className="title">Create dashboards from templates</div>
      <div className="slots_container">
        <Slots
          img={slots0}
          title={"Sales"}
          content={
            "Start with a dashboard of 6 reports that show the kind of requests your team receives over chat.Includes 6 default reports"
          }
          tag="contact"
        />
        <Slots
          img={slots1}
          title={"Communications"}
          content={
            "Start with a dashboard of 6 reports that show the kind of requests your team receives over chat. Includes 6 default reports"
          }
          tag="company"
        />
        <Slots
          img={slots2}
          title={"Marketing"}
          content={
            "Start with a dashboard of 6 reports that show the kind of requests your team receives over chat.Includes 6 default reports"
          }
          tag="ticket"
        />
        <Slots
          img={slots3}
          title={"Customer support"}
          content={
            "Start with a dashboard of 6 reports that show the kind of requests your team receives over chat.Includes 6 default reports"
          }
          tag="contact"
        />
        <Slots
          img={slots4}
          title={"Service Overview"}
          content={`Start with a dashboard of 6 reports that show the kind of requests your team receives over chat. Includes 6 default reports`}
          tag="contact"
        />
        <Slots
          img={slots5}
          title={"Marketing Channel Performance"}
          content={`Start with a dashboard of 6 reports that show the kind of requests your team receives over chat. Includes 6 default reports`}
          tag="ticket"
        />
        <Slots
          img={slots6}
          title={"Sales Manager"}
          content={`Start with a dashboard of 6 reports that show the kind of requests your team receives over chat. Includes 6 default reports`}
          tag="company"
        />
        <Slots
          img={slots7}
          title={"Sales Opportunity Review"}
          content={`Start with a dashboard of 6 reports that show the kind of requests your team receives over chat. Includes 6 default reports`}
          tag="company"
        />
        <Slots
          img={slots8}
          title={"Sales Rep"}
          content={`Start with a dashboard of 6 reports that show the kind of requests your team receives over chat. Includes 6 default reports`}
          tag="company"
        />
      </div>
    </div>
  );
};

export default CreateFromTemplate;
