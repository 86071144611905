import "./column.scss";
import calenderIcon from "../../../../assets/svgs/calender.svg";
import mailIcon from "../../../../assets/svgs/mail.svg";

export const stringToSubstringSpreed = (value: string, count: number) => {
  if (value.length > count) return value.substring(0, count - 1) + " . . .";
  return value;
};

export const columnsForContactVsRecent = [
  {
    name: "CONTACT",
    cell: (row: any) => {
      return <div className="nameProperty"> {row.name ? row.name : "-"} </div>;
    },
  },
  {
    name: "CREATE DATE",
    cell: (row: any) => {
      return (
        <div className="dateProperty">
          {" "}
          <img
            height="17px"
            width="17.054px"
            src={calenderIcon}
            alt="calender icon"
          />{" "}
          {row.date ? row.date : "-"}
        </div>
      );
    },
  },
  {
    name: "CONTACT SOURCES",
    cell: (row: any) => {
      return (
        <div className="dateProperty">
          {" "}
          <img
            height="17px"
            width="17.054px"
            src={mailIcon}
            alt="mail icon"
          />{" "}
          {row.source ? row.source : "-"}
        </div>
      );
    },
    selector: (row: any) => (row.source ? row.source : "-"),
  },
];

export const columnsForCompanyVsRecent = [
  {
    name: "COMPANY",
    cell: (row: any) => {
      return <div className="nameProperty"> {row.name ? row.name : "-"} </div>;
    },
  },
  {
    name: "CREATE DATE",
    cell: (row: any) => {
      return (
        <div className="dateProperty">
          {" "}
          <img
            height="17px"
            width="17.054px"
            src={calenderIcon}
            alt="calender icon"
          />{" "}
          {row.date ? row.date : "-"}
        </div>
      );
    },
  },
];
