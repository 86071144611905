import { useProgress } from "src/common/components/progress/ProgressContext";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";
import { Progress } from "rsuite";
import { ViewRoutes } from "src/common/view-routes";

const SetUpBasicBodyForSales = () => {
  const { Line } = Progress;
  const {
    totalProgressSales,
    dealsProgress,
    formSalesProgress,
    meetingProgress,
    taskProgress,
    ticketProgress,
  } = useProgress();

  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="line_progress">
        <Line percent={totalProgressSales} strokeColor="#319F69" />
      </div>

      <div className="drawerSubTitle">
        <div>
          {" "}
          <p
            onClick={() => {
              if (taskProgress !== 100) {
                window.location.href =
                  ViewRoutes.Tasks + "?getting-started=true";
              }
            }}
            className={taskProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Task
          </p>
          <p className="title_p">
            Efficiently manage your sales workflow by creating tasks within
            Roboket to track and organize key actions and deadlines.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (ticketProgress !== 100) {
                window.location.href =
                  ViewRoutes.Tickets + "?getting-started=true";
              }
            }}
            className={ticketProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Ticket
          </p>

          <p className="title_p">
            Streamline customer support and issue resolution by generating
            tickets within Roboket to prioritize and address customer inquiries
            and concerns effectively
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (dealsProgress !== 100) {
                window.location.href =
                  ViewRoutes.Conversion + "?getting-started=true";
              }
            }}
            className={dealsProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Deals
          </p>
          <p className="title_p">
            Drive revenue growth by creating and managing sales deals within
            Roboket to track progress, forecast revenue, and close deals
            efficiently.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (formSalesProgress !== 100) {
                window.location.href =
                  ViewRoutes.RootSub + "/inbox/connect-channel/form";
              }
            }}
            className={formSalesProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Form
          </p>
          <p className="title_p">
            Customize and deploy forms within Roboket to gather valuable
            customer information and streamline data collection for sales and
            marketing efforts.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (meetingProgress !== 100) {
                window.location.href =
                  ViewRoutes.Meetings + "/connect-calendar";
              }
            }}
            className={meetingProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Meeting
          </p>
          <p className="title_p">
            Coordinate and schedule meetings seamlessly within Roboket to
            facilitate communication and collaboration among your sales team and
            clients, ensuring productive interactions and successful outcomes.
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default SetUpBasicBodyForSales;
