import React from "react";
import { Button, Container, Content, Header } from "rsuite";
import "./styles/landing-page.scss";
import createDashboardIcon from "../assets/svgs/createDashboard.svg";
import LandingPageDashboard from "src/common/components/landing-age-dashboard/Landing-page-dashboard";

const LandingPage = () => {
    const handleOnClick = () => { };
    return (
        <Content className="main-content">
            <Container>
                <Header>
                    <div className="inbox-top-section shadow-sm">
                        <div className="top-elements-wrapper">
                            <div className="inner __dashboard_lists__title">
                                <div className="inner __dashboard_lists__title">
                                    <p className="headerTitle"> Welcome to Roboket</p>
                                </div>
                            </div>
                            <div className="actions">
                                <Button className="green-button" onClick={handleOnClick}>
                                    <img src={createDashboardIcon} alt="createDashboardIcon" />{" "}
                                    Create Dashboard
                                </Button>
                            </div>
                        </div>
                    </div>
                </Header>
                <LandingPageDashboard />
            </Container>
        </Content>
    );
};

export default LandingPage;
