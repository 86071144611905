import React from "react";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";
import { useProgress } from "src/common/components/progress/ProgressContext";
import { Progress } from "rsuite";
import { ViewRoutes } from "src/common/view-routes";
const SetUpBasicBodyForMarketing = () => {
  const {
    totalProgressMarketing,
    campaignProgress,
    assetsProgress,
    emailProgress,
    formProgress,
    pageProgress,
    txtMsgProgress,
    whatsAppProgress,
    adsProgress,
  } = useProgress();
  const { Line } = Progress;
  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="line_progress">
        <Line percent={totalProgressMarketing} strokeColor="#319F69" />
      </div>

      <div className="drawerSubTitle">
        <div>
          <p
            onClick={() => {
              if (campaignProgress !== 100) {
                window.location.href =
                  ViewRoutes.Create_Campaign_URL + "/create-campaign";
              }
            }}
            className={campaignProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Campaigns
          </p>
          <p className="title_p">Create your first campaign in Roboket</p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (assetsProgress !== 100) {
                window.location.href =
                  ViewRoutes.Create_Campaign_URL + "/assets/new";
              }
            }}
            className={assetsProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Assets
          </p>
          <p className="title_p">
            Generate digital content for your campaigns within Roboket.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (formProgress !== 100) {
                window.location.href =
                  ViewRoutes.Create_Campaign_URL + "/forms/new";
              }
            }}
            className={formProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Forms
          </p>
          <p className="title_p">
            Design and build custom forms to collect valuable data and engage
            with your audience effectively using Roboket.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (pageProgress !== 100) {
                window.location.href =
                  ViewRoutes.Create_Campaign_URL + "/pages/new";
              }
            }}
            className={pageProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Landing pages
          </p>
          <p className="title_p">
            Craft captivating landing pages tailored to your campaigns within
            Roboket to drive conversions and enhance user experience.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (emailProgress !== 100) {
                window.location.href =
                  ViewRoutes.Create_Campaign_URL + "/pages/new";
              }
            }}
            className={emailProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Emails
          </p>
          <p className="title_p">
            Compose and design captivating email campaigns seamlessly within
            Roboket to reach and engage your audience effectively.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (txtMsgProgress !== 100) {
                window.location.href =
                  ViewRoutes.Create_Campaign_URL + "/sms/new";
              }
            }}
            className={txtMsgProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Text Messages
          </p>
          <p className="title_p">
            Craft personalized text messages easily within Roboket to connect
            with your audience on a more immediate and intimate level.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (whatsAppProgress !== 100) {
                window.location.href =
                  ViewRoutes.Create_Campaign_URL + "/whatsapp/new";
              }
            }}
            className={whatsAppProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Whatsapp Messages
          </p>
          <p className="title_p">
            Design and send engaging WhatsApp messages directly from Roboket to
            interact with your audience on their preferred messaging platform.
          </p>
          <hr />
        </div>

        <div className="card_top">
          <p
            onClick={() => {
              if (adsProgress !== 100) {
                window.location.href = ViewRoutes.Ads + "/create-native-ads";
              }
            }}
            className={adsProgress === 100 ? "titleLineThrough" : "title"}
          >
            Create Ads
          </p>
          <p className="title_p">
            Develop targeted advertisements efficiently within Roboket to reach
            your desired audience across various channels and drive engagement
            and conversions.
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default SetUpBasicBodyForMarketing;
