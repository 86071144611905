// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgFlex {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.imgFlex .serviceDown {
  color: #767676;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 307px;
  height: 56px;
}
.imgFlex .imgMargin {
  margin-top: 58px;
}

.reloadBtn {
  background-color: #1d3d70;
  color: white;
  padding: 8px 23px;
}

.reloadBtn:hover {
  background-color: #1d3d70;
  color: white;
  padding: 8px 23px;
}`, "",{"version":3,"sources":["webpack://./src/common/components/hasErrorOnDashboard/hasErrorOnDashboard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;AACF;AAAE;EACE,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;;AACA;EACE,yBAAA;EACA,YAAA;EACA,iBAAA;AAEF;;AAAA;EACE,yBAAA;EACA,YAAA;EACA,iBAAA;AAGF","sourcesContent":[".imgFlex {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  gap: 10px;\n  .serviceDown {\n    color: #767676;\n    text-align: center;\n    font-family: Poppins;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 28px;\n    width: 307px;\n    height: 56px;\n  }\n  .imgMargin {\n    margin-top: 58px;\n  }\n}\n.reloadBtn {\n  background-color: #1d3d70;\n  color: white;\n  padding: 8px 23px;\n}\n.reloadBtn:hover {\n  background-color: #1d3d70;\n  color: white;\n  padding: 8px 23px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
