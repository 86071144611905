import React from "react";
import { Button } from "rsuite";
import statusImage from '../../../assets/svgs/statusImage.svg'

function StatusPage(props: any) {
  const { fullName, selectedMenuItem } = props;
  return (
    <div className="parentContainer">
      <>
        <p className="hiClass">Hi, {fullName} </p>
        {selectedMenuItem === "Status page" && (
          <p className="welcomeTxt">Status page</p>
        )}
      </>
      <div className="txtPara">
      <p>
      Welcome to Roboket home for real-time information on system status and security. Here you'll find live and historical data on system 
performance. If there are any interruptions in service, a note will be posted here.
      </p>

      <p>
      Roboket status page itself.  Is there anything specific you'd like to know about HubSpot's system status or security?  The passage you provided indicates everything is currently operational, but I can help you navigate the full status page if you'd like to see more details or historical data.
      </p>

      <Button className="callBtn">Check Status</Button>

      <div className="text-center" style={{marginTop:'60px'}}>
        <img className="imgStatus" src={statusImage} alt="statusImage"/>
      </div>
      </div>
    </div>
  );
}

export default StatusPage;
