import React from "react";
import "./drawer-body.scss";
import DrawerCommonBody from "./DrawerCommonBody";

function GrowAudienceBody() {
  return (
    <div className="basic_parent">
      <DrawerCommonBody />

      <div className="drawerSubTitle">
        <div>
          <p className="title">Publish and manage your pages</p>
          <p className="title_p">
            Create, edit, publish and analyze webpages using our drag-and-drop
            editor and publishing tools.
          </p>
          <hr />
        </div>

        <div>
          <p className="title">Connect a custom domain</p>
          <p className="title_p">
            By default, your site will be given a HubSpot domain, but you can
            also connect it to any domain that you own. Free security
            certificate (SSL) included!
          </p>
          <hr />
        </div>

        <div>
          <p className="title">Create your blog</p>
          <p className="title_p">
            Start with your blog's title and URL information, then build your
            first post. Once you're done with the basics, you can import an
            existing blog.
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default GrowAudienceBody;
