import { useEffect, useState, createContext } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import { Footer, Header } from "rsuite";

import { setCurrentUser } from "./common/redux/user/user.actions";
import {
  setCurrentAccount,
  setCurrentUserId,
  setIsAuthenticated,
  setUserPermission,
  setUserToken,
} from "./common/redux/authentication/authentication.actions";
import {
  checkLogin,
  getAccount,
  getUserId,
  setLogin,
  setUserId,
} from "./common/constants";
import { GetUserById } from "./common/api-common";
import { getUserPermissions } from "./common/utils/Utils";
import keycloak from "./keycloak";

import Navbar from "./common/components/navbar/navbar.component";

import CreateNewDashboard from "./pages/CreateNewDashboard";
import Dashboard from "./pages/Dashboard";
import Report from "./pages/Report";
import CreateReport from "./pages/CreateReport";
import DashboardLists from "./pages/DashboardLists";
import { FooterComponent } from "./common/components/footer/footer.component";
import LoadingComponent from "./common/components/loading/Loading";
import NotAllowed from "./pages/NotAllowed";

import NotFound from "./components/NotFound/NotFound";
import Bouncer from "./pages/Bouncer";

/** Private Routing */
import PrivateRouteDashboard from "./routes/PrivateRouteDashboard";

import PrivateRouteReport from "./routes/PrivateRouteReport";
import ReportBouncer from "./pages/ReportBouncer";
import { ApiRoutes } from "./common/routes";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "./App.scss";
// import FailedResponse from "./common/components/failedResponse/FailedResponse";
import Onboard from "./pages/Onboard";
import LandingPage from "./pages/Landing-page";
import GridTest from "./common/components/__test__/GridText";
import { ProgressProvider } from "./common/components/progress/ProgressContext";

export const GlobalContext = createContext<any>({});

function App(props: any) {
  const {
    setIsAuthenticated,
    setCurrentUserId,
    setUserToken,
    setCurrentAccount,
    authentication,
    setCurrentUser,
    setUserPermission,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [authenticate, setAuthenticate] = useState(false);
  // const [checkApiError, setCheckApiError] = useState(false);
  const params = props.location.pathname;
  const userId = getUserId();

  const loginCheck = () => {
    if (!checkLogin()) {
      setLogin();
      window.location.reload();
    }
  };

  const checkAuthorize = () => {
    keycloak
      .init({
        onLoad: "login-required",
        promiseType: "native",
      })
      .then(async function (authenticated: any) {
        localStorage.setItem("token", keycloak.token);
        await getAccount(keycloak.subject);
        setCurrentUserId(keycloak.subject);
        setUserToken(keycloak.token);
        setCurrentAccount(keycloak.sessionId);
        setUserId(keycloak.subject);
        setAuthenticate(authenticated);
        loginCheck();
      })
      .catch(function () {
        console.log("failed to initialize");
        setAuthenticate(false);
      });
  };

  const getUserInfo = async (userId: string) => {
    await GetUserById(userId)
      .then((response: any) => {
        const userInfo = response.data.userDetails;
        localStorage.setItem("email", userInfo.email);
        const user = {
          userId: userInfo.userId,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email,
          avater: ApiRoutes.USER_IMAGE + userInfo.userId,
        };
        setCurrentUser(user);
      })
      .catch((error) => {
        // setCheckApiError(true);
        console.log(error);
      });
  };

  const fetchUserPermissions = async () => {
    try {
      const permissions = await getUserPermissions();
      setUserPermission(permissions);
    } catch (error: any) {
      // setCheckApiError(true);
    }
  };

  if (authentication.isAuthenticated) {
    checkAuthorize();
    setIsAuthenticated(false);
  }

  useEffect(() => {
    if (
      params !== "/verify-email" &&
      params !== "/invited-user" &&
      params !== "/user/account-confirmation"
    ) {
      checkAuthorize();
      getUserInfo(userId);
      fetchUserPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  if (authenticate)
    return (
      <GlobalContext.Provider
        value={{ isLoading: isLoading, setIsLoading: setIsLoading }}
      >
        <div className="container-area-main">
          <Header>
            <Navbar />
          </Header>
          <Switch>
            <Route exact path={"/"}>
              <Bouncer />
            </Route>

            <PrivateRouteDashboard path={"/create-dashboard"}>
              <CreateNewDashboard />
            </PrivateRouteDashboard>

            <Route path={"/dashboard-lists"}>
              <DashboardLists />
            </Route>

            <Route exact path={"/getting-started"}>
            <ProgressProvider>
              <Onboard />
              </ProgressProvider>
            </Route>

            <Route exact path={"/landing-page"}>
              <LandingPage />
            </Route>

            <PrivateRouteDashboard path={"/dashboard/:id"}>
              <Dashboard />
            </PrivateRouteDashboard>

            <PrivateRouteReport path={"/report-creation"}>
              <CreateReport />
            </PrivateRouteReport>

            <PrivateRouteReport path={"/report-lists"}>
              <Report />
            </PrivateRouteReport>

            <PrivateRouteReport path={"/create-report"}>
              <ReportBouncer />
            </PrivateRouteReport>

            <Route path={"/permission-not-found"}>
              <NotAllowed />
            </Route>

            <Route path={"/t/e/s/t"}>
              <GridTest />
            </Route>
            <Route path={"*"}>
              <NotFound />
            </Route>
          </Switch>
          <Footer>
            <FooterComponent />
          </Footer>
        </div>
      </GlobalContext.Provider>
    );
  else
    return (
      <>
        <LoadingComponent message="Loading" />
      </>
    );
}

const mapStateToProps = ({ authentication }: any) => ({
  authentication,
});

const mapDispatchToProps = (dispatch: any) => ({
  setIsAuthenticated: (authentication: boolean) =>
    dispatch(setIsAuthenticated(authentication)),
  setCurrentUserId: (userId: string) => dispatch(setCurrentUserId(userId)),
  setUserToken: (token: string) => dispatch(setUserToken(token)),
  setCurrentAccount: (account: string) => dispatch(setCurrentAccount(account)),
  setCurrentUser: (user: string) => dispatch(setCurrentUser(user)),
  setUserPermission: (permission: string[]) =>
    dispatch(setUserPermission(permission)),
});

const AppRouter = withRouter(App);
export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
