import React from "react";
import { Loader } from "rsuite";
import "./loading.scss";

interface ILoadingComponent {
  message?: string;
  resizableCardLoader?: Boolean;
}

const LoadingComponent: React.FC<ILoadingComponent> = ({
  message,
  resizableCardLoader,
}: ILoadingComponent) => {
  return (
    <div
      className={
        resizableCardLoader !== true
          ? "loadingComponent_container"
          : "loadingComponent_container_for_single_card"
      }
    >
      <Loader />
      <div>{message ? message : "Loading..."}</div>
    </div>
  );
};

export default LoadingComponent;
