// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.__vl_single_card_container {
  display: flex;
  flex-direction: column;
  border: 1px solid #bcdcd5;
  height: 100%;
  min-height: 600px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}
.__vl_single_card_container:hover {
  box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
}

.__vl_single_card_container_dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 80px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}
.__vl_single_card_container_dashboard:hover {
  box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
}

.single_card_data {
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/GraphANdChartsSnippets/SingleCard/singleCard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,gCAAA;AACF;AACE;EACE,8CAAA;AACJ;;AAGA;EACE,aAAA;EACA,sBAAA;EAEA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,gCAAA;AADF;AAGE;EACE,8CAAA;AADJ;;AAKA;EAEE,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAEA,aAAA;EACA,uBAAA;AAJF","sourcesContent":[".__vl_single_card_container {\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #bcdcd5;\n  height: 100%;\n  min-height: 600px;\n  background-color: white;\n  transition: all 0.3s ease-in-out;\n\n  &:hover {\n    box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);\n  }\n}\n\n.__vl_single_card_container_dashboard {\n  display: flex;\n  flex-direction: column;\n\n  height: 100%;\n  min-height: 80px;\n  background-color: white;\n  transition: all 0.3s ease-in-out;\n\n  &:hover {\n    box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);\n  }\n}\n\n.single_card_data {\n  // color: #1d3d70;\n  font-family: Poppins;\n  font-size: 25px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 35px;\n\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
