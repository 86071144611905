import { Panel, Tag } from "rsuite";

enum NotificationType {
  TicketAssign = "Ticket_Assigned",
  ConversionAssigned = "Conversion_Assigned",
  EmailThreadAssigned = "Email_Thread_Assigned",
  TaskDueSoon = "Task_Due_Soon",
  ChatBotConnected = "Chat_Bot_Connected",
  ChatBotDisconnected = "Chat_Bot_Disconnected",
  WebChatAssigned = "Web_Chat_Assigned",
  ChatBotTrained = "Chat_Bot_Trained",
  ContactOwnerAssigned = "Contact_Owner_Assigned",
  CompanyOwnerAssigned = "Company_Owner_Assigned",
  ImportCompanyFromFile = "Import_Company_From_File",
  ImportContactFromFile = "Import_Contact_From_File",
  ImportContactFromThirdParty = "Import_Contact_From_Third_Party",
  MeetingEventCreated = "Meeting_Event_Created",
  FacebookThreadAssigned = "Facebook_Thread_Assigned",
  WebFormAssigned = "Web_Form_Assigned",
}

const BaseNotification = (props: any) => {
  const { item, toggleDrawer } = props;

  const onSelectPanel = (type: string) => {
    if (type === NotificationType.TicketAssign) {
      window.location.href = process.env.REACT_APP_CRM_UI_URL_DEV + "/tickets";
      toggleDrawer(false);
    }
  };
  return (
    <Panel
      className="mb-4 pointer"
      header={item.header}
      shaded
      onClick={() => onSelectPanel(item.type)}
    >
      <p>{item.message}</p>
      <Tag color="green">{new Date(item.created_at).toLocaleString()}</Tag>
    </Panel>
  );
};

export default BaseNotification;
