import React from "react";
import SingleCard from "../GraphANdChartsSnippets/SingleCard/SingleCard";

const LazyTableSnippet = React.lazy(
  () => import("src/components/GraphANdChartsSnippets/Table/Table")
);

const LazyMicroCardGroup = React.lazy(
  () =>
    import(
      "src/components/GraphANdChartsSnippets/MicroCardGroup/MicroCardGroup"
    )
);

const LazyVerticalLineChart = React.lazy(
  () =>
    import(
      "src/components/GraphANdChartsSnippets/VerticalLineChart/VerticalLineChart"
    )
);

const LazyHorizontalBarGraph = React.lazy(
  () =>
    import(
      "src/components/GraphANdChartsSnippets/HorizontalBarGraph/HorizontalBarGraph"
    )
);

const LazyPieChart = React.lazy(
  () => import("src/components/GraphANdChartsSnippets/PieChart/pieChart")
);

const TemplateRenderer: React.FC<any> = ({
  item,
  layoutsPassToTemplate,
}: {
  item: any;
  layoutsPassToTemplate: any;
}) => {
  return (
    <React.Suspense fallback={() => <>loading</>}>
      <div
        style={{
          height: "100%",
          width: "100%",
          overflowY: "auto",
        }}
      >
        {item?.widget_name === "contact_vs_recent" && (
          <>
            <LazyTableSnippet
              title={item?.widget_title}
              endPoint={item?.widget_endpoint}
              description={item?.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "total_contact_created_vs_last_week" && (
          <>
            <LazyMicroCardGroup
              title={item.widget_title}
              endpoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
        {item?.widget_name === "total_task_created_vs_last_week" && (
          <>
            <LazyMicroCardGroup
              title={item.widget_title}
              endpoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "contact_vs_time" && (
          <>
            <LazyVerticalLineChart
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
        {item?.widget_name === "stage_wise_deals_amount" && (
          <>
            <LazyVerticalLineChart
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
        {item?.widget_name === "task_vs_month" && (
          <>
            <LazyVerticalLineChart
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
        {item?.widget_name === "contact_creation_total_by_source" && (
          <>
            <LazyHorizontalBarGraph
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
        {item?.widget_name === "contact_creation_total_by_person" && (
          <>
            <LazyHorizontalBarGraph
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
        {item?.widget_name === "company_vs_recent" && (
          <>
            <LazyTableSnippet
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "total_company_created_vs_last_week" && (
          <>
            <LazyMicroCardGroup
              title={item.widget_title}
              endpoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "total_ticket_created_vs_last_week" && (
          <>
            <LazyMicroCardGroup
              title={item.widget_title}
              endpoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "company_vs_time" && (
          <>
            <LazyVerticalLineChart
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "company_creation_total_by_person" && (
          <>
            <LazyHorizontalBarGraph
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "ticket_vs_month" && (
          <>
            <LazyVerticalLineChart
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "ticket_status" && (
          <>
            <LazyPieChart
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
        {item?.widget_name === "task_status" && (
          <>
            <LazyPieChart
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "get_stage_ticket_count" && (
          <>
            <LazyVerticalLineChart
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "deals_in_a_stage_count" && (
          <>
            <LazyVerticalLineChart
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "get_created_last_24h" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={false}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "get_close_last_24h" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={false}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "total_company_created" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "last_week_company_created" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={false}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "top_month_company_creation" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={false}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "average_company_creation_per_month" && (
          
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={false}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "total_task_count" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={false}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "last_week_total_task_count" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={false}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "top_month_task_analytics" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={false}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "get_max_deal_amount_month" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={false}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "avg_month_task_analytics_cards" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={false}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "get_lowest_deal_amount_month" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={false}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "month_wise_deals_amount" && (
          <>
            <LazyHorizontalBarGraph
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "get_total_ticket" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={true}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "get_total_created" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              showFilter={true}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "get_total_open_ticket" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "get_total_close_ticket" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "get_total_ticket_in_progress" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "average_ticket_resolve_time" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "ticket_close_before" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "ticket_close_after" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
        {item?.widget_name === "total_company_target" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
        {item?.widget_name === "achieved_till_date" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
        {item?.widget_name === "backlog_till_date" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
        {item?.widget_name === "achieved_this_month" && (
          <>
            <SingleCard
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "ticket_person_wise_in_progress" && (
          <>
            <LazyHorizontalBarGraph
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "ticket_person_wise_open" && (
          <>
            <LazyHorizontalBarGraph
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}

        {item?.widget_name === "ticket_person_wise_close" && (
          <>
            <LazyHorizontalBarGraph
              title={item.widget_title}
              endPoint={item.widget_endpoint}
              description={item.widget_description}
              widgetData={item}
              layoutsPassToTemplate={layoutsPassToTemplate}
            />
          </>
        )}
      </div>
    </React.Suspense>
  );
};

export default TemplateRenderer;
