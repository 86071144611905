import React from "react";
import talkToUs from "../../../../assets/svgs/talkToUs.svg";
import sendMailSvg from "../../../../assets/svgs//sendMailSvg.svg";
import chatBotSvg from "../../../../assets/svgs//chatBotSvg.svg";
import "./contactus.card.component.scss";
import { Button } from "rsuite";
const ContactUsCardComponent = () => {
  return (
    <>
      <div className="cardParentForContact">
        <div className="contactFlex">
          <img src={talkToUs} alt="talkToUs" />
          <div>
            <p className="talk">Talk to us</p>
            <p className="numberPhn">+880 1777 770 555</p>
            <p className="contentDesc">
              Interested in ROBOKET software? Just pick up the phone to chat
              with a member of our sales team.
            </p>
            <Button className="callBtn">Call</Button>
          </div>
        </div>
      </div>

      <div className="cardParentForContact">
        <div className="contactFlex">
          <img src={sendMailSvg} alt="sendMailSvg" />
          <div>
            <p className="talk">Send a mail</p>
            <p className="numberPhn">support@roboket.com</p>
            <p className="contentDesc">
              If you have any questions or technical issues while using ROBOKET
            </p>
            <Button className="callBtn">Send</Button>
          </div>
        </div>
      </div>

      <div className="cardParentForContact">
        <div className="contactFlex">
          <img src={chatBotSvg} alt="chatBotSvg" />
          <div>
            <p className="talk">Chatbot</p>

            <p className="contentDesc">
              Sometimes you need a little help from your friends. Or a ROBOKET
              support rep. Don’t worry… we’re here for you.
            </p>
            <Button className="callBtn">Connect</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsCardComponent;
