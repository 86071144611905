import "./onboardMarketing.scss";
import FirstCard from "src/onboardCard/FirstCard";
import SecondCard from "src/onboardCard/SecondCard";
import ThirdCard from "src/onboardCard/ThirdCard";
import FourthCard from "src/onboardCard/FourthCard";

const OnboardMarketing = (props: any) => {
  const { selectedMenuItem, fullName } = props;

  return (
    <>
      <div className="parentContainer">
        <>
          <p className="hiClass">Hi, {fullName} </p>

          <p className="welcomeTxt"> Welcome to Roboket</p>
        </>

        <div className="divFlex">
          <FirstCard selectedMenuItem={selectedMenuItem} />
          <SecondCard selectedMenuItem={selectedMenuItem} />
        </div>

        <div className="divFlex">
          <ThirdCard selectedMenuItem={selectedMenuItem} />
          <FourthCard />
        </div>
      </div>
    </>
  );
};

export default OnboardMarketing;
