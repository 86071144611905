import React, { useState, createContext, useRef, useEffect } from "react";
import {
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  SelectPicker,
  RadioGroup,
  Radio,
  Row,
  Col,
} from "rsuite";
import { useHistory } from "react-router-dom";
import CreateFromTemplate from "src/components/CreateFromTemplate/CreateFromTemplate";
import {
  creteNewDashboard,
  getAllWidgets,
  getAllWidgetsWithTag,
  getTeamsOfAnUser,
} from "src/common/analytics-and-dashboard-api-calls";
import "./CreateNewDashboardComp.scss";
import { IWidgets } from "src/common/types/analytics.types";
import { registeredWidgets } from "src/widget.register";
import { dashboard_modal } from "src/assets";
import toast from "react-hot-toast";
type Size = "xs" | "sm" | "md" | "lg";
interface DashboardContextType {
  handleModalOpenClose: (preset?: string | null) => void;
}
export const DashboardContext = createContext<DashboardContextType>({
  handleModalOpenClose: () => {},
});

const ModalContentForStepOne = ({
  isLoading,
  reportTypesWillBeIncluded,
  setReportTypesWillBeIncluded,
  hasError,
  accessOptions,
  currentlySelectedAccessOption,
  setCurrentlySelectedAccessOption,
  setPermissionOpt,
  handleCreateDashboard,
  dashboardName,
  setDashBoardName,
  teams,
  setSelectedTeam,
  isFetching,
}: any) => {
  const dashboardRef = useRef<HTMLElement>(null);
  // console.log("!! reportTypesWillBeIncluded", reportTypesWillBeIncluded);

  return (
    <>
      <div className="modalBody-contentArea">
        {/* <Grid> */}
        <Row gutter={16}>
          <Col sm={24} md={12} lg={12}>
            {" "}
            <div className="left-container">
              <Form>
                <FormGroup className="formField">
                  <ControlLabel>
                    Dashboard name<span className="requiredStyle">*</span>
                  </ControlLabel>
                  <FormControl
                    name="name"
                    ref={dashboardRef}
                    value={dashboardName}
                    onChange={(e) => {
                      setDashBoardName(e);
                    }}
                    style={{ minWidth: "435px" }}
                  />
                  {/* <HelpBlock>Required</HelpBlock> */}
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    Who can access this dashboard?
                    <span className="requiredStyle">*</span>
                  </ControlLabel>
                  <SelectPicker
                    name="permissionOpt"
                    placeholder="Please Select"
                    data={accessOptions}
                    // style={{ minWidth: "400px" }}
                    style={{ width: "100%" }}
                    onSelect={(e) => {
                      setCurrentlySelectedAccessOption(e);
                      if (e === "PTM") setPermissionOpt(0);
                      else {
                        setPermissionOpt(1);
                      }
                    }}
                    defaultValue={currentlySelectedAccessOption}
                    cleanable={false}
                  />
                  {/* <HelpBlock>Required</HelpBlock> */}
                </FormGroup>

                {currentlySelectedAccessOption === "EVE" && (
                  <FormGroup controlId="radioList">
                    <RadioGroup name="radioList" inline defaultValue={"1"}>
                      <Radio value="1" onChange={() => setPermissionOpt(1)}>
                        View and edit
                      </Radio>
                      <Radio value="2" onChange={() => setPermissionOpt(2)}>
                        View only
                      </Radio>
                    </RadioGroup>
                  </FormGroup>
                )}

                {currentlySelectedAccessOption === "TEM" && (
                  <FormGroup>
                    <ControlLabel>Which team can access?</ControlLabel>
                    <SelectPicker
                      placeholder="Please Select"
                      data={teams}
                      style={{ minWidth: "300px" }}
                      defaultValue={teams.length > 0 && teams[0].value}
                      cleanable={false}
                      onChange={(eve) => {
                        setSelectedTeam(eve);
                      }}
                    />
                  </FormGroup>
                )}
              </Form>
            </div>
          </Col>
          <Col sm={24} md={12} lg={12}>
            {" "}
            <div className="right-container">
              <div className="___groups element-scroll widgets-options-list">
                {/* {isLoading && <LoadingComponent />} */}
                {!isFetching && (
                  <>
                    <div className="title">
                      Reports included in this dashboard:
                    </div>
                    <div className="opt__container-area">
                      {reportTypesWillBeIncluded.map((data: any) => {
                        return (
                          registeredWidgets.includes(data?.widget_name) && (
                            <div className="opt__container" key={data.id}>
                              <div className="list">
                                <input
                                  type="checkbox"
                                  checked={data.selected}
                                  onChange={() => {
                                    setReportTypesWillBeIncluded(
                                      (prevState: any) => {
                                        return prevState.map((item: any) => {
                                          if (item.id === data.id) {
                                            return {
                                              ...item,
                                              selected: !item.selected,
                                            };
                                          }
                                          return item;
                                        });
                                      }
                                    );
                                  }}
                                  disabled={isLoading}
                                />
                                <div className="_context">
                                  {data?.widget_title}
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </>
                )}
                {!isLoading && hasError && (
                  <>
                    Our service is currently offline. please wait for few times.
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        {/* <Divider /> */}
        <div className="footer-area-btn">
          <button
            className="drawer-button-v1"
            onClick={handleCreateDashboard}
            disabled={isLoading}
          >
            Create dashboard
          </button>
        </div>
      </div>
    </>
  );
};

// const ModalContentForStepTwo = ({
//   accessOptions,
//   currentlySelectedAccessOption,
//   setCurrentlySelectedAccessOption,
//   setPermissionOpt,
//   isLoading,
//   setCurrentModalStep,
//   handleCreateDashboard,
//   dashboardName,
//   setDashBoardName,
// }: any) => {
//   const dashboardRef = useRef<HTMLElement>(null);
//   const [teams] = useState([
//     {
//       label: "Team 01",
//       value: "1",
//     },
//     {
//       label: "Team 02",
//       value: "2",
//     },
//     {
//       label: "Team 03",
//       value: "3",
//     },
//   ]);
//   return (
//     <>
//       <Form fluid>
//         <FormGroup>
//           <ControlLabel>Dashboard name</ControlLabel>
//           <FormControl
//             name="name"
//             ref={dashboardRef}
//             value={dashboardName}
//             onChange={(e) => {
//               setDashBoardName(e);
//             }}
//           />
//           <HelpBlock>Required</HelpBlock>
//         </FormGroup>
//         <FormGroup>
//           <ControlLabel>Who can access this dashboard?</ControlLabel>
//           <SelectPicker
//             name="permissionOpt"
//             placeholder="Please Select"
//             data={accessOptions}
//             style={{ minWidth: "300px" }}
//             onSelect={(e) => {
//               setCurrentlySelectedAccessOption(e);
//               if (e === "PTM") setPermissionOpt(0);
//               else {
//                 setPermissionOpt(1);
//               }
//             }}
//             defaultValue={currentlySelectedAccessOption}
//             cleanable={false}
//           />
//           <HelpBlock>Required</HelpBlock>
//         </FormGroup>

//         {currentlySelectedAccessOption === "EVE" && (
//           <FormGroup controlId="radioList">
//             <RadioGroup name="radioList" inline defaultValue={"1"}>
//               <Radio value="1" onChange={() => setPermissionOpt(1)}>
//                 View and edit
//               </Radio>
//               <Radio value="2" onChange={() => setPermissionOpt(2)}>
//                 View only
//               </Radio>
//             </RadioGroup>
//           </FormGroup>
//         )}

//         {currentlySelectedAccessOption === "TEM" && (
//           <FormGroup>
//             <ControlLabel>Which team can access?</ControlLabel>
//             <SelectPicker
//               placeholder="Please Select"
//               data={teams}
//               style={{ minWidth: "300px" }}
//               defaultValue={teams[0].value}
//               cleanable={false}
//             />
//           </FormGroup>
//         )}

//         <div>
//           <Divider />
//           <div className="d-flex">
//             <Button
//               color="green"
//               size="md"
//               className="d-flex align-items-center gap-2"
//               onClick={() => setCurrentModalStep(1)}
//               disabled={isLoading}
//             >
//               <BiArrowBack /> <div className="ml-2">Back</div>
//             </Button>

//             <Button
//               color="green"
//               size="lg"
//               className="btn__width ml-2"
//               onClick={handleCreateDashboard}
//               disabled={isLoading}
//             >
//               Create dashboard
//             </Button>
//           </div>
//         </div>
//       </Form>
//     </>
//   );
// };

const CreateNewDashboardComponent: React.FC = () => {
  const history = useHistory();
  const [dashboardName, setDashBoardName] = useState("");
  const [modalSize, setModalSize] = useState<Size | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [currentPrestSelected, setCurrentPrestSelected] = useState("new");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalStep, setCurrentModalStep] = useState(0);
  const [reportTypesWillBeIncluded, setReportTypesWillBeIncluded] = useState<
    IWidgets[]
  >([]);
  const [permissionOpt, setPermissionOpt] = useState(0);
  const [currentlySelectedAccessOption, setCurrentlySelectedAccessOption] =
    useState<"PTM" | "EVE" | "TEM">("PTM");

  const [accessOptions] = useState([
    {
      label: "Private to owner (Me)",
      value: "PTM",
    },
    {
      label: "Everyone",
      value: "EVE",
    },
    {
      label: "Teams",
      value: "TEM",
    },
  ]);

  const [teams, setTeams] = useState<{ label: string; value: string }[]>([]);

  const [selectedTeam, setSelectedTeam] = useState(null);

  const teamsInformation = async () => {
    const response = await getTeamsOfAnUser();
    console.log("response", response);

    if (response.data.data.length > 0) {
      let modified: any = [];
      // eslint-disable-next-line array-callback-return
      response.data?.data.map((data: any) => {
        modified.push({
          value: data.id,
          label: data.name,
        });
      });
      console.log(modified);

      setTeams(modified);
    }
  };

  const handleModalOpenClose = (preset?: string | null) => {
    setIsModalOpen((prev) => !prev);
    if (preset !== undefined && preset !== null) {
      setCurrentPrestSelected(preset);
      fetchAllWidgetsListWithTag(preset);
    }
    if (isModalOpen === true) {
      setCurrentModalStep(0);
    }
    if (currentModalStep === 0) {
      setCurrentModalStep(1);
      setIsLoading(true);
      setIsFetching(true);
    }
  };

  const handleNextStep = () => {
    setCurrentModalStep((prev) => prev + 1);
  };

  const handleCreateDashboard = async () => {
    if (currentlySelectedAccessOption === "TEM" && selectedTeam === null) {
      toast.error("You don't have any teams, please create a team first");
      return;
    }
    if (dashboardName.trim().length !== 0) {
      setIsLoading(true);
      const response = await creteNewDashboard(
        dashboardName,
        reportTypesWillBeIncluded,
        currentlySelectedAccessOption,
        currentlySelectedAccessOption === "EVE" ? permissionOpt : 0,
        currentlySelectedAccessOption === "TEM" ? selectedTeam : null
      );

      if (response.error === false) {
        toast.success("New Dashboard Created.");
        setTimeout(() => {
          setIsLoading(false);
          history.push(`/dashboard/${response.data.id}`);
        }, 3000);
      } else {
        setIsLoading(false);
      }
    } else {
      toast.error("Please Enter Dashboard name");
    }
  };

  const fetchAllWidgetsList = async () => {
    setHasError(false);
    const response = await getAllWidgets();

    if (response.error === true) {
      setIsLoading(false);
      setHasError(true);
      setIsFetching(false);
    } else {
      setIsLoading(false);
      setIsFetching(false);
      setReportTypesWillBeIncluded(response.data);
    }
  };

  const fetchAllWidgetsListWithTag = async (preset: string) => {
    setHasError(false);
    const response = await getAllWidgetsWithTag(preset);

    if (response.error === true) {
      setIsLoading(false);
      setHasError(true);
    } else {
      setIsLoading(false);
      setReportTypesWillBeIncluded(
        response.data.map((widget: any) => ({ ...widget, selected: true }))
      );
    }
  };

  useEffect(() => {
    if (currentlySelectedAccessOption === "TEM") {
      teamsInformation();
    }
  }, [currentlySelectedAccessOption]);

  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth;
      // Determine the size based on the window width
      if (windowWidth < 576) {
        setModalSize("xs");
      } else if (windowWidth < 800) {
        setModalSize("sm");
      } else if (windowWidth < 992) {
        setModalSize("md");
      } else {
        setModalSize("lg");
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    // Removing event listener for resizing window
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="CreateNewDashboard-modal-area">
        {" "}
        <Modal
          autoFocus
          show={isModalOpen}
          onHide={() => handleModalOpenClose()}
          backdrop={"static"}
          size={modalSize}
        >
          <Modal.Header className="CreateNewDashboard-modal-header">
            <Modal.Title>
              <img src={dashboard_modal} alt="SVG icon" />
              {currentPrestSelected === "new"
                ? "Create dashboards from scratch"
                : `Create Dashboard with Pre Selected ${currentPrestSelected}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modalBodyPadding">
            {currentModalStep === 1 && (
              <ModalContentForStepOne
                currentPrestSelected={currentPrestSelected}
                isLoading={isLoading}
                reportTypesWillBeIncluded={reportTypesWillBeIncluded}
                setReportTypesWillBeIncluded={setReportTypesWillBeIncluded}
                hasError={hasError}
                handleNextStep={handleNextStep}
                accessOptions={accessOptions}
                currentlySelectedAccessOption={currentlySelectedAccessOption}
                setCurrentlySelectedAccessOption={
                  setCurrentlySelectedAccessOption
                }
                permissionOpt={permissionOpt}
                setPermissionOpt={setPermissionOpt}
                setCurrentModalStep={setCurrentModalStep}
                handleCreateDashboard={handleCreateDashboard}
                dashboardName={dashboardName}
                setDashBoardName={setDashBoardName}
                teams={teams}
                setSelectedTeam={setSelectedTeam}
                isFetching={isFetching}
              />
            )}
            {/* {currentModalStep === 2 && (
            <ModalContentForStepTwo
              accessOptions={accessOptions}
              currentlySelectedAccessOption={currentlySelectedAccessOption}
              setCurrentlySelectedAccessOption={
                setCurrentlySelectedAccessOption
              }
              permissionOpt={permissionOpt}
              setPermissionOpt={setPermissionOpt}
              isLoading={isLoading}
              setCurrentModalStep={setCurrentModalStep}
              handleCreateDashboard={handleCreateDashboard}
              dashboardName={dashboardName}
              setDashBoardName={setDashBoardName}
            />
          )} */}
          </Modal.Body>
        </Modal>
      </div>

      <DashboardContext.Provider
        value={{ handleModalOpenClose: handleModalOpenClose }}
      >
        <div
          className="CreateNewDashboardComponentContainer"
          // style={{ padding: "0px 48px 20px" }}
        >
          <div className="title">Create dashboards from scratch</div>
          <div
            className="create_new_container"
            onClick={() => {
              setReportTypesWillBeIncluded([]);
              handleModalOpenClose();
              fetchAllWidgetsList();
            }}
          >
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
              >
                <path
                  d="M19 25.5312C18.3441 25.5312 17.8125 24.9996 17.8125 24.3438V20.1875H13.6562C13.0004 20.1875 12.4688 19.6559 12.4688 19C12.4688 18.3441 13.0004 17.8125 13.6562 17.8125H17.8125V13.6562C17.8125 13.0004 18.3441 12.4688 19 12.4688C19.6559 12.4688 20.1875 13.0004 20.1875 13.6562V17.8125H24.3438C24.9996 17.8125 25.5312 18.3441 25.5312 19C25.5312 19.6559 24.9996 20.1875 24.3438 20.1875H20.1875V24.3438C20.1875 24.9996 19.6559 25.5312 19 25.5312Z"
                  fill="#05AA85"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.6875 1.1875C5.44079 1.1875 1.1875 5.44079 1.1875 10.6875V27.3125C1.1875 32.5592 5.44079 36.8125 10.6875 36.8125H27.3125C32.5592 36.8125 36.8125 32.5592 36.8125 27.3125V12.2356C36.8125 10.919 36.266 9.66144 35.3035 8.7631L28.5556 2.46499C27.676 1.64406 26.5177 1.1875 25.3145 1.1875H10.6875ZM3.5625 10.6875C3.5625 6.75248 6.75248 3.5625 10.6875 3.5625H24.9375V7.125C24.9375 9.74837 27.0641 11.875 29.6875 11.875H34.41C34.4282 11.9936 34.4375 12.1141 34.4375 12.2356V27.3125C34.4375 31.2475 31.2475 34.4375 27.3125 34.4375H10.6875C6.75248 34.4375 3.5625 31.2475 3.5625 27.3125V10.6875ZM27.3125 7.125V4.55353L32.6123 9.5H29.6875C28.3758 9.5 27.3125 8.43665 27.3125 7.125Z"
                  fill="#05AA85"
                />
              </svg>
            </div>
            <div className="label">New dashboard</div>
          </div>
        </div>
        <div className="CreateFromTemplateArea">
          <CreateFromTemplate />
        </div>
      </DashboardContext.Provider>
    </>
  );
};

export default CreateNewDashboardComponent;
