import React from "react";
import { Container, Content, Header } from "rsuite";
import { HeaderBadge } from "src/view";
import CreateNewDashboardComponent from "src/components/CreateNewDashboard";

const CreateNewDashboard: React.FC = () => {
  return (
    <>
      <Content className="main-content">
        <Container>
          <Header>
            <HeaderBadge title="create dashboard" />
          </Header>
          <div className="__card_container">
            <CreateNewDashboardComponent />
          </div>
        </Container>
      </Content>
    </>
  );
};

export default CreateNewDashboard;
