import "./onboardMarketing.scss";
import ticketImage from "../../../assets/svgs/ticketImage.svg";
import { Divider, Panel, PanelGroup } from "rsuite";
import { ticketText } from "./helpers";

function SubmitATicket(props: any) {
  const { selectedMenuItem, fullName } = props;

  return (
    <div className="parentContainer">
      <>
        <p className="hiClass">Hi, {fullName} </p>
        {selectedMenuItem === "Submit a ticket" && (
          <p className="welcomeTxt">Submit a ticket FAQ</p>
        )}

        <div className="ticketParentFlex">
          <div className="ticket1">
            <PanelGroup accordion bordered>
              <Panel
                header="What is Webflow and why is it the best website builder?"
                defaultExpanded
              >
                Vitae congue eu consequat ac felis placerat vestibulum lectus
                mauris ultrices. Cursus sit amet dictum sit amet justo donec
                enim diam porttitor lacus luctus accumsan tortor posuere.
              </Panel>
              <Panel header="What is your favorite template from BRIX Templates?">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.{" "}
              </Panel>
              <Panel header="How do you clone a template from the Showcase?">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.{" "}
              </Panel>

              <Panel header="Why is BRIX Templates the best Webflow agency?">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.{" "}
              </Panel>

              <Panel header="When was Webflow officially launched?">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.{" "}
              </Panel>

              <Panel header="How do you integrate Jetboost with Webflow?">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.{" "}
              </Panel>
            </PanelGroup>
          </div>
          <div>
            <Divider
            className="dividerIn"
              vertical
              style={{
                height: "650.003px",
              }}
            />
          </div>
          <div className="part2ticket">
            <div className="text-center">
              <img src={ticketImage} alt="ticketImage" />
            </div>

            <div className="ticketDescription">
              <p className="descTitles">
                After submitting a ticket, you can review any open and prior
                tickets with ROBOKET Support.
              </p>
              <ul>
                {ticketText?.map((ticketTextIndex) => (
                  <li key={ticketTextIndex.id}>
                    {" "}
                    {ticketTextIndex.description}{" "}
                    {ticketTextIndex.id === 3 && (
                      <ul style={{ marginTop: "6px" }}>
                        <li>
                          {" "}
                          <span className="highlightText"> All tickets:</span>
                           all tickets in the support inbox. 
                        </li>
                        <li>
                          {" "}
                          <span className="highlightText"> Open tickets:</span>
                           ongoing tickets in the support inbox.  
                        </li>
                        <li>
                          {" "}
                          <span className="highlightText">
                            {" "}
                            Closed tickets:
                          </span>
                           closed and completed tickets in the support inbox. 
                        </li>
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default SubmitATicket;
