import { Button } from "rsuite";
import doesnotExistDashboard from "../../../assets/svgs/doesnotExistDashboard.svg";
import "./hasErrorOnDashboard.scss";

interface IHasError {
  message?: string;
}

const HasErrorOnDashboard = ({ message }: IHasError) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        height: "100vh",
      }}
    >
      <div className="imgFlex">
        <p className="serviceDown">
          {" "}
          {message
            ? message
            : "Currently our service is down, please wait for some times."}
        </p>

        <img
          className="imgMargin"
          src={doesnotExistDashboard}
          alt="doesnotExistDashboard"
        />
      </div>

      <div>
        <Button
          appearance="ghost"
          onClick={() => (window.location.href = "/")}
        >
          Reload
        </Button>
      </div>
    </div>
  );
};

export default HasErrorOnDashboard;
